import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { SubpageTitle } from "../../../components/atoms/text/titles";
import { useQueryBackend } from "../../../utils/requestHelpers";
import { BarChartView, IDataItem } from "../../../components/atoms/charts";
import { DateTime } from "luxon";
import {
  DateRangePickerPopUp,
  TagButton,
} from "../../../components/molecules/datepickers/dateRangePickerPopUp";
import { MachineLogTable } from "../../reports/machineReport";
import theme, { padding } from "../../../theme";
import { Checkbox } from '@mui/material';
import Text from "../../../components/atoms/text/text";

interface IErrorWarningItem {
  day: string;
  warningcount: number | undefined;
  errorcount: number;
}
export const ErrorsAndWarnings = ({ name }: { name?: string | undefined }) => {
  const queryApi = useQueryBackend("Backoffice");
  const [from, setFrom] = useState<DateTime>(DateTime.now().startOf("month"));
  const [to, setTo] = useState<DateTime>(DateTime.now().endOf("month"));
  const [errorsAndWarnings, setErrorsAndWarnings] = useState<Array<IDataItem>>(
    []
  );
  const today = DateTime.now();
  const step = "month";
  const stepper = { month: 1 };
  const todayRollingSeven = today.minus(stepper);
  const lastMonthStart = today.minus(stepper).startOf("month");
  const lastMOnthEnd = today.minus(stepper).endOf("month");
  const [warnings,setWarnings] = useState<boolean>(false)

  useEffect(() => {

    if (!from || !to) return;
    queryApi
      .Get<Array<IErrorWarningItem>>(
        `telemetry/error-warning-bar-chart/${from.toFormat(
          "yyyy-MM-dd"
        )}/and/${to.toFormat("yyyy-MM-dd")}?filterType=${warnings ? "warning" : "no_warning"}`
      )
      .Data((d) => {
        const items: IDataItem[] = [
          ...d.map((i) => {
            if(warnings){
              return {
                name: i.day,
                warning: i.warningcount,
                error: i.errorcount,
              };
            }else{
              return {
                name: i.day,
                error: i.errorcount,
              };
            }

          }),
        ];
        setErrorsAndWarnings(items);
      });
  }, [from, to, warnings]);
  const [open, setOpen] = useState<boolean>(false);
  const presets: TagButton[] = [
    {
      name: "This Month",
      id: "this",
      action: () => {
        setFrom(today.startOf(step));
        setTo(today.endOf("month"));
        setOpen(false);
      },
      default: true,
    },
    {
      name: "Last Month",
      id: "last",
      action: () => {
        setFrom(lastMonthStart);
        setTo(lastMOnthEnd);
        setOpen(false);
      },
    },
    {
      name: "Rolling month",
      id: "rolling",
      action: () => {
        setFrom(todayRollingSeven);
        setTo(today);
        setOpen(false);
      },
    },
  ];

  const [details, setDetails] = useState<{
    from: DateTime | undefined;
    to: DateTime | undefined;
    open;
  }>({ from: undefined, to: undefined, open: false });

  const handle = (event: { name: string }) => {
    setDetails({
      from: DateTime.fromISO(event.name),
      to: DateTime.fromISO(event.name).plus({ day: 1 }),
      open: true,
    });
  };

  return (
    <Box>
      <SubpageTitle title={name ?? `Errors ${warnings ? "and warnings" : ""}`} />
      <Box sx={{display: "flex", flexDirection: "row", alignItems: "center"}}>
        <DateRangePickerPopUp
          startDate={from}
          endDate={to}
          onStartDatePicked={setFrom}
          onEndDatePicked={setTo}
          presets={presets}
          open={open}
          setOpen={setOpen}
        ></DateRangePickerPopUp>
              <Checkbox checked={warnings} onClick={() => setWarnings(!warnings)}/>
                <Text>Include warnings</Text>
      </Box>
      <div style={{ width: "100%", height: "400px" }}>
        <BarChartView
          handleClick={(event) => handle(event)}
          items={errorsAndWarnings}
          formatX={(s) => DateTime.fromISO(s).toFormat("dd")}
          colorMap={{ error: theme.palette.error.light, warning: theme.palette.warning.light }}
        />
      </div>
      {details.open && details.to && details.from ? (
        <MachineLogTable to={details.to} from={details.from} warnings={warnings}></MachineLogTable>
      ) : (
        <> </>
      )}
    </Box>
  );
};
