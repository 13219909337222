import React from "react"
import Page from "../../../components/molecules/page"
import { OrganizationUserView } from "./organizationUserView"



export const OrganizationUserPage = () => {

    return (
        <Page title="Company User" pageSections={[<OrganizationUserView />]} />
    )
}