import React, { useState } from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Tooltip } from "@mui/material";
import { Add, Info, Remove } from "@mui/icons-material";
import theme from "../../theme";

type AggregationConfig<T> = {
  [key in keyof T]: (item: T) => number;
};

export type ColumnName = {
  displayName: string;
  id: string;
  aggregate: boolean;
  info?: string;
  grouped?: boolean;
  level: number;
  formatter?: (...args: any[]) => string | React.ReactElement | number;
  align?: "left" | "right" | "center";
  altText?:  (...args: any[]) => string | React.ReactElement | string;
  visible?: boolean;  // New property to control visibility
};

// Define the data item type
export type DataItem = {
  [key: string]: any;
};

const calculateAggregates = <T,>(items: T[], config: AggregationConfig<T>) => {
  return items.reduce((aggregates, item) => {
    for (const key in config) {
      if (isNaN(config[key](item))) {
        aggregates[key] = (aggregates[key] || 0) + 1;
      } else {
        if (config.hasOwnProperty(key)) {
          aggregates[key] = (aggregates[key] || 0) + config[key](item);
        }
      }
    }
    return aggregates;
  }, {} as Record<keyof T, number>);
};

const isNumber = (value: string): boolean => {
  return !isNaN(Number(value));
};

const buildAggregationConfig = (columnNames: ColumnName[]) => {
  const config: AggregationConfig<any> = {};
  columnNames.forEach((col) => {
    config[col.id] = (item) => parseFloat(item[col.id]) as number;
  });

  return config;
};

const ToolTipCell = ({ column }: { column: ColumnName }) => {
  return (
    <TableCell align="right">
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          justifyContent: "flex-end",
        }}
      >
        {column.displayName}
        <Tooltip title={column.info}>
          <Info
            sx={{
              marginLeft: 1,
              color: theme.palette.info.main,
              fontSize: 16,
            }}
          />
        </Tooltip>
      </Box>
    </TableCell>
  );
};

const Row = ({
  row,
  columnNames,
  level,
}: {
  row: { groupBy: string; items: any[] };
  columnNames: ColumnName[];
  level: number;
}) => {
  const [open, setOpen] = useState(false);

  const config = buildAggregationConfig(columnNames);
  const aggregates = calculateAggregates(row.items, config);

  const getCellStyle = (value: any) => {
    return !isNumber(value)
      ? { textAlign: "start" as const }
      : { textAlign: "end" as const };
  };

  const getNestedValue = (obj: any, path: string) => {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj);
  };

  // Filter the next level columns
  const nextLevelColumns = columnNames.filter((col) => col.level === level + 1);

  return (
    <>
      <TableRow>
        <TableCell
          sx={{
            textAlign: "start",
            flexDirection: "row",
            display: "flex",
            alignItems: "center",
            paddingLeft: `${level * 16}px`, // Indentation for levels
          }}
        >
          {row.items && row.items.length > 0 && (
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <Remove /> : <Add />}
            </IconButton>
          )}
        </TableCell>
        {columnNames.map((n) =>
         n.level >= level  ?
         n.visible ? 
          !n.aggregate || n.level === level ? (
            <TableCell align={n.align} key={n.id}>
              {n.altText ? n?.altText(row, aggregates[n.id as keyof typeof aggregates], level) : row.groupBy}
            </TableCell>
          ) : (
            <TableCell align={n.align} key={n.id}>
              {!n.formatter
                ? aggregates[n.id as keyof typeof aggregates]
                : n.formatter(aggregates[n.id as keyof typeof aggregates])}
            </TableCell>
          ): null : <TableCell></TableCell>
        )}
      </TableRow>
      {open && 
        row.items.map((item, rowIndex) =>
          item.items ? ( // Check if the item itself has sub-items (indicating another group level)
            <Row
              key={rowIndex}
              row={item}
              columnNames={columnNames}
              level={level + 1}
            />
          ) : (
            <TableRow
              sx={{ backgroundColor: theme.palette.background.default }}
              key={rowIndex}
            >
              <TableCell
                sx={{
                  paddingLeft: `${(level + 1) * 16}px`, // Further indentation for nested rows
                }}
              >
              </TableCell>
              {columnNames.map((n) => (
                 n?.level >= level ?
                n.visible ?
                <TableCell
                  align={n.align}
                  key={n.id}
                  sx={[n.align ==="left" ? "start" : getCellStyle(getNestedValue(item, n.id))]}
                >
                  
                  {n?.formatter ? n.formatter(getNestedValue(item, n.id)) : 
                  getNestedValue(item,n.id)}
                </TableCell> : null : <TableCell></TableCell>
              )) }
            </TableRow>
          )
        )}
    </>
  );
};


function formatDataForCollapsibleTable(data: DataItem[], groupByKeys: string[]): any[] {
  // Recursive function to group data by the specified keys
  const groupByParam = (arr: DataItem[], keys: string[]): any[] => {
    if (keys.length === 0) return arr;

    const [currentKey, ...restKeys] = keys;
    const groupedObj = arr.reduce((acc, item) => {
      const groupKey = item[currentKey];
      if (!acc[groupKey]) {
        acc[groupKey] = [];
      }
      acc[groupKey].push(item);
      return acc;
    }, {} as Record<string, DataItem[]>);

    return Object.keys(groupedObj).map((groupKey) => ({
      groupBy: groupKey,
      items: groupByParam(groupedObj[groupKey], restKeys),
    }));
  };

  return groupByParam(data, groupByKeys);
}

const groupByParam = <T,>(
  arr: T[],
  groupKeys: (keyof T)[],
  level = 0
): Array<{ groupBy: string; items: any }> => {
  if (groupKeys.length === 0) return [{ groupBy: "", items: arr }];

  const key = groupKeys[0];
  const groupedObj = arr.reduce((acc, item) => {
    const groupKey = item[key] as unknown as string;
    if (!acc[groupKey]) {
      acc[groupKey] = [];
    }

    acc[groupKey].push(item);
    return acc;
  }, {} as Record<string, T[]>);

  return Object.keys(groupedObj).map((groupKey) => ({
    groupBy: groupKey,
    items: groupByParam(groupedObj[groupKey], groupKeys.slice(1), level + 1),
  }));
};


export const CollapsibleTable = ({
  rows,
  columnNames,
}: {
  rows: DataItem[];
  columnNames: ColumnName[];
}) => {
  // Determine the grouping keys based on the levels
  const groupedColumns = columnNames
    .filter((col) => col.grouped)
    .sort((a, b) => (a.level || 0) - (b.level || 0));

  const groupKeys = groupedColumns.map((col) => col.id);
  const data = formatDataForCollapsibleTable(rows, groupKeys);

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            {columnNames.map((col, idx) =>
            col.visible && col?.level >= 0?
              col.info ? (
                <ToolTipCell column={col} key={idx} />
              ) : (
                <TableCell align={col?.align ? col.align : "right"} key={idx}>
                  {col.displayName}
                </TableCell>
              ): null
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, rowIdx) => (
            <Row key={rowIdx} row={row} columnNames={columnNames} level={0} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
