import { createAsyncThunk } from "@reduxjs/toolkit"
import { AddCustomerSubnodeType, FETCH_CUSTOMER_PRICE_MODELS, REMOVE_CUSTOMER_PRICE_MODEL, SAVE_PRICE_MODEL } from "../actionTypes/customer"
import { FetchFromBackoffice, PostBackoffice } from "../../utils/request"
import { PriceModelEnvelope } from "../../pages/customers/pricemodel/types"
import { backofficeCommandURL } from "../../config"
import { postAsync } from "../../utils/ajax"
import { sec } from "../../utils/auth0Helper"




export const fetchCustomerPriceModels = createAsyncThunk(
    FETCH_CUSTOMER_PRICE_MODELS,
    async (customerId: {customerId: string | undefined}, thunkAPI) => {
      const response = await FetchFromBackoffice<PriceModelEnvelope[]>(`customers/${customerId.customerId}/price_models`)
    return response
    },
  )
  export const removePriceModel = createAsyncThunk(
    REMOVE_CUSTOMER_PRICE_MODEL,
    async (params: {params:{validFrom: string, id: string}}, thunkAPI) => {
       const response = await PostBackoffice(`cmd/node/price-model/delete`, params.params).then(  a => console.log(a))
    return response
    }
  )

  export const addCustomerSubnodeThunk = createAsyncThunk(
    "ADD_CUSTOMER_SUBNODE",
    async (customerSubnode:AddCustomerSubnodeType, thunkAPI) => {
      let url = `${backofficeCommandURL()}/cmd/store/create`

      const command =
      {
        name: customerSubnode.name,
        invoice_AddressLine1: customerSubnode.invoice_AddressLine1,
        invoice_AddressLine2: customerSubnode.invoice_AddressLine2,
        invoice_PostalCode: customerSubnode.invoice_PostalCode,
        invoice_Locality: customerSubnode.invoice_Locality,
        visiting_AddressLine1: customerSubnode.visiting_AddressLine1,
        visiting_AddressLine2: customerSubnode.visiting_AddressLine2,
        visiting_PostalCode: customerSubnode.visiting_PostalCode,
        visiting_Locality: customerSubnode.visiting_Locality,
        location_Longitude: 0,//customerSubnode.location_Longitude,
        location_Latitude: 0,//customerSubnode.location_Latitude,
        companyId: customerSubnode.parentId
      }
    
      const accessToken: string = await sec.getAccessTokenSilently()
      const response: Response = await postAsync( url, JSON.stringify(command), accessToken)
      if(response.status === 202){
        return response
      }
      return thunkAPI.rejectWithValue(response.status)

      // if (response && response.ok) {
      //   try {
      //     response.json().then(m => {
      //       const id: string = m?.Message as string
      //       getStore().dispatch({ type: 'CUSTOMER_SUBNODE_ADDED', id: id, customerSubnode: action.customerSubnode } as CustomerActionTypes)
      //     })
    
      //   } catch (e) {
      //     console.error('Error trying to process response Json:', e)
      //     yield put({ type: 'ADDING_CUSTOMER_SUBNODE_FAILED', customerSubnode: action.customerSubnode } as CustomerActionTypes)
      //   }
      // }
      // else {
      //   yield put({ type: 'ADDING_CUSTOMER_SUBNODE_FAILED', customerSubnode: action.customerSubnode } as CustomerActionTypes)
      // }
    }
  )

  export const savePriceModel = createAsyncThunk(
    SAVE_PRICE_MODEL,
    async (command:{model:any} , thunkAPI) => {        
        const arg:AddInitialFeeWithPerExecutionThresholds =
        {
          id: command.model.id,
          validFrom: command.model.date.toFormat("yyyy-MM-dd"),
          currency: command.model.currency,
          initialFee: command.model.initialCost as number,
          thresholds: command.model.threshHolds?.map((t) => {
            return {from: t.trigger as number, perExecution:t.cost as number } 
          })
      
        }
        const url = `${backofficeCommandURL()}${"cmd/node/price-model/add/initialfeewithperexecutionthresholds"}`;
        const token = await sec.getAccessTokenSilently()
        const response = await postAsync(url, JSON.stringify(arg), token)
        if(response.status === 202){
            return response
        }
        return thunkAPI.rejectWithValue(response.status)

    }
  )

  type AddInitialFeeWithPerExecutionThresholds = {
    id:string,
    validFrom: string,
    currency: string,
    initialFee: number,
    thresholds:{from:number, perExecution:number}[]
  }
  




