import React from "react"
import { InvoiceResponse, NodeInvoiceSummary, RecipeExecutions } from "./types"
import { getStore } from "../../../state/configureStore"

export const InvoiceSpecifications = ({invoice}:{invoice?:InvoiceResponse}) => {
    const recipes = invoice?.recipesUsed ?? new Array<RecipeExecutions>()
    const nodes = invoice?.nodes ?? new Array<NodeInvoiceSummary>()
    const recipesCount = recipes?.length ?? 0;

    const isTablet = getStore().getState().dimensions.device === "tablet"
    const isMobile = getStore().getState().dimensions.device === "mobile"

    return (
        <table className="invoice_table" style={isTablet ? { fontSize: 13 } : isMobile ? { fontSize: 12 } : undefined}>
            <thead style={isMobile ? { fontSize: 12 } : undefined}>
                <tr>
                    <th>Store</th>
                    <th>Machine</th>
                    {recipes.map((r, idx) => (<th className="right" key={idx}>{r.name}</th>))}
                    <th className="right">Quantity</th>
                    <th className="right">Unit Price</th>
                    <th className="right">Total</th>
                </tr>
                <tr>
                    <th></th>
                    <th></th>
                    {recipes.map((r, idx) => (<th className="right" key={idx}><span className="art-no">({r.articleNo})</span></th>))}
                    <th></th>
                    <th></th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {nodes.map((n, idx) => <StoreView key={idx} node={n} recipesCount={recipesCount} />)}
            </tbody>
            <tfoot>
                <SummaryView isMobile={isMobile || isTablet} invoice={invoice} recipesCount={recipesCount} />
            </tfoot>
        </table>
    )
}

const SummaryView = ({invoice, recipesCount, isMobile}:{invoice?:InvoiceResponse, recipesCount: number, isMobile: boolean}) => {

    return(
        <>
        <tr className="summary" style={{width: '100%'}}>
            <td></td>
            <td></td>
            <EmptyColumns count={isMobile ? recipesCount - 1 : recipesCount} />
            <td></td>
            <td className="right"><span className="bold">Total</span></td>
            <td colSpan={isMobile ? 2 : 1} className="right"><span className="bold">{invoice?.price?.totalString}</span></td>
        </tr>
        </>
    )
}

const EmptyColumns = ({count}:{count:number}) => {
    return (
        <>
            {Array.from({length: count}).map((_, idx) => (<td key={idx}></td>))}
        </>
    )
}

const StoreView = ({node, recipesCount}:{node:NodeInvoiceSummary, recipesCount: number}) => {
    return (
        <tr>
            <td>{node?.nodeName}</td>
            <td>{node?.machines}</td>
            {node?.executions.recipes.map((r, idx) => (<td className="right" key={idx}>{r.executions}</td>))}
            <td className="right">{node?.executions.recipes.reduce((n, r) => n + r.executions, 0)}</td>
            <td className="right">{node?.price.averageString}</td>
            <td className="right">{node?.price.totalString}</td>
        </tr>
    )
}


