import React from "react"
import { Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material"
import { ColumnDefinition } from "."
import {RootState } from "../../../state/configureStore"
import { useSelector } from "react-redux"

export const TableView = <T extends object,>(
{
    items = [],
    columns,
    columns_mobile = columns,
    onRowClick = () => {}
}:{
    columns: Array<ColumnDefinition<T>>
    columns_mobile?: Array<ColumnDefinition<T>>
    items: Array<T>
    onRowClick?: (item:T) => void
}
) => {
  const isMobile = useSelector<RootState>(state => state.dimensions.device) === "mobile"

    return (
        <Table>
            <TableHead>
                <TableRow>
                    {(isMobile ? columns_mobile : columns).map((c, idx) => (<TableHeader key={idx} title={c.Title} />))}
                </TableRow>
            </TableHead>
            <TableBody>
                {items.map((i, idx) => (
                <TableRow key={idx}>
                    {(isMobile ? columns_mobile : columns)
                    .map((c, idx) => (<TableColumnContent key={idx} column={c} item={i} onCellClick={(item) => onRowClick(item)} />))}
                </TableRow>
                ))}
            </TableBody>
        </Table>
    )
}

const TableColumnContent = <T extends object,>({
    column,
    item,
    onCellClick = () => {}
}:{
    column: ColumnDefinition<T>
    item: T
    onCellClick?: (item:T, column: ColumnDefinition<T>) => void
}) => {
    const value = (typeof column.Property === 'function' ? column.Property(item) : item[column.Property]) as string

    return (
        <TableCell onClick={() => onCellClick(item, column)}>
            {value}
        </TableCell>
    )
}

const TableHeader = ({title}:{title:string}) => {

    return (
        <TableCell sx={{fontFamily: 'DIN'}}>
            <TableSortLabel
                hideSortIcon={true}
                sx={{":hover": { cursor: "default" }}}
                >
            {title}
            </TableSortLabel>
        </TableCell>
    )
}