import React, { useEffect } from 'react'
import { AppDispatch, StoreState } from '../../state/configureStore'
import { connect, useDispatch } from 'react-redux'
import { AnyAction, Dispatch } from 'redux'
import Page from '../../components/molecules/page'
import { fetchMachines } from '../../state/actions/machine'
import { DataLoadingText } from '../../components/atoms/text/dataLoadingText'
import OverviewMap from './overviewMap'
import { Box } from '@mui/material'
import theme from '../../theme'
import { fetchNodeColors } from '../../state/thunks/nodes'

function DashboardPage(
  state: ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>
): React.ReactElement {
  const dispatch = useDispatch<AppDispatch>()
  useEffect(() => {
    state.fetchMachines( "dashboard_view")
    dispatch(fetchNodeColors())
  }, [])

  return (
    <Page hideBreadcrumbs fullView>
      { state.fetchingState === 'loading' ? 
        [
          <Box key={'loadingContainer'} sx={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', backgroundColor: theme.palette.primary.light}}><DataLoadingText key={'loadingText'} showSpinner /></Box> 
        ] : 
        state.fetchingState === 'failed' ? 
        [ 
          <DataLoadingText key={'fetchingErrorText'} text={'There was an issue loading data from the server.'} /> 
        ] : 
        <OverviewMap />
      }
      </Page>
  )
}

const mapStateToProps = (state: StoreState) => ({
  signalRLogs: state.signal_r_logs,
  machines: state.machines,
  fetchingState: state.machine_fetching
})

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  fetchMachines: (prop) => dispatch(fetchMachines(prop)),
})

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPage)