import React, { useState } from "react"
import { CompactPicker, CirclePicker } from 'react-color';
import Text from "../atoms/text/text";
import { Unstable_Popup as BasePopup } from '@mui/base/Unstable_Popup';
import { getStore } from "../../state/configureStore";
import { Paper } from "@mui/material";
import { padding } from "../../theme";


type ColorPickerProps = {
  color: string, // hex code
  setColor: (string) => void
}

export const ColorPicker = ({ color, setColor }: ColorPickerProps) => {
  const [showPicker, setShowPicker] = useState(false)
  const [popupAnchor, setPopupAnchor] = useState<null | HTMLElement>(null)

  const isDesktop = getStore().getState().dimensions.device === 'desktop'

  const handleClick = (event?: React.MouseEvent<HTMLElement>) => {
    setShowPicker(!showPicker)
    if (event) setPopupAnchor(popupAnchor ? null : event.currentTarget);
  };

  return (
    <React.Fragment>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 10 }}>
        <Text>Color:</Text>
        <div style={{ 
          backgroundColor: color ?? 'transparent',
          border: '0.5px solid black',
          height: 25,
          width: 25,
          borderRadius: 25,
          cursor: 'pointer',
          }} onClick={handleClick}/>

      </div>
      <BasePopup style={{zIndex: 9999}} id={'picker'} open={showPicker} anchor={popupAnchor} placement={isDesktop ? "bottom" : "bottom-end"}>
        <Paper elevation={1} sx={{ padding: padding/3, borderRadius: 2.5 }}>
        {
          isDesktop ? <CompactPicker color={color} onChange={(col, event) => setColor(col.hex)} />
          : <CirclePicker color={color} onChange={(col, event) => {setColor(col.hex); handleClick()}} />
        }
        </Paper>
      </BasePopup>
    </React.Fragment>
  )
}