import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchNodeColors, setNodeColors } from "../thunks/nodes";

// Define the shape of individual node color objects
interface NodeColor {
    node_id: string;
    node_color: string;
    name: string;
}

// Define the shape of the slice's state
interface NodeColorsState {
    loading: boolean;
    error: string | null; 
    nodeColors: NodeColor[];
}

const initialState: NodeColorsState = {
    error: null,
    loading: false,
    nodeColors: []
};

const nodeSlice = createSlice({
    name: 'colors',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchNodeColors.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchNodeColors.fulfilled, (state, action: PayloadAction<NodeColor[]>) => {
                state.loading = false;
                state.nodeColors = action.payload; 
            })
            .addCase(fetchNodeColors.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'An error occurred'; 
            }).addCase(setNodeColors.fulfilled, (state,action) => {
                state.loading = false; 
                const node = state.nodeColors.find(s => s.node_id === action.meta.arg.id);
                if (node) {
                    node.node_color = action.meta.arg.color;
                }else{
                    state.nodeColors.push({node_color: action.meta.arg.color, node_id: action.meta.arg.id, name:action.meta.arg.name })
                }
            } 
            ).addCase(setNodeColors.pending, (state) => {
                state.loading = true;
            }).addCase(setNodeColors.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'An error occurred'; 
            });
    },
});

export const node_colors = nodeSlice.reducer;
