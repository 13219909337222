import { TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { AddModal } from '../../../components/molecules/AddModal'
import { UpdateUserType } from '../../../state/actionTypes/user'
import { StoreState } from '../../../state/configureStore'
import { AnyAction, Dispatch } from 'redux'
import { updateUser } from '../../../state/actions/user'
import { isValidEmail } from '../../../utils/text'
import { UserState } from '../../../state/reducers/user'

type IComponentProps = IComponentOwnProps & IComponentStoreProps & IComponentDispatchProps;

type IComponentStoreProps = ReturnType<typeof mapStateToProps>;
type IComponentDispatchProps = ReturnType<typeof mapDispatchToProps>;

interface IComponentOwnProps {
  open: boolean;
  closeModal: Function;
  user: UserState | undefined
}


function UpdateUserModal(props: IComponentProps): React.ReactElement | null {
  const [emailError, setEmailError] = useState(false)
  const [firstNameError, setFirstNameError] = useState(false)
  const [lastNameError, setLastNameError] = useState(false)

  const [email, setEmail] = useState(props.user?.email ?? '')
  const [firstName, setFirstName] = useState(props.user?.givenName ?? '')
  const [lastName, setLastName] = useState(props.user?.familyName ?? '')


  useEffect(() => {
    setEmail(props.user?.email ?? '')
    setFirstName(props.user?.givenName ?? '')
    setLastName(props.user?.familyName ?? '')
  }, [props.user])

  const onSave = () => {
    const emailValid = email && isValidEmail(email) ? true : false
    if (!emailValid) setEmailError(true)
    else setEmailError(false)

    if (!firstName) setFirstNameError(true)
    else setFirstNameError(false)

    if (!lastName) setLastNameError(true)
    else setLastNameError(false)

    if (emailValid && firstName && lastName) {
      const name = firstName + ' ' + lastName

      const toSave: UpdateUserType = {
        email: email,
        givenName: firstName,
        familyName: lastName,
        name: name,
        userId: props.user!.userId,   
      }

      props.updateUser(toSave)
    }
  }

  const resetModal = () => {
    setEmailError(false)
    setFirstNameError(false)
    setLastNameError(false)

    setEmail(props.user?.email ?? '')
    setFirstName(props.user?.givenName ?? '')
    setLastName(props.user?.familyName ?? '')
  }

  if (!props.user) return null

  return (
    <AddModal
      title={'Update User'}
      saving={props.user_saving}
      open={props.open}
      savingDisabled={!props.user}
      savingFailedMessage={'The user could not be updated. Please check your inputs below and your internet connection and try again.'}
      closeModal={() => { resetModal(); props.closeModal() }} onSave={onSave}>

      <TextField
        autoFocus
        margin="normal"
        id="email_input_field"
        label="Email Address"
        type="email"
        value={email}
        fullWidth
        onChange={(event) => setEmail(event.target.value)}
        error={emailError}
        helperText={emailError ? "Please enter a valid email address." : null}
      />
      <TextField
        margin="normal"
        id="first_name_input_field"
        label="First Name"
        type="text"
        value={firstName}
        fullWidth
        onChange={(event) => setFirstName(event.target.value)}
        error={firstNameError}
        helperText={firstNameError ? "Please enter a first name." : null}
      />
      <TextField
        margin="normal"
        id="last_name_input_field"
        label="Last Name"
        type="text"
        value={lastName}
        fullWidth
        onChange={(event) => setLastName(event.target.value)}
        error={lastNameError}
        helperText={lastNameError ? "Please enter a last name." : null}
      />
    </AddModal>
  )
}

const mapStateToProps = (state: StoreState) => ({
  user_saving: state.user_saving
})

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  updateUser: (user: UpdateUserType) => dispatch(updateUser(user))
})

export default connect(mapStateToProps, mapDispatchToProps)(UpdateUserModal)