import { DateTime } from 'luxon'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { AnyAction, Dispatch } from 'redux'
import { DataLoadingText } from '../../components/atoms/text/dataLoadingText'
import TableComponent from '../../components/molecules/table'
import { StoreState } from '../../state/configureStore'
import { toReadableDuration, toReadableTime } from '../../functions'
import { fetchMachineOnlineHistory } from '../../state/actions/machine'
import LogLevelDisplay from '../../components/molecules/logs/logLevelDisplay'

const columnNames = [
  {
    displayName: '',
    serverName: null//'level'
  },
  {
    displayName: 'From',
    serverName: 'timestamp'
  },
  {
    displayName: 'To',
    serverName: null, //'record'
  },
  {
    displayName: 'Duration',
    serverName: null //'source'
  }
]

const columnNames_mobile = [
  {
    displayName: '',
    serverName: null//'level'
  },
  {
    displayName: 'From – To ',
    serverName: 'timestamp'
  },
  {
    displayName: 'Duration',
    serverName: null //'source'
  }
]

export type SortOn = 'timestamp' // | 'level' | 'record' | 'source'

const UptimeTable = ({
  fetchMachineOnlineHistory,
  serialId,
  dimensions,
  machine_online_history,
  fetchingState
}) => {
  const now = DateTime.now()

  const [countPerPage, setCountPerPage] = useState<number>(10)
  const [page, setPage] = useState<number>(0)
  const [descending, setDescending] = useState(true)
  const [sortingOn, setSortingOn] = useState<SortOn>('timestamp')

  useEffect(() => {
    if (serialId) fetchMachineOnlineHistory(serialId)
  }, [serialId, fetchMachineOnlineHistory])

  const isMobile = dimensions.device === 'mobile'

  return (fetchingState !== 'failed' ?
    (fetchingState === 'loading' ? <DataLoadingText showSpinner text={'Loading logs ...'} />
      : (machine_online_history ? <TableComponent
          animateTable
          countPerPage={countPerPage}
          page={page}
          descending={descending}
          sortingOn={sortingOn}
          setCountPerPage={setCountPerPage}
          // setsPage={setPage}
          setDescending={setDescending}
          setSortingOn={setSortingOn}
          columnNames={ isMobile ? columnNames_mobile : columnNames}
          rows={(machine_online_history?.items ?? [])?.map((log, index) => {
            const timestampFromLog = toReadableTime(DateTime.fromISO(log.startAt), now, isMobile)
            const timestampToLog =  toReadableTime(DateTime.fromISO(log.endAt), now, isMobile)
            const duration =  toReadableDuration(DateTime.fromISO(log.endAt), DateTime.fromISO(log.startAt))
            return isMobile ? {
              'id': index,
              'Level': <LogLevelDisplay iconOnly level={log.connectionState === "Connected" ? 'Up' : 'Down'}/>,
              'Timestamp_to_from': [timestampFromLog, timestampToLog],
              'Duration': duration
            } : {
              'id': index,
              'Level': <LogLevelDisplay level={log.connectionState === "Connected" ? 'Up' : 'Down'}/>,
              'Timestamp_from': timestampFromLog,
              'Timestamp_to': timestampToLog,
              'Duration': duration
            }
          })}
          total={0} /> : <DataLoadingText text={'No logs available.'} />))
    : <DataLoadingText text={'Failed to fetch logs from the server.'} />
  )
}
const mapStateToProps = (state: StoreState) => ({
  fetchingState: state.machine_online_history_fetching,
  dimensions: state.dimensions,
  machine_online_history: state.machine_online_history
})

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  fetchMachineOnlineHistory: (
    serialId: string
  ) => dispatch(fetchMachineOnlineHistory(serialId))
})

export default connect(mapStateToProps, mapDispatchToProps)(UptimeTable)