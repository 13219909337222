import React, { useEffect, useState } from "react"
import { CustomButton } from "../../../../components/atoms/buttons"
import { GetCommandResponse, PagedResponse, useCommandBackend, useQueryBackend } from "../../../../utils/requestHelpers"
import { AddUserCommand, AddUserCommandSchema, ChildNode } from "./types"
import { AddUserForm } from "./AddUserForm"


export const AddUserButton = ({nodeId, onSaved}:{
    nodeId: string
    onSaved: (user: AddUserCommand) => void
}) => {
    const commandApi = useCommandBackend("Backoffice")
    const queryApi = useQueryBackend("Backoffice")
    let children: Array<ChildNode> = []
    const [show, setShow] = useState<boolean>()
    const [command, setCommand] = useState<AddUserCommand | undefined>()
    const schema = AddUserCommandSchema
    
    const refreshCommand = () => {
        if(nodeId)
            commandApi.Get<GetCommandResponse<AddUserCommand>>(`/cmd/user/create/${nodeId}`)
                .Data(d => {
                    let nodeIds:Array<string> = children.map(c => c.id)
                    nodeIds.push(nodeId)
                    setCommand({...d.command, organisationIds: nodeIds})
                })
    }

    const handleSave = (cmd: AddUserCommand) => {
        commandApi.PostCommand('/cmd/user/create', cmd)
            .Data(result => {
                onSaved(result.command)
                setShow(false)
                refreshCommand()
            })
    }

    useEffect(() => {
        queryApi.Get<PagedResponse<ChildNode>>(`/customers/${nodeId}/children`)
            .Data(d => {
                children = d.items
                refreshCommand()
            })
    }, [nodeId])

    return (
        <>
            <CustomButton noBg disabled={!command} onClick={() => setShow(command !== undefined)}>
                {'+ Add User'}
            </CustomButton>
            {show && <AddUserForm command={command!} schema={schema} onCancel={() => setShow(false)} onSave={handleSave} />}
        </>
    )
}