import React, { useCallback, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { DateTime } from "luxon";
import { SubpageTitle } from "../../components/atoms/text/titles";
import { DateRangePickerPopUp, TagButton } from "../../components/molecules/datepickers/dateRangePickerPopUp";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  fetchRecipesPerNode,
  fetchTrendsAll,
} from "../../state/thunks/resports.ts";
import { useDispatch, useSelector } from "react-redux";
import { padding } from "../../theme.ts";
import Text from "../../components/atoms/text/text.tsx";
import {
  CollapsibleTable,
  ColumnName,
} from "../../components/molecules/CollapsableTable.tsx";
import ExportButton from "../../components/atoms/buttons/ExportButton.tsx";
import { AppDispatch } from "../../state/configureStore.ts";

interface CyclesItem {
  customer_name: string;
  organisation_name: string;
  total_sum: number;
  excluded: number;
  leather: number;
  suede: number;
  synthetic: number;
  textile: number;
  compare_total: number;
}

const getPrefix = (value: number) => {
  return value > 0 ? "+" : value < 0 ? "-" : "";
};

export const SalesPerRecipeTable = () => {
  const isMobile = useSelector(
    (state: any) => state.dimensions.device === "mobile"
  );
  const columnNames: ColumnName[] = [
    {
      displayName: "Organisation",
      id: "organisation",
      aggregate: false,
      grouped: true,
      level: 0, // Top level group
      align: "left",
      visible: false // New property to control visibility

    },
    {
      displayName: "Name",
      id: "store",
      aggregate: false,
      grouped: false,
      level: 1, // Second level group
      align: "left",
      visible: true
    },
    {
      displayName: "Total",
      id: "total_sum",
      aggregate: true,
      grouped: false,
      level: 1, // No grouping at this level
      align: "right",
      visible: true
    },
    {
      displayName: "Leather",
      id: "leather",
      aggregate: true,
      grouped: false,
      level: 1, // No grouping at this level
      align: "right",
      visible: true

    },
    {
      displayName: "Synthetic",
      id: "synthetic",
      aggregate: true,
      grouped: false,
      level: 1, // No grouping at this level
      align: "right",
      visible: true
    },
    {
      displayName: "Suede",
      id: "suede",
      aggregate: true,
      grouped: false,
      level: 1, // No grouping at this level
      align: "right",
      visible: true
    },
    {
      displayName: "Textile",
      id: "textile",
      aggregate: true,
      grouped: false,
      level: 1, // No grouping at this level
      align: "right",
      visible: true
    },
    {
      displayName: "Excluded",
      id: "excluded",
      aggregate: true,
      grouped: false,
      level: 1, // No grouping at this level
      align: "right",
      visible: true
    },
    {
      displayName: "Daily Average",
      id: "avrage_day",
      aggregate: true,
      info: "Total Treatments / No. of Days",
      grouped: false,
      level: 1, // No grouping at this level
      align: "right",
      visible: true,
      formatter: (value) => parseFloat(value?.toFixed(1)),
    },
    {
      displayName: "Trend",
      id: "avrage_day_trend",
      aggregate: true,
      info: "Daily Average Selected Period - Daily Average Last Period",
      grouped: false,
      level: 1, // No grouping at this level
      align: "right",
      visible: true,
      formatter: (value) => (
          getPrefix(value) + " " + parseFloat(Math.abs(value)?.toFixed(1))
      ),
    },
  ];
  
  const [from, setFrom] = useState<DateTime>(
    DateTime.now().startOf("week")
  );
  const [to, setTo] = useState<DateTime>(
    DateTime.now()
  );
  const [compareFrom, setCompafreFrom] = useState<DateTime>();
  const [compareTo, setCompafreTo] = useState<DateTime>();
  const [trendKpi, setTrend] = useState<{ last: number; this: number }>();
  const [data, setData] = useState<Array<CyclesItem>>([]);
  const [open, setOpen] = useState<boolean>(false);
  
  const getTrend = useCallback(
    (total_sum:number, compare_total:number) => {
      return from && to
        ? (
          (total_sum === 0 ? 0 :  total_sum / (to?.diff(from, "days")?.days < 1 ? 1 :  to?.diff(from, "days")?.days ) ) 
           - (compare_total=== 0 ? 0 : compare_total /  ( to?.diff(from, "days")?.days <1 ? 1 : to?.diff(from, "days")?.days  ))
            )
        : 0;
    },
    [from, to]
  );
  const getAvrage = useCallback(
    (total_sum:number) => {
      return from && to && total_sum
        ? (total_sum / ( to?.diff(from, "days")?.days <1 ? 1 : to?.diff(from, "days")?.days  ))
        : 0;
    },
    [from, to]
  );

  const setCompare = (to: DateTime, from: DateTime) => {
    setCompafreTo(to?.minus(stepper));
    setCompafreFrom(from?.minus(stepper));
  };

  function calculateSalesTrend(previousWeekSales, currentWeekSales) {
    const prefix = previousWeekSales > currentWeekSales ? "" : "+";
    if ((previousWeekSales || currentWeekSales) === undefined) return "- -";
    if (previousWeekSales === 0 && currentWeekSales === 0) {
      return 0;
    } else if (previousWeekSales === 0) {
      return prefix + "" + 100 + "%";
    } else {
      const trend =
        ((currentWeekSales - previousWeekSales) / previousWeekSales) * 100;
      return +"" + parseFloat(trend.toFixed(1)) + "%";
    }
  }
  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    if (!to || !from) return;
    setCompare(to, from);
    const thunkRecipePerNode = fetchRecipesPerNode({ from: from, to: to.plus({days: 1}), view: "report_sales_view"});
    dispatch(thunkRecipePerNode)
      .then(unwrapResult)
      .then((result) => setData([...result]))
      .catch((e) => console.error(`Error: ${e}`));
  }, [to, from, dispatch]);

  useEffect(() => {
    if (!to || !from) return;
    const thunKpi = fetchTrendsAll({
      from: from,
      to: to.plus({days: 1}),
      compareFrom: compareFrom,
      compareTo: compareTo,
      view: "report_sales_view"
    });
    dispatch(thunKpi)
      .then(unwrapResult)
      .then((result) => setTrend(result))
      .catch((e) => console.error(`Error: ${e}`));
  }, [compareTo, compareFrom, dispatch]);

  const prepData = data.map((c) => ({
    store: c.customer_name,
    organisation: c.organisation_name,
    total_sum: c.total_sum,
    leather: c.leather,
    synthetic: c.synthetic,
    suede: c.suede,
    textile: c.textile,
    excluded: c.excluded,
    avrage_day: getAvrage(c.total_sum),
    avrage_day_trend: getTrend(c.total_sum, c.compare_total),
  }));
  const today = DateTime.now()
  const stepper = to?.diff(from, "days").days <= 7 ? { week: 1 } : {month: 1} 
  const step = to?.diff(from, "days").days <= 7 ? "week" : "month" 
  const todayRollingSeven = today.minus({week:1});
  const lastWeekMonday = today.minus({week: 1  }).startOf("week");
  const lastWeekSunday = today.minus({week: 1}).endOf("week").startOf("day");

  const presets:TagButton [] = [
    {
      name: "This week",
      id: "this",
      action: () => {
        setFrom(today.startOf("week"));
        setTo(today);
        setOpen(false);
      },
      default: true
    },
    {
      name:  "Last week",
      id: "last",
      action: () => {
        setFrom(lastWeekMonday);
        setTo(lastWeekSunday);
        setOpen(false);
      },
    },
    {
      name:  "7 days Rolling" ,
      id: "rolling",
      action: () => {
        setFrom(todayRollingSeven);
        setTo(today);
        setOpen(false);
      },
    },
    {
      name:  "This month" ,
      id: "this_month",
      action: () => {
        setFrom(today.startOf("month"));
        setTo(today);
        setOpen(false);
      },
    },
  ];
  return (
    <Box>
      <SubpageTitle title={"Sales per store"} />
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          flex: 1,
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div style={!isMobile ? { display: "flex", flexDirection: "row" } : {}}>
          <DateRangePickerPopUp
            startDate={from}
            endDate={to}
            onStartDatePicked={setFrom}
            onEndDatePicked={setTo}
            presets={presets}
            setOpen={setOpen}
            open={open}
          ></DateRangePickerPopUp>
          <div
            style={{
              marginBottom: padding * 8,
              //width: 350,
              marginLeft: padding * 12,
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <KPIDisplay description="Total" value={trendKpi?.this.toString()} />
            <KPIDisplay
              description="Trend"
              value={calculateSalesTrend(
                trendKpi?.last,
                trendKpi?.this
              ).toString()}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            paddingRight: padding * 3,
          }}
        >
          {!isMobile && (
            <ExportButton
              data={prepData}
              fileName={`store_sales_${from?.toFormat("LLL_dd")}-${to?.toFormat(
                "LLL_dd"
              )}.xlsx`}
              columnNames={columnNames}
              groupBy="organisation"
            />
          )}
        </div>
      </div>
      <Box sx={[isMobile && { marginBottom: padding / 2 }]}>
        <CollapsibleTable
          columnNames={columnNames}
          rows={prepData}
        ></CollapsibleTable>
      </Box>
      {isMobile && (
        <ExportButton
          data={prepData}
          fileName={`store_sales_${from?.toFormat("LLL_dd")}-${to?.toFormat(
            "LLL_dd"
          )}.xlsx`}
          columnNames={columnNames}
          groupBy="organisation"
        />
      )}
    </Box>
  );
};

export const KPIDisplay = ({
  value,
  description,
}: {
  value?: string;
  description?: string;
}) => {
  return (
    <div
      style={{
        flexDirection: "column",
        alignItems: "center",
        marginRight: padding * 8,
      }}
    >
      <SubpageTitle noPadding title={value ?? ""} />
      <Text compact description>
        {description}
      </Text>
    </div>
  );
};
