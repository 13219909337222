import { TableView } from "./TableView"

export {
    TableView
}

export interface ColumnDefinition<T extends object> {
    Property: (keyof T & string) | ((item: T) => string)
    Title: string
}



export default TableView