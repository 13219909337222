import React, { Dispatch, useEffect, useState } from "react";
import { DateTime } from "luxon";
import { IPage,} from "../../../common-types";
import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { ContentMenu, MenuItemProps } from "../../../components/atoms/menu";
import { ExcludeFromInvoice } from "./exclude-from-invoice";
import { toReadableTime } from "../../../functions";
import { SoldRecipes } from "../../customers/customer";
import { useDispatch, useSelector } from "react-redux";
import Text from "../../../components/atoms/text/text";
import {
  fetchRecipesForMachine,
  RecipeItem,
  setRecipeAsIncludedinInvoicing,
} from "../../../state/thunks/machines";
import { AppDispatch, RootState } from "../../../state/configureStore";
import theme from "../../../theme";

export const RecipesList = ({
  MachineId,
}: {
  MachineId: string | undefined;
}) => {

  const [paging, setPaging] = useState<IPage>({ count: 10, page: 0 });
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const dispatch = useDispatch<AppDispatch>();
  const recipes = useSelector<RootState>(
    (state) => state.machine_recipes.recipes
  );
  const total = useSelector<RootState>((state) => state.machine_recipes.total);
  const [selectedRow, setSelectedRow] = useState<RecipeItem>();
  useEffect(() => {
    if (MachineId) {
      dispatch(
        fetchRecipesForMachine({
          machineId: MachineId,
          paging: paging.page,
          count: paging.count,
        })
      );
    }
  }, [MachineId, paging]);

  const isMobile =
    useSelector<RootState>((state) => state.dimensions.device) === "mobile";

  return (
    <>
      <SoldRecipes id={MachineId} queryString={"recipes-run-daily-machine"} />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Started at</TableCell>
            <TableCell>Recipe</TableCell>
            {isMobile ? null : <TableCell>Duration</TableCell>}
            <TableCell>Comment</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {recipes?.map((item, idx) => (
            <RecipeItemView
              key={idx}
              item={item}
              dialogOpen={dialogOpen}
              setDialogOpen={setDialogOpen}
              setSelecetdRow={setSelectedRow}
              // noDurationColumn={isMobile}
              // Index={idx}
              // {...item}
              // excludeFromInvoicing={(transactionId, reasonText) =>
              //   setExcludeFromInvoice({ transactionId, reasonText })
              // }
            />
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              count={total}
              page={paging.page}
              rowsPerPage={paging.count}
              onPageChange={(_, page) =>
                setPaging({ count: paging.count, page: page })
              }
              onRowsPerPageChange={(e) =>
                setPaging({
                  count: Number.parseInt(e.target.value) ?? 10,
                  page: 0,
                })
              }
            />
          </TableRow>
        </TableFooter>
      </Table>
      <ExcludeFromInvoice
        open={dialogOpen}
        item={selectedRow}
        setOpen={setDialogOpen}
      />
    </>
  );
};

const RecipeItemView = ({
  item,
  setDialogOpen,
  setSelecetdRow,
}: {
  item: RecipeItem;
  setDialogOpen: Dispatch<boolean>;
  setSelecetdRow: Dispatch<RecipeItem>;
  dialogOpen: boolean;
}) => {
  const [duration, setDuration] = useState<string>();
  const dispatch = useDispatch<AppDispatch>();
  const [canBeAddedBackToInvoicing, setCanBeAddedBackToInvoicing] = useState<boolean>(false);
  const [menus, setMenus] = useState<MenuItemProps[]>([])
  const saving = useSelector<RootState>((state) => state.machine_recipes.savingItems.find(s => s.transactionId === item.transactionId));

  useEffect(() => {
    if (item.endedAt) {
      const calculatedDuration: string = DateTime.fromISO(item.endedAt)
        .diff(DateTime.fromISO(item.startedAt))
        .toFormat("mm:ss");

      setDuration(calculatedDuration);

      const canBeAddedBack =
        DateTime.now().startOf("month") <
        DateTime.fromISO(item.endedAt)
          .startOf("month")
          .plus({ month: 1, days: 10 });
      setCanBeAddedBackToInvoicing(canBeAddedBack);
    }
    setMenus([
      !item.excludedFromInvoicing
        ? {
            Label: "No invoicing",
            Enabled: canBeAddedBackToInvoicing,
            onClick: () => {
              setSelecetdRow(item);
              setDialogOpen(true);
            },
          }
        : {
            Label: "Include",
            Enabled: canBeAddedBackToInvoicing,
            onClick: () => includeFromInvoicing(),
          },
    ]);

  }, [item, canBeAddedBackToInvoicing]);

  const started = toReadableTime(
    DateTime.fromISO(item.startedAt),
    DateTime.now()
  ); //DateTime.fromISO(startedAt).toFormat("yyyy-MM-dd hh:mm")
  const includeFromInvoicing = () => {
    dispatch(
      setRecipeAsIncludedinInvoicing({
        machineId: item.machineName,
        transactionId: item.transactionId,
      })
    );
  };

  let classes = "";
  // switch (status) {
  //   // case 'done':
  //   //     classes += ` highlight-ok`
  //   //     break;
  //   case "canceled":
  //     classes += ` highlight-fail`;
  //     break;
  // }

  if (item.excludedFromInvoicingReason) {
    classes += ` invoice-excluded`;
  }

  return (
    <RowWrapper saving={saving} classes={classes}>
      <TableCell>
        <Text style={{ fontSize: 14 }} key={"started"}>
          {started}
        </Text>
      </TableCell>
      <TableCell >{item.recipeName}</TableCell>
      <TableCell>{duration}</TableCell>
      <TableCell>{item.excludedFromInvoicingReason ?? "-"}</TableCell>
      <TableCell>
        <ContentMenu
          Layout="Row"
          Id={`recipe_menu_${item.transactionId}`}
          MenuItems={menus}
        />
      </TableCell>
    </RowWrapper>
  );
};



function RowWrapper({ saving, classes, children }) {
  return (
    <TableRow
      sx={[
        saving && {
          bgcolor: "#ddd",
          opacity: 0.8,
          position: "relative",
          "&::after": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            bgcolor: theme.palette.background.default, // Slight overlay
            opacity: 0.8,
            zIndex: 1,
          }
        },
      ]}
      className={classes}
    >
      {children}
      {saving && (
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 2,
          }}
        >
          <CircularProgress size={24} />
        </Box>
      )}
    </TableRow>
  );
}