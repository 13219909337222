import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'

type TagProps = ({
  color: string,
  title: string,
  titleFontSize?: number
})

const roundTagSize = '15px'

function Tag({
  color,
  title,
  titleFontSize
}: TagProps): React.ReactElement {

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
    }}>
      <Box sx={{
        backgroundColor: color,
        color: 'white',
        aspectRatio: 1,
        height: roundTagSize,
        borderRadius: 25,
        marginRight: 1,
      }} />
      <Typography sx={titleFontSize ? { fontSize: titleFontSize } : undefined} color='textPrimary'>
        {title}
      </Typography>

    </Box>
  )
}

export default Tag