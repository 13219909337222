import React, { useCallback, useEffect, useState } from "react";
import { Box, colors } from "@mui/material";
import { SubpageTitle } from "../../../components/atoms/text/titles";
import { useQueryBackend } from "../../../utils/requestHelpers";
import { Nullable } from "../../../common-types";
import { BarChartView, IDataItem } from "../../../components/atoms/charts";
import { getDistinctPropertyValues } from "../../../functions";
import { DateTime } from "luxon";
import { DateRangePickerPopUp, TagButton } from "../../../components/molecules/datepickers/dateRangePickerPopUp";
import { useSelector } from "react-redux";
import { RootState } from "../../../state/configureStore";
import { IColorMap } from "../../../components/atoms/charts/barChartView";

interface CyclesItem {
  date: string;
  softwareVersion: Nullable<string>;
  amount: number;
}

export const MachineSoftwareVersionHistory = ({
  nodeId,
  name,
}: {
  nodeId?: string;
  name?: string | undefined;
}) => {


  const today = DateTime.now()
  const step = "month"
  const stepper = {month: 1}
  const todayRollingSeven = today.minus(stepper);
  const lastMonthStart = today.minus(stepper).startOf("month");
  const lastMOnthEnd = today.minus(stepper).endOf("month");
  const queryApi = useQueryBackend("Backoffice");
  const [from, setFrom] = useState<DateTime>(todayRollingSeven);
  const [to, setTo] = useState<DateTime>(today);
  const [data, setData] = useState<Array<IDataItem>>([]);

  const [colorMap, setColorMap] = useState<IColorMap[]>([])  


  function generateColorMap(versions: string[]): IColorMap {
    const colorMap: IColorMap = {};
    const totalVersions = versions.length;

    // Generate colors based on position in the sorted array
    versions.forEach((version, index) => {
        const position = index / (totalVersions - 1); // Normalized position (0 for lowest, 1 for highest)
        // Calculate RGB values for red to yellow to green gradient
        const r = position === 1 ? 109 : Math.round(220 - (position) * 190);
        const g = position === 1 ? 156 : Math.round(220 - (position ) * 190);;
        const b = position === 1 ? 119 : Math.round(220 - (position ) * 190);

        colorMap[version] = `rgb(${r}, ${g}, ${b})`;
    });

    return colorMap;
}

 const compareVersions = (a, b) =>  {
  const aParts = a?.split('.').map(Number);
  const bParts = b?.split('.').map(Number);

  for (let i = 0; i < Math.max(aParts.length, bParts.length); i++) {
      const aVal = aParts[i] || 0;  // Default to 0 if part is missing
      const bVal = bParts[i] || 0;
      if (aVal !== bVal) {
          return aVal - bVal;
      }
  }
  return 0; 
}


useEffect(() => {

    if(!from || !to) return
      queryApi
        .Get<Array<CyclesItem>>(
          `software-versions/history-between-${from.toFormat(
            "yyyy-MM-dd"
          )}-to-${to.toFormat("yyyy-MM-dd HH:mm:ss.SSSZZ")}`
        )
        .Data((d) => {
          const allDays = getDistinctPropertyValues(d, "date");
          const allVersions = getDistinctPropertyValues(d, "softwareVersion").sort(compareVersions);
          setColorMap(generateColorMap(allVersions))
          const items = allDays.map((day) => {
            const item: IDataItem = { name: day };
            allVersions
              .filter((s) => !!s)
              .forEach((k) => {
                item[k!] =
                  d.find((d) => d.date === day && d.softwareVersion === k)?.amount ?? 0;
              });
            return item;
          });


          setData(items);
        });
  }, [ from, to]);
  const [open, setOpen] = useState<boolean>(false);
  const presets:TagButton [] = [
    {
      name:  "Rolling month" ,
      id: "rolling",
      action: () => {
        setFrom(todayRollingSeven);
        setTo(today);
        setOpen(false);
      },
    },
    {
      name:  "Last Month",
      id: "last",
      action: () => {
        setFrom(lastMonthStart);
        setTo(lastMOnthEnd);
        setOpen(false);
      },
    },
  ];
  return (
    <Box>
      <SubpageTitle title={name ?? "Software version history"} />
      <Box>
        <DateRangePickerPopUp
          startDate={from}
          endDate={to}
          onStartDatePicked={setFrom}
          onEndDatePicked={setTo}
          presets={presets}
          open={open}
          setOpen={setOpen}
        ></DateRangePickerPopUp>
      </Box>
      <div style={{ width: "100%", height: "400px" }}>
        <BarChartView
          useCostomLEgend
          items={data}
          colorMap={colorMap}
          formatX={(s) => s.substring(s.lastIndexOf("-") + 1)}
        />
      </div>
    </Box>
  );
};
