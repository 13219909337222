import { HubConnection } from "@microsoft/signalr";
import { AppSettingActionTypes, SignalRConnectionActionTypes } from "../actionTypes/app";
import { DeviceType } from "../../theme";

// Define the type for SignalRConnectionReducer properly
export type SignalRConnectionReducer = HubConnection | null;

export const initialSignalRConnectionState: SignalRConnectionReducer = null;

export function signal_r_connection(
  state: SignalRConnectionReducer = initialSignalRConnectionState,
  action: SignalRConnectionActionTypes
): SignalRConnectionReducer {
  switch (action.type) {
    case 'SIGNALR_CONNECTION_STARTED':
      return  {...action.data.HubConnection}
    case 'SIGNALR_CONNECTION_STOPPED':
       return null;
    default:
      return state;
  }
}

// AppSettings reducer with a proper type definition
export type AppSettingsReducer = {
  showHidden: boolean;
}

export const initialAppSettingsState: AppSettingsReducer = {
  showHidden: false,
}

export function settings(
  state: AppSettingsReducer = initialAppSettingsState,
  action: AppSettingActionTypes
): AppSettingsReducer {
  switch (action.type) {
    case 'SET_SHOW_HIDDEN':
      return {
        ...state,
        showHidden: action.value
      };
    default:
      return state;
  }
}


export type DimensionsReducer = {
  device: DeviceType,
  orientation: "portrait" | "landscape"
}
export const initialDimensionsState: DimensionsReducer = {
  device: 'desktop',
  orientation: 'landscape'
}
export function dimensions(state: DimensionsReducer = initialDimensionsState, action: DimensionsActionTypes): DimensionsReducer {
  switch (action.type) {
    case 'VIEW_DIMENSIONS_UPDATED' :
      return {
        ...state,
        device: action.device,
        orientation: action.orientation
      }
    default:
      return state
  }
}