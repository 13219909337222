import { call, put } from 'redux-saga/effects'
import { backofficeCommandURL, backofficeQueryURL, utilsURL } from '../config'
import { CustomerActionTypes, FetchCustomer, FetchCustomerInvoice, FetchCustomers } from '../state/actionTypes/customer'
import MachineActionTypes, { FetchMachineLogs, FetchMachines, FetchOrphanedMachines, FetchMachineOnlineHistory } from '../state/actionTypes/machine'
import { FetchUsers, UserActionTypes } from '../state/actionTypes/user'
import { MachineState } from '../state/reducers/machine'
import { getAsync, postAsync } from './ajax'
import { sec } from './auth0Helper'



class FetchPromise<T> {
  
  private res: (value: T | PromiseLike<T>) => void
  private rej: (reason?: any) => void
  private emp: () => void
  private readonly promise: Promise<T>
//  private readonly emptyPromise: PromiseLike<never>

  constructor() {
    this.promise = new Promise<T>((resolve, reject) => {
      this.res = resolve
      this.rej = reject
    })
  }

  then<TResult1 = T, TResult2 = never, TResult3 = never>(onfulfilled?: ((value: T) => TResult1 | PromiseLike<TResult1>) | null | undefined, onrejected?: ((reason: any) => TResult2 | PromiseLike<TResult2>) | null | undefined, onempty?: (() => TResult3 | PromiseLike<TResult3> | null | undefined)): Promise<TResult1 | TResult2> {
    return this.promise.then(onfulfilled, onrejected)
  }

  finally(onfinally?: (() => void) | null | undefined): Promise<T> {
    return this.promise.finally(onfinally)
  }
  //[Symbol.toStringTag]: string

  catch (onRejected?: (reason: any) => PromiseLike<never>) : Promise<T> {return this.promise.catch(onRejected)}

//  empty (onEmpty?: () => PromiseLike<never>) : void{ this.emptyPromise.then(onEmpty)}

  resolve(value: T | PromiseLike<T>):void { return this.res(value)}

  reject(reason?:any):void {return this.rej(reason)}
}

export const FetchFromBackoffice = <T>(path: string) : FetchPromise<T> => {
  const url = `${backofficeQueryURL()}/${path}`;
  console.debug('FetchFromBackoffice', url)

  const promise = new FetchPromise<T>()
    
  sec.getAccessTokenSilently()
    .then(token => getAsync(url, token))
    .then(r => {
      if(r.status === 204)
      {
      }
      return r;
    })
    .then(r => r.json())
    .then(json => {
      return json
    })
    .then(data => {
      promise.resolve(data as T)
    })
  .catch(error =>{
    console.error(`Error fetching ${url}.`, error)
    promise.reject(error)
  });

  return promise;
}

export const FetchFromBackofficeCmd = <T>(path: string) : FetchPromise<T> => {
  const url = `${backofficeCommandURL()}/${path}`;
  console.debug('FetchFromBackofficeCmd', url)

  const promise = new FetchPromise<T>()
    
  sec.getAccessTokenSilently()
    .then(token => getAsync(url, token))
    .then(r => {
      if(r.status === 204)
      {
      }
      return r;
    })
    .then(r => r.json())
    .then(json => {
      return json
    })
    .then(data => {
      promise.resolve(data as T)
    })
  .catch(error =>{
    console.error(`Error fetching ${url}.`, error)
    promise.reject(error)
  });

  return promise;
}




export const PostBackoffice = <T>(path: string, body: object | undefined)  => {
  const url = `${backofficeCommandURL()}/${path}`;
  console.debug('PostFromBackoffice', url, body)
  const promise = new FetchPromise<T>()
  sec.getAccessTokenSilently()
    .then(token => postAsync(url, JSON.stringify(body), token))
    .then(r => {
      if(r.status === 204)
      {
      }
      return r;
    })
    .then(r => r.json())
    .then(json => {
      return json
    }).then(data => {
      promise.resolve(data as T)
    })
  .catch(error =>{
    console.error(`Error fetching ${url}.`, error)
    promise.reject(error)
  });
  return promise;
}


export function* fetchUsers(action: FetchUsers) {
  let hasParameters = (action.countPerPage || action.countPerPage === 0) && (action.page || action.page === 0) && action.sortBy

  let url = !hasParameters ? `${backofficeQueryURL()}/users` : `${backofficeQueryURL()}/users?count=${action.countPerPage}&page=${action.page}&sortby=${action.sortBy}`
  if (hasParameters && action.nameFilter) url += `&namefilter=${action.nameFilter}`

  const accessToken: string = yield sec.getAccessTokenSilently()
  const response: Response = yield call(getAsync, url, accessToken)

  if (response && response.status === 200)
    yield put({ type: 'USERS_FETCHED', data: yield response.json() } as UserActionTypes)
  else if (response && (response.status === 204)) {
    yield put({ type: 'USERS_FETCHED', data: {users: [], total: 0} } as UserActionTypes)
  }
  else
    yield put({ type: 'FETCHING_USERS_FAILED', status: response.status, statusText: response.statusText } as UserActionTypes)
}

export function* fetchMachines(action: FetchMachines) {
  let url = `${backofficeQueryURL()}/machines`
  if(action.prop)
  {
    url = `${url}?viewName=${action.prop}`
  } 
  const accessToken: string = yield sec.getAccessTokenSilently()
  const response: Response = yield call(getAsync, url, accessToken)

  if (response && response.status === 200) {
    const data = yield (response.json())
    const translatedData = data?.length ? data.map(d => {
      return {
        serialId: d.id,
        name: d.machineName,
        ...d
      }
    }) : []

    yield put({ type: 'MACHINES_FETCHED', data: translatedData } as MachineActionTypes)
  }
  else if (response && (response.status === 204)) {
    yield put({ type: 'MACHINES_FETCHED', data: [] } as MachineActionTypes)
  }
  else
    yield put({ type: 'FETCHING_MACHINES_FAILED', status: response.status, statusText: response.statusText } as MachineActionTypes)
}

// export function* fetchMachineTwin(action: FetchMachineTwin) {
//   let url = `${utilsURL()}/iot-device/GetDeviceTwin/${action.serialId}`

//   const accessToken: string = yield sec.getAccessTokenSilently()
//   const response: Response = yield call(getAsync, url, accessToken)

//   if (response && response.status === 200)
//     yield put({ type: 'MACHINE_TWIN_FETCHED', serialId: action.serialId, data: yield response.json() } as MachineActionTypes)
//   else
//     yield put({ type: 'FETCHING_MACHINE_TWIN_FAILED', status: response.status, statusText: response.statusText } as MachineActionTypes)
// }

export function* fetchMachineLogs(action: FetchMachineLogs) {
  const hasParameters = (action.countPerPage || action.countPerPage === 0) && (action.page || action.page === 0)
  
  let url = hasParameters ?
    `${backofficeQueryURL()}/telemetry/${action.serialId}?sortBy=${action.sortBy}&desc=${action.desc}&count=${action.countPerPage}&page=${action.page}`
    : `${backofficeQueryURL()}/telemetry/${action.serialId}`
  if (action.nameFilter) url += (hasParameters ? '&' : '?') + `namefilter=${action.nameFilter}`
  if (action.start) {
    url += `&start=${action.start}`
    if (action.end) url += `&end=${action.end}`
  }

  const accessToken: string = yield sec.getAccessTokenSilently()
  const response: Response = yield call(getAsync, url, accessToken)

  if (response && response.status === 200)
    yield put({ type: 'MACHINE_LOGS_FETCHED', serialId: action.serialId, data: yield response.json() } as MachineActionTypes)
  else if (response && response.status === 204)
    yield put({ type: 'MACHINE_LOGS_FETCHED', serialId: action.serialId, data: { records: [], total: 0 } } as MachineActionTypes)
  else
    yield put({ type: 'FETCHING_MACHINE_LOGS_FAILED', status: response.status, statusText: response.statusText } as MachineActionTypes)
}

export function* fetchMachineOnlineHistory(action: FetchMachineOnlineHistory) {

  let url = `${backofficeQueryURL()}/machines/${action.serialId}/online/history`

  const accessToken: string = yield sec.getAccessTokenSilently()
  const response: Response = yield call(getAsync, url, accessToken)

  if (response && (response.status === 200 || response.status === 204)) {
    const data = yield (response.json())
    yield put({ type: 'MACHINE_ONLINE_HISTORY_FETCHED', data: data } as MachineActionTypes)
  }
  else
    yield put({ type: 'FETCHING_MACHINE_ONLINE_HISTORY_FAILED', status: response.status, statusText: response.statusText } as MachineActionTypes)
}

export function* fetchCustomers(action: FetchCustomers) {
  let url = `${backofficeQueryURL()}/customers?showAll=true` // showAll -> fetches hidden and non-hidden customers

  const accessToken: string = yield sec.getAccessTokenSilently()
  const response: Response = yield call(getAsync, url, accessToken)

  if (response && (response.status === 200)) {
    const data = yield (response.json())
    yield put({ type: 'CUSTOMERS_FETCHED', data: data } as CustomerActionTypes)
  }
  else if (response && (response.status === 204)) {
    yield put({ type: 'CUSTOMERS_FETCHED', data: {levelName: 'Companies', nodes: []} } as CustomerActionTypes)
  }
  else
    yield put({ type: 'FETCHING_CUSTOMERS_FAILED', status: response.status, statusText: response.statusText } as CustomerActionTypes)
}

export function* fetchCustomer(action: FetchCustomer) {
  let url = `${backofficeQueryURL()}/customers/${action.id}?showHidden=true`

  const accessToken: string = yield sec.getAccessTokenSilently()
  const response: Response = yield call(getAsync, url, accessToken)

  if (response && response.status === 200) {
    const data = yield (response.json())

    yield put({ type: 'CUSTOMER_FETCHED', data: data } as CustomerActionTypes)
  }
  else
    yield put({ type: 'FETCHING_CUSTOMER_FAILED', status: response.status, statusText: response.statusText } as CustomerActionTypes)
}

export function* fetchOrphanedMachines(action: FetchOrphanedMachines) {
  let url = `${backofficeQueryURL()}/machines/orphans`

  const accessToken: string = yield sec.getAccessTokenSilently()
  const response: Response = yield call(getAsync, url, accessToken)

  if (response && response.status === 200) {
    const data = yield (response.json())

    const translatedData: MachineState[] = data.map((d) => {
      return {
        serialId: d.id,
        connectionState: d.connectionState,
        lastActivityTime: d.lastActivityTime,
        name: d.machineName,
        cyclesCount: d.cyclesCount,
        runningState: d.runningState
      }
    })

    yield put({ type: 'ORPHANED_MACHINES_FETCHED', data: translatedData } as MachineActionTypes)
  }
  else
    yield put({ type: 'FETCHING_ORPHANED_MACHINES_FAILED', status: response.status, statusText: response.statusText } as MachineActionTypes)
}

export function* fetchCustomerInvoice(action: FetchCustomerInvoice) {
  if (!action.nodeId || (action.from && !action.from.isValid) || (action.to && !action.to.isValid)) {
    yield put({ type: 'FETCHING_CUSTOMER_INVOICE_FAILED', nodeId: action.nodeId, to: action.to, from: action.from } as CustomerActionTypes)
    return
  }

  let url = `${backofficeQueryURL()}/customers/${action.nodeId}/invoice/basis`
  if (action.from) {
    url += '?from=' + action.from.toISODate()
    if (action.to) {
      url += '&to=' + action.to.toISODate()
    }
  }
  else if (action.to) {
    url += '?to=' + action.to.toISODate()
  }

  const accessToken: string = yield sec.getAccessTokenSilently()
  const response: Response = yield call(getAsync, url, accessToken)

  if (response && response.status === 200)
    yield put({ type: 'CUSTOMER_INVOICE_FETCHED', data: yield response.json() } as CustomerActionTypes)
  else if (response && response.ok) {
    yield put({ type: 'CUSTOMER_INVOICE_FETCHED', data: {} } as CustomerActionTypes)
  }
  else
    yield put({ type: 'FETCHING_CUSTOMER_INVOICE_FAILED', nodeId: action.nodeId, to: action.to, from: action.from } as CustomerActionTypes)
}


