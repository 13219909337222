import { LngLat, LngLatBounds } from "mapbox-gl"
import { topDrawerHeight } from "../navbar"
import { getStore } from "../state/configureStore"
import { DeviceType } from "../theme"

export const mapStyle = 'clgl7so0c00ad01qx5ldpgxp8'
export const mapboxToken = 'pk.eyJ1Ijoic2VlcmVmaW5lIiwiYSI6ImNrYXh5OGcxYTBhdmkyeW81NGM3ODhlaXMifQ.JK353QD6jUa7g1J_RnGPVw'

export type Location = {
  latitude: number,
  longitude: number
}

export const getCenterLngLatLike = (locations: Location[]): LngLat => {
  const bb = getBoundingBox(locations)
  const b1 = new LngLat(bb[0].longitude, bb[0].latitude)
  const b2 = new LngLat(bb[1].longitude, bb[1].latitude)
  const llb = new LngLatBounds(b1, b2)
  return llb.getCenter()
}

export const getCenter = (locations: Location[]): Location => {
  if (locations?.length === 1) return locations[0]
  if (!locations?.length) return { longitude: 0, latitude: 0 }

  const longitudes = locations.map(l => l.longitude)
  const latitudes = locations.map(l => l.latitude)

  const averagedLat = latitudes.reduce((partialSum, a) => partialSum + a, 0) / latitudes.length;
  const averagedLng = longitudes.reduce((partialSum, a) => partialSum + a, 0) / longitudes.length;

  return {
    longitude: averagedLng,
    latitude: averagedLat
  }
}

export const getBoundingBox = (locations: Location[], padding: number = 0): Location[] => {
  let allLatitudes = locations.map(l => l.latitude)
  let allLongitudes = locations.map(l => l.longitude)
  const minLongitude = Math.min(...allLongitudes)
  const minLatitude = Math.min(...allLatitudes)
  const maxLongitude = Math.max(...allLongitudes)
  const maxLatitude = Math.max(...allLatitudes)

  return [
    { longitude: minLongitude - padding, latitude: minLatitude - padding },
    { longitude: maxLongitude + padding * 2, latitude: maxLatitude + padding }
  ]
}

export const getSidebarWidth = (device: DeviceType) => {
  return device === "mobile" ? 250 : device === "tablet" ? 300 : 450
}

export const getMapSidebarOrientation = () => {
  const isMobile = getStore().getState().dimensions.device === "mobile"
  const deviceOrientation = getStore().getState().dimensions.orientation
  return ((isMobile && deviceOrientation !== 'landscape') ? 'horizontal' : 'vertical')
}

export const getMapCenterOffset = () => {
  const sidebarOrientation = getMapSidebarOrientation()
  const sidebarSize = getSidebarWidth(getStore().getState().dimensions.device)
  return (
    sidebarOrientation === "vertical" ? [-sidebarSize/4, 0] // the 4s are magic numbers 
    : [0, -(sidebarSize + topDrawerHeight)/4]
  )
}