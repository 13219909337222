import React from "react"

type Props = {
  onClickPrev?: () => void,
  onClickNext?: () => void,
  currentStr?: string,
  disablePrev?: boolean,
  disableNext?: boolean
}
export const StepControl = ({ onClickPrev, onClickNext, currentStr, disablePrev, disableNext }: Props) => {
  return (
    <div>
        {disablePrev ? null : <span style={{display:"inline-block", cursor:"pointer"}} onClick={onClickPrev}>&lt;&lt;</span>}
        <span style={{width:"150px", display:"inline-block", textAlign:"center"}}>{currentStr}</span>
        {disableNext ? null : <span style={{display:"inline-block", cursor:"pointer"}} onClick={onClickNext}>&gt;&gt;</span>}
    </div>
  )
}