import React from "react"
import { Typography } from "@mui/material"
import { AddressInterface } from "./types"


export const AddressView = ({address}:{address: AddressInterface | undefined}) => {
    if(address) {
        return (
            <>
                <Typography variant='body1'>{address.streetAddress}</Typography>
                {address.extraAddress && <Typography variant='body1'>{address.extraAddress}</Typography> }
                <Typography variant='body1'>{address.postalCode + ' ' + address.locality}</Typography>
                <Typography variant='body1'>{address.country}</Typography>
          </>
        )
    }
    else return <Typography variant='body1'>{"No address"}</Typography>
}