import { Box, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { VerticalSpacer } from '../../../components/atoms/spacer'
import { TextTitle } from '../../../components/atoms/text/titles'
import EditAddressView from './editAddress'
import { AddressTypes, ChangeAddressCommand, RemoveAddressCommand } from './editAddress/types'
import { GetCommandResponse, useCommandBackend } from '../../../utils/requestHelpers'
import { AddressInterface } from './types'
import { AddressView } from './addressView'
import { DeleteAddressView } from './deleteAddress'
import { CommandWithSchema } from '../../../common-types/commandingTypes'

export type AddressProps = {
  address?: AddressInterface,
  isRootNode: boolean,
  addressType: AddressTypes,
  nodeId: string,
  invoiceNumber?: string | undefined,
}

const styles = {
  actionButton: {
    fontSize: 12,
    height: '30px'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    minWidth: 250
  },
  actionRow: {
    display: 'flex',
    flexGrow: 1,
    paddingLeft: '5%',
    justifyContent: 'flex-end',
  }
}
export const AddressDisplay = ({ address, isRootNode, addressType, nodeId, invoiceNumber }: AddressProps) => {
  const commandApi = useCommandBackend("Backoffice")
  const [displayAddress, setDisplayAddress] = useState<AddressInterface| undefined>(address)
  const [changeAddress, setChangeAddress] = useState<CommandWithSchema<ChangeAddressCommand> | undefined>()
  const [removeAddress, setRemoveAddress] = useState<RemoveAddressCommand | undefined>()

  useEffect(() => {
    if(nodeId)
      updateChangeAddressCommand(nodeId, addressType)
      updateRemoveAddressCommand(nodeId, addressType)
  }, [nodeId])

  const updateRemoveAddressCommand = (nodeId: string, type: AddressTypes) =>
    commandApi.Get<GetCommandResponse<RemoveAddressCommand>>(`/cmd/node/address/remove/node/${nodeId}/type/${type}`)
      .Data(r => setRemoveAddress(r.command))
      .Empty(() => setRemoveAddress(undefined))
      .Rejected(() => setRemoveAddress(undefined))

  const updateChangeAddressCommand = (nodeId: string, type: AddressTypes) => 
    commandApi.Get<GetCommandResponse<ChangeAddressCommand>>(`/cmd/node/address/change/node/${nodeId}/type/${type}`)
      .Data(a => setChangeAddress(a))
      .Empty(() => setChangeAddress(undefined))
      .Rejected(() => setChangeAddress(undefined))
  
  const handleSavedCommand = (command: ChangeAddressCommand) => {
      setDisplayAddress({streetAddress: command.streetAddress!,extraAddress:command.extraAddress, postalCode: command.postalCode!, locality: command.locality!, country: command.country!})
      updateChangeAddressCommand(nodeId, addressType)
  }

  const handleDeletedCommand = () => {
    setDisplayAddress(undefined)
    updateRemoveAddressCommand(nodeId, addressType)
  }

  const title = `${addressType} Address`

  return (
    <>
      <Box sx={styles.container}>
        <Box>
          <Box style={{ display: 'flex', flexDirection: 'row' }}>
            <TextTitle noPadding title={title} />

            <Box sx={styles.actionRow} >
                <EditAddressView addressType={addressType} command={changeAddress} onSaved={handleSavedCommand} />
                <VerticalSpacer size={1} />
                <DeleteAddressView addressType={addressType} command={removeAddress} onDeleted={handleDeletedCommand}/>
            </Box>
          </Box>
          <Typography variant='body1'>{invoiceNumber}</Typography>
          <AddressView address={displayAddress} />
        </Box>
      </Box>
    </>
  )
}
