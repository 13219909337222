import { DateTime } from "luxon";
import { text_colors } from "../theme";

type EmailValidation = (email: string) => boolean;
export const isValidEmail: EmailValidation = (email) => {
  if (!email) {
    return false
  }
  let regex = /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;

  return regex.test(email);
}

export function lastActivityToReadableTime(connectionState: string, dateTime: DateTime, now: DateTime, short: boolean = false) {
  
  if (connectionState && connectionState !== 'Disconnected') return short ? 'Now' : 'Active now'
  
  if (!dateTime.isValid) return '-'
  if (dateTime.toMillis() <= 0) return short ? 'Never' : 'Never been connected'

  const difference = now.diff(dateTime)
  if (difference.as('minutes') < 1) return 'Now'
  if (difference.as('minutes') < 60) return `${difference.as('minutes').toFixed(0)}${short ? 'min' : ' minutes'} ago`
  if (difference.as('days') < 1) return `${difference.as('hours').toFixed(0)}${short ? 'h' : ' hours'} ago`
  if (difference.as('days') < 7) return `${difference.as('days').toFixed(0)} days ago`

  return `${dateTime.toFormat('dd MMM HH:mm')}`
}

export function capitalizeFirstLetter(string: string) {
  if (!string) return string
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function translateColorCode(code: string): Object {
  switch (code) {
    case '30m':
      return { color: text_colors.grey } // black (actually grey, as seen by the screenshot they provided)
    case '31m':
      return { color: text_colors.red } // red
    case '32m':
      return { color: text_colors.green } // green
    case '33m':
      return { color: text_colors.yellow }// yellow
    case '34m':
      return { color: text_colors.blue } // blue
    case '35m':
      return { color: text_colors.purple } // purple
    case '36m':
      return { color: text_colors.cyan } // cyan
    case '37m':
      return { color: text_colors.white } // white

    case '40m':
      return { backgroundColor: text_colors.grey } // black BG
    case '41m':
      return { backgroundColor: text_colors.red, color: text_colors.white } // red BG
    case '42m':
      return { backgroundColor: text_colors.green, color: text_colors.white } // green BG
    case '43m':
      return { backgroundColor: text_colors.yellow } // yellow BG
    case '44m':
      return { backgroundColor: text_colors.blue, color: text_colors.white } // blue BG
    case '45m':
      return { backgroundColor: text_colors.purple, color: text_colors.white } // purple BG
    case '46m':
      return { backgroundColor: text_colors.cyan } // cyan BG
    case '47m':
      return { backgroundColor: text_colors.white } // white BG


    // if we ever need them: define them in theme.js
    case '90m':
      return { color: 'grey' } // black high intensity
    case '91m':
      return { color: 'red' } // red high intensity
    case '92m':
      return { color: 'green' } // green high intensity
    case '93m':
      return { color: 'yellow' } // yellow high intensity
    case '94m':
      return { color: 'blue' } // blue high intensity
    case '95m':
      return { color: 'purple' } // purple high intensity
    case '96m':
      return { color: 'cyan' } // cyan high intensity
    case '97m':
      return { color: 'white' } // white high intensity

    case '100m':
      return { backgroundColor: 'grey', color: 'white' } // black high intensity BG
    case '101m':
      return { backgroundColor: 'red', color: 'white' } // red high intensity BG
    case '102m':
      return { backgroundColor: 'green', color: 'white' } // green high intensity BG
    case '103m':
      return { backgroundColor: 'yellow' } // yellow high intensity BG
    case '104m':
      return { backgroundColor: 'blue', color: 'white' } // blue high intensity BG
    case '105m':
      return { backgroundColor: 'purple', color: 'white' } // purple high intensity BG
    case '106m':
      return { backgroundColor: 'cyan' } // cyan high intensity BG
    case '107m':
      return { backgroundColor: 'white' }// white high intensity BG

    default: return { color: 'black' }
  }
}


const date_and_time = 'date-and-time'
const date_only = 'date-only'
const time_only = 'time-only'
const month_and_year = 'month-and-year'
const day_and_date = 'day-and-date'
export const dateTimeToString = (
  date: DateTime, 
  format: typeof date_and_time | typeof date_only | typeof time_only | typeof month_and_year | typeof day_and_date = date_and_time
  ) : string => {
  switch(format) {
    case date_and_time:
      return date.toLocaleString(DateTime.DATETIME_SHORT)
    case date_only:
      return date.toLocaleString()
    case time_only:
      return date.toLocaleString(DateTime.TIME_SIMPLE)
    case month_and_year:
      return date.toLocaleString({ month: 'long', year: 'numeric' })
    case day_and_date:
      return date.toLocaleString({ weekday: 'long', day: 'numeric', month: 'long' })
    default:
      return '-'
  }
}