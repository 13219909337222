import React from "react"
import { CheckBox } from "./checkbox"
import { CheckboxItem } from "."


export const CheckboxList = ({items, onChange}:
    {
        items: Array<CheckboxItem>
        onChange?: (key:string, checked:boolean) => void
    }) => {


    return (
        <div style={{display: "flex", flexDirection: "column"}}>
            {items.map((item, idx) => (<CheckBox key={idx} id={item.id} title={item.title} checked={item.checked} onChange={onChange} />) )}
        </div>
    )
}