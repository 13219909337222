import { createSlice } from '@reduxjs/toolkit';

import { fetchRecipesForMachine, postExcludeFromInvoicing, RecipeItem, setRecipeAsIncludedinInvoicing } from '../thunks/machines';

interface RecipeState {
    recipes: RecipeItem[];
    total: number,
    loading: boolean;
    error: string | null;
    savingItems: {transactionId: string | undefined}[]
}

const initialState: RecipeState = {
    recipes: [],
    total: 0,
    loading: false,
    error: null,
    savingItems:[{
        transactionId: undefined
    }]
};


const recipeSlice = createSlice({
    name: 'recipes',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchRecipesForMachine.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchRecipesForMachine.fulfilled, (state, action) => {
                state.loading = false;
                state.recipes = [...action.payload.items]; 
                state.total = action.payload.total
            })
            .addCase(fetchRecipesForMachine.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to fetch recipes';
            }).addCase(postExcludeFromInvoicing.pending, (state, action) =>{
                state.loading = true;
                const { transactionId} = action.meta.arg;
                state.savingItems.push({transactionId:transactionId})
            }).addCase(postExcludeFromInvoicing.fulfilled, (state, action) =>{
                state.loading = false;
                const { id, transactionId, reason } = action.meta.arg;
                const recipe = state.recipes.find(
                  (r) => r.machineName === id && r.transactionId === transactionId
                );
                if (recipe) {
                  recipe.excludedFromInvoicingReason = reason;
                  recipe.excludedFromInvoicing = true
                }
                state.savingItems = state.savingItems.filter(i => i.transactionId !== transactionId);
            } ).addCase(postExcludeFromInvoicing.rejected, (state, action) => {
                const { id, transactionId, reason } = action.meta.arg;
                state.loading = false;
                state.error = action.error.message || 'Failed to exclude recipe';
                state.savingItems = state.savingItems.filter(i => i.transactionId !== transactionId );
            }).addCase(setRecipeAsIncludedinInvoicing.pending, (state, action) =>{
                state.loading = true;
                const { transactionId} = action.meta.arg;
                state.savingItems.push({transactionId:transactionId})
            } )
            .addCase(setRecipeAsIncludedinInvoicing.fulfilled, (state, action) =>{
                state.loading = false;
                const { machineId, transactionId  } = action.meta.arg;
                const recipe = state.recipes.find(
                  (r) => r.machineName === machineId && r.transactionId === transactionId
                );
                if (recipe) {
                  recipe.excludedFromInvoicingReason = undefined;
                  recipe.excludedFromInvoicing = false
                }
                state.savingItems = state.savingItems.filter(i => i.transactionId !== transactionId);
            } ).addCase(setRecipeAsIncludedinInvoicing.rejected, (state, action) => {
                const { transactionId } = action.meta.arg;
                state.loading = false;
                state.error = action.error.message || 'Failed to include recipe';
                state.savingItems = state.savingItems.filter(i => i.transactionId !== transactionId);
            });
    },
});

export const machine_recipes = recipeSlice.reducer;