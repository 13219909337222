import { Box, DialogContentText, TextField } from '@mui/material'
import React, { Fragment, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { AddModal } from '../../../components/molecules/AddModal'
import { AppDispatch, StoreState } from '../../../state/configureStore'
import { AnyAction, Dispatch } from 'redux'
import { padding } from '../../../theme'
import { addCustomerSubnode } from '../../../state/actions/customer'
import { AddCustomerSubnodeType } from '../../../state/actionTypes/customer'
import { addCustomerSubnodeThunk } from '../../../state/thunks/customer'

type IComponentProps = IComponentOwnProps & IComponentStoreProps & IComponentDispatchProps;

type IComponentStoreProps = ReturnType<typeof mapStateToProps>;
type IComponentDispatchProps = ReturnType<typeof mapDispatchToProps>;

interface IComponentOwnProps {
  open: boolean;
  closeModal: Function;
  locationIs: 'visiting' | 'invoice',
  parentId: string
}

function AddCustomerSubnodeModal(props: IComponentProps): React.ReactElement {
  const [nameError, setNameError] = useState(false)
  const [invoice_AddressLine1Error, setInvoiceAddressLine1Error] = useState(false)
  const [invoice_AddressLine2Error, setInvoiceAddressLine2Error] = useState(false)
  const [invoice_PostalCodeError, setInvoicePostalCodeError] = useState(false)
  const [invoice_LocalityError, setInvoiceLocalityError] = useState(false)
  const [visiting_AddressLine1Error, setVisitingAddressLine1Error] = useState(false)
  const [visiting_AddressLine2Error, setVisitingAddressLine2Error] = useState(false)
  const [visiting_PostalCodeError, setVisitingPostalCodeError] = useState(false)
  const [visiting_LocalityError, setVisitingLocalityError] = useState(false)
  // const [locationLongitudeError, setLocationLongitudeError] = useState(false)
  // const [locationLatitudeError, setLocationLatitudeError] = useState(false)

  const [name, setName] = useState('')
  const [invoice_AddressLine1, setInvoiceAddressLine1] = useState<string | null>(null)
  const [invoice_AddressLine2, setInvoiceAddressLine2] = useState<string | null>(null)
  const [invoice_PostalCode, setInvoicePostalCode] = useState<string | null>(null)
  const [invoice_Locality, setInvoiceLocality] = useState<string | null>(null)
  const [visiting_AddressLine1, setVisitingAddressLine1] = useState<string | null>(null)
  const [visiting_AddressLine2, setVisitingAddressLine2] = useState<string | null>(null)
  const [visiting_PostalCode, setVisitingPostalCode] = useState<string | null>(null)
  const [visiting_Locality, setVisitingLocality] = useState<string | null>(null)
  // const [locationLongitude, setLocationLongitude] = useState<number | null>(null)
  // const [locationLatitude, setLocationLatitude] = useState<number | null>(null)
  const dispatch = useDispatch<AppDispatch>()
  const onSave = async () => {
    if (!name) setNameError(true)
    else setNameError(false)

    if (!visiting_AddressLine1) setVisitingAddressLine1Error(true) // maybe do more thorough check, should probably always contain a street name and number
    else setVisitingAddressLine1Error(false)

    setVisitingAddressLine2Error(false) // is optional, but if there is something in there, might want to check if it is a valid address input

    if (!visiting_PostalCode) setVisitingPostalCodeError(true)
    else setVisitingPostalCodeError(false)

    if (!visiting_Locality) setVisitingLocalityError(true)
    else setVisitingLocalityError(false)


    // these are options, so no error if missing. (might want to do other validation here though?)
    setInvoiceAddressLine1Error(false)
    setInvoiceAddressLine2Error(false)
    setInvoicePostalCodeError(false)
    // setInvoiceLocalityError(false)


    // const locationLatWrong = locationLatitude === null || isNaN(locationLatitude) || locationLatitude > 90 || locationLatitude < -90
    // const locationLongWrong = locationLongitude === null || isNaN(locationLongitude) || locationLongitude > 180 || locationLongitude < -180

    // if (locationLatWrong) setLocationLatitudeError(true)
    // else setLocationLatitudeError(false)

    // if (locationLongWrong) setLocationLongitudeError(true)
    // else setLocationLongitudeError(false)

    const hasAllRequiredInputs = name && visiting_AddressLine1 && visiting_PostalCode && visiting_Locality && props.parentId
    // const hasNoErrors = !locationLatWrong && !locationLongWrong
    if (hasAllRequiredInputs) { //} && hasNoErrors) {
      const toSave: AddCustomerSubnodeType = {
        parentId: props.parentId,
        name: name,

        invoice_AddressLine1: invoice_AddressLine1,
        invoice_AddressLine2: invoice_AddressLine2,
        invoice_PostalCode: invoice_PostalCode,
        invoice_Locality: invoice_Locality,

        visiting_AddressLine1: visiting_AddressLine1,
        visiting_AddressLine2: visiting_AddressLine2,
        visiting_PostalCode: visiting_PostalCode,
        visiting_Locality: visiting_Locality,

        // location_Longitude: locationLongitude,
        // location_Latitude: locationLatitude,
      }
      const respons  = await dispatch(addCustomerSubnodeThunk(toSave))
      if(respons.payload.ok)
        props.closeModal()
    }
  }

  const resetModal = () => {
    setNameError(false)
    setInvoiceAddressLine1Error(false)
    setInvoiceAddressLine2Error(false)
    setInvoicePostalCodeError(false)
    setInvoiceLocalityError(false)
    setVisitingAddressLine1Error(false)
    setVisitingAddressLine2Error(false)
    setVisitingPostalCodeError(false)
    setVisitingLocalityError(false)
    // setLocationLongitudeError(false)
    // setLocationLatitudeError(false)

    setName('')
    setInvoiceAddressLine1('')
    setInvoiceAddressLine2('')
    setInvoicePostalCode('')
    setInvoiceLocality('')
    setVisitingAddressLine1('')
    setVisitingAddressLine2('')
    setVisitingPostalCode('')
    setVisitingLocality('')
    // setLocationLatitude(null)
    // setLocationLongitude(null)
  }

  return (
    <AddModal
      title={'Add Customer'}
      savingFailedMessage={'The customer could not be saved. Please check your inputs below and your internet connection and try again.'}
      saving={props.customer_saving}
      open={props.open}
      closeModal={() => { resetModal(); props.closeModal() }}
      onSave={onSave}>
      <Subheader noPadding title={'General Information'} />
      <TextField
        margin="normal"
        id="name_input_field"
        label="Name"
        type="text"
        size='small'
        fullWidth
        onChange={(event) => setName(event.target.value)}
        error={nameError}
        helperText={nameError ? "Please enter a customer name." : null}
      />

      <AddressSection
        header={'Visiting Address'}
        addressLine1Error={visiting_AddressLine1Error}
        addressLine2Error={visiting_AddressLine2Error}
        postalCodeError={visiting_PostalCodeError}
        localityError={visiting_LocalityError}

        setAddressLine1={setVisitingAddressLine1}
        setAddressLine2={setVisitingAddressLine2}
        setPostalCode={setVisitingPostalCode}
        setLocality={setVisitingLocality}
      />

      {/* {props.locationIs === 'visiting' ? <LocationSection
        setLocationLatitude={setLocationLatitude}
        setLocationLongitude={setLocationLongitude}
        locationLatitudeError={locationLatitudeError}
        locationLongitudeError={locationLongitudeError} /> : null
      } */}

      <AddressSection
        header={'Invoice Address (optional)'}
        addressLine1Error={invoice_AddressLine1Error}
        addressLine2Error={invoice_AddressLine2Error}
        postalCodeError={invoice_PostalCodeError}
        localityError={invoice_LocalityError}

        setAddressLine1={setInvoiceAddressLine1}
        setAddressLine2={setInvoiceAddressLine2}
        setPostalCode={setInvoicePostalCode}
        setLocality={setInvoiceLocality}
      />
      {/* {props.locationIs === 'invoice' ? <LocationSection
        setLocationLatitude={setLocationLatitude}
        setLocationLongitude={setLocationLongitude}
        locationLatitudeError={locationLatitudeError}
        locationLongitudeError={locationLongitudeError} /> : null
      } */}
    </AddModal>
  )
}

const Subheader = ({ title, noPadding = false, error = false }) => {
  return (
    <Box style={{
      paddingTop: noPadding ? 'auto' : padding * 3
    }}>
      <DialogContentText>{title}</DialogContentText>
    </Box>
  )
}

type AddressSectionProps = {
  header: string,

  addressLine1Error: boolean,
  addressLine2Error: boolean,
  postalCodeError: boolean,
  localityError: boolean,

  setAddressLine1: Function,
  setAddressLine2: Function,
  setPostalCode: Function,
  setLocality: Function
}
const AddressSection = ({
  header,
  addressLine1Error,
  addressLine2Error,
  postalCodeError,
  localityError,

  setAddressLine1,
  setAddressLine2,
  setPostalCode,
  setLocality
}: AddressSectionProps) => {
  return (
    <Fragment>
      <Subheader title={header} />
      <TextField
        margin="normal"
        id={header + "invoice_address1_input_field"}
        label="Address line"
        type="text"
        size='small'
        fullWidth
        onChange={(event) => setAddressLine1(event.target.value)}
        error={addressLine1Error}
        helperText={addressLine1Error ? "Please enter a valid invoice address." : null}
      />
      <TextField
        margin="normal"
        id={header + "invoice_address2_input_field"}
        label="Address line 2 (optional)"
        type="text"
        size='small'
        fullWidth
        onChange={(event) => setAddressLine2(event.target.value)}
        error={addressLine2Error}
        helperText={addressLine2Error ? "Please enter a valid invoice address." : null}
      />
      <TextField
        margin="normal"
        id={header + "invoice_postal_code_input_field"}
        label="Postal Code"
        type="text"
        size='small'
        fullWidth
        onChange={(event) => setPostalCode(event.target.value)}
        error={postalCodeError}
        helperText={postalCodeError ? "Please enter a postal code." : null}
      />
      <TextField
        margin="normal"
        id={header + "invoice_locality_input_field"}
        label="City"
        type="text"
        size='small'
        fullWidth
        onChange={(event) => setLocality(event.target.value)}
        error={localityError}
        helperText={localityError ? "Please enter a city." : null}
      />
    </Fragment>
  )
}

// type LocationSectionProps = {
//   setLocationLatitude: Function,
//   setLocationLongitude: Function,
//   locationLatitudeError: boolean,
//   locationLongitudeError: boolean
// }
// const LocationSection = ({
//   setLocationLatitude,
//   setLocationLongitude,
//   locationLatitudeError,
//   locationLongitudeError
// }: LocationSectionProps) => {
//   return (
//     <Box style={{
//       display: 'flex',
//       flexDirection: 'row',
//     }}>
//       <TextField
//         style={{ flex: 5 }}
//         margin="normal"
//         id="location_latitude_field"
//         label="Latitude"
//         type="number"
//         inputProps={{
//           step: "0.1"
//         }}
//         size='small'
//         fullWidth
//         onChange={(event) => setLocationLatitude(parseFloat(event.target.value))}
//         error={locationLatitudeError}
//         helperText={locationLatitudeError ? "Please enter a valid latitude." : null}
//       />
//       <Box style={{ flex: 1 }} />
//       <TextField
//         style={{ flex: 5 }}
//         margin="normal"
//         id="location_latitude_field"
//         label="Longitude"
//         type="number"
//         size='small'
//         fullWidth
//         onChange={(event) => setLocationLongitude(parseFloat(event.target.value))}
//         error={locationLongitudeError}
//         helperText={locationLongitudeError ? "Please enter a valid longitude." : null}
//       />
//     </Box>
//   )
// }

const mapStateToProps = (state: StoreState) => ({
  customer_saving: state.customer_saving
})

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  addCustomerSubnode: (customerSubnodeData: AddCustomerSubnodeType) => dispatch(addCustomerSubnode(customerSubnodeData))
})

export default connect(mapStateToProps, mapDispatchToProps)(AddCustomerSubnodeModal)