import { createAsyncThunk } from "@reduxjs/toolkit";
import { FetchFromBackoffice } from "../../utils/request";
import { PostToBackoffice } from "../../utils/commands";

export const fetchNodeColors = createAsyncThunk(
  "FETCH_NODE_COLOR",
  async () => {
    const response = await FetchFromBackoffice<{ node_id: string; node_color: string; name: string }[]>(`customers/colors`);
    return response;

  }
);


export const setNodeColors = createAsyncThunk(
  "SET_NODE_COLOR",
  async ({id, color}:{id:string, color: string}) => {
    const response = await PostToBackoffice(`cmd/node/color/change`, {
      id: id,
      color: color
    });
    return response;

  }
);

