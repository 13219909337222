import React, { useCallback, useState } from "react";
import {
  DateCalendar,
  LocalizationProvider,
  PickersDay,
} from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { Box, Button, TextField } from "@mui/material";
import { DateTime, Settings } from "luxon";
import { PickersDayProps } from "@mui/lab";

export const CustomDateRangePicker = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  isMobile
}) => {
  const [selectingEndDate, setSelectingEndDate] = useState(false);

  const handleDateChange = (newDate) => {
    if (!selectingEndDate) {
      setStartDate(newDate);
      setEndDate(null); // Reset end date when a new start date is selected
      setSelectingEndDate(true);
    } else {
      if (newDate >= startDate) {
        setEndDate(newDate);
        setSelectingEndDate(false);
      } else {
        // If the selected end date is before the start date, reset both
        setStartDate(newDate);
        setEndDate(null);
      }
    }
  };

  const renderDay = (
    props: PickersDayProps<any> & {
      selectedDay?: DateTime | null;
    }
  ) => {
    const { day, selectedDay, ...other } = props;
    const isInRange =
      startDate && endDate && props.day >= startDate && props.day <= endDate;

    const isSelectedStart = startDate && props.day.hasSame(startDate, "day");
    const isSelectedEnd = endDate && props.day.hasSame(endDate, "day");

    return (
      <PickersDay
        {...other}
        day={props.day}
        isSelected={isSelectedStart}
        sx={[ isMobile && {fontSize: 10, height: 30, width: 30},{
          bgcolor:
            isSelectedStart || isSelectedEnd
              ? "primary.main"
              : isInRange
              ? "primary.light"
              : "inherit",
          color: isSelectedStart || isSelectedEnd ? "white" : "inherit",
        }]}
      />
    );
  };

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale="gb-en">
      <DateCalendar
        sx={[isMobile && {width: 300}]}
        disableFuture
        showDaysOutsideCurrentMonth
        displayWeekNumber
        value={selectingEndDate ? endDate : startDate}
        onChange={handleDateChange}
        slots={{ day: renderDay }}
        slotProps={{
          day: (ownerState) =>
            ({
              selectedDay: selectingEndDate ? endDate : startDate,
            } as any),
        }}
      />
    </LocalizationProvider>
  );
};
