import { Delete, Edit, RestoreFromTrash, VisibilityOff } from '@mui/icons-material'
import { Table, TableBody, TableCell, TableFooter, TableHead, TablePagination, TableRow, TableSortLabel } from '@mui/material'
import { AnimatePresence, motion } from 'framer-motion'
import React, { Fragment } from 'react'
import theme, { DeviceType } from '../../theme'
import { VerticalSpacer } from '../atoms/spacer'
import CustomButton from './button'
import { StoreState } from '../../state/configureStore'
import { connect } from 'react-redux'
import { DimensionsReducer } from '../../state/reducers/app'
import './table.css'
import Text from '../atoms/text/text'
import { columnNames } from '../../pages/machines/machines'

type TableProps = TablePropsWithoutPaging | TablePropsWithPaging

export type TablePropsWithoutPaging = ({
  columnNames: {
    displayName: string,
    serverName: string | null,
    hide: DeviceType[] | undefined
  }[],
  rows: Object[],
  onClickRow?: Function,
  onClickDelete?: Function,
  onClickEdit?: Function,
  onClickRestore?: Function,

  noPaging: true // needs to be set explicitly to prevent accidentally forgetting to give table paging info
  noHover?: boolean,
  animateTable?: boolean // can cause graphical issues when refreshing the table, if the structure isn't exactly right. Need to find out why that is. Eventually want  all the tables to be animated.

  sortingOn: string | null,
  descending: boolean,
  setSortingOn: ((newSortingOn: any) => any) | null,
  setDescending: (newDescending: boolean) => any,
  selectedRows?: string[],
  dimensions: DimensionsReducer
})

export type TablePropsWithPaging = ({
  columnNames: {
    displayName: string,
    serverName: string | null,
    hide: DeviceType[] | undefined
  }[],
  rows: Object[],
  onClickRow?: Function,
  onClickDelete?: Function,
  onClickEdit?: Function,
  onClickRestore?: Function,
  total: number,

  noHover?: boolean,
  animateTable?: boolean // can cause graphical issues when refreshing the table, if the structure isn't exactly right. Need to find out why that is. Eventually want  all the tables to be animated.

  countPerPage: number,
  page: number,
  sortingOn: string | null,
  descending: boolean,
  setCountPerPage?: (newCount: number) => any,
  setPage?: (newPage: number) => any,
  setSortingOn?: ((newSortingOn: any) => any) | null,
  setDescending?: (newDescending: boolean) => any,

  selectedRows?: string[],

  dimensions: DimensionsReducer
})

const hiddenRowColor = theme.palette.grey[600]
const hiddenFirstCellStyle = {
  color: hiddenRowColor,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  height: 65 // slightly magic number, seems to be the height of cell. without, the cell becomes slightly smaller than the others
}

const TableComponent = (props: TableProps) => {
  const columnNamesConst = [...props.columnNames.map(cn => cn.displayName)] as const

  type SortOn = typeof columnNamesConst[number];

  type RowDataObject = ({
    'id': 'string',
    [key: SortOn]: string
  })

  const rowsData = props.rows as RowDataObject[]

  const onClickColumnName = (sortOn: SortOn) => {
    if (!props.sortingOn || props.sortingOn === sortOn) props.setDescending(!props.descending)
    else if (props.setSortingOn) props.setSortingOn(sortOn)
  }

  const currentDevice = props.dimensions.device

  const iconButtonStyle = currentDevice === 'mobile' ? {
    width: 20,
    padding: '5px',
  } : {}

  return (
    <Table>
      <TableHead>
        <TableRow>
          {props.columnNames.map((columnName, index) => {
            if (columnName?.hide?.length && columnName?.hide?.includes(currentDevice)) return

            return (
              <TableCell sx={[{fontFamily: 'DIN',  }, columnName.displayName === "" && {width: 25, padding: 0 }]} key={'cell' + index}>
                <TableSortLabel
                  hideSortIcon={columnName.serverName ? false : true}
                  active={props.sortingOn ? (props.sortingOn === columnName.serverName) : false}
                  direction={props.descending ? 'desc' : 'asc'}
                  onClick={columnName.serverName && props.setSortingOn ?
                    () => onClickColumnName(columnName.serverName!)
                    : undefined
                  }
                  sx={!columnName.serverName || !props.setSortingOn ?
                    {
                      ":hover": { cursor: 'default' }
                    } : null
                  }
                >
                  {columnName.displayName}
                </TableSortLabel>
              </TableCell>)
          })
          }
          {
            props.onClickEdit ? <TableCell sx={{ fontFamily: 'DIN', ...iconButtonStyle }} key={'edit_button'}></TableCell> : null
          }
          {
            props.onClickDelete ? <TableCell sx={{ fontFamily: 'DIN', ...iconButtonStyle }} key={'delete_button'}></TableCell> : null
          }
        </TableRow>
      </TableHead>

      <TableBody>
        {
          rowsData.map((row, index) => {
            const tableRowContent = <React.Fragment>{
              Object.keys(row).filter((key: string) => { return key !== 'id' && key !== 'hidden' })
                .map((key: string, index) => {
                  const col = props.columnNames?.[index]

                  if (col?.hide?.length && col?.hide?.includes(currentDevice)) return null

                  return index === 0 && row.hidden ? <TableCell onClick={props.onClickRow ? () => props.onClickRow!(row['id']) : undefined} key={key + '_' + index} sx={hiddenFirstCellStyle}>
                    <VisibilityOff sx={{ color: hiddenRowColor }} fontSize='small' />
                    <VerticalSpacer />
                    {row[key]}
                  </TableCell> : (
                    <ValueCell nopadding={index === 0} hidden={row.hidden} onClickRow={props.onClickRow} id={row['id']} key={key + '_' + index}>{row[key]}</ValueCell>
                  )
                })
            }

              {props.onClickEdit ? <TableCell sx={{fontFamily: 'DIN', ...iconButtonStyle}} key={'cell_actions_edit'}>
                <CustomButton iconOnly noPadding onClick={props.onClickEdit ? () => (props.onClickEdit)!(row['id']) : undefined} noBg endIcon={<Edit fontSize='small' />} />
              </TableCell> : null}

              {props.onClickDelete && !row.hidden ? <TableCell sx={{fontFamily: 'DIN', ...iconButtonStyle}} key={'cell_actions_delete'}>
                <CustomButton iconOnly noPadding onClick={props.onClickDelete ? () => props.onClickDelete!(row['id']) : undefined} noBg endIcon={<Delete fontSize='small' />} />
              </TableCell> : null}

              {props.onClickRestore && row.hidden ? <TableCell sx={{fontFamily: 'DIN', ...iconButtonStyle}} key={'cell_actions_restore'}>
                <CustomButton iconOnly noPadding onClick={props.onClickRestore ? () => props.onClickRestore!(row['id']) : undefined} noBg endIcon={<RestoreFromTrash fontSize='small' />} />
              </TableCell> : null}
            </React.Fragment>

            return (props.animateTable ?
              <AnimatePresence key={'row_' + index}>
                <TableRow
                  component={motion.div}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  selected={(props.selectedRows && props.selectedRows.includes(row.id))}
                  key={index + '_' + row['id']}
                  hover={!props.noHover}

                  sx={{
                    cursor: props.noHover && !props.onClickRow ? 'default' : 'pointer'
                  }}
                >
                  {tableRowContent}
                </TableRow>
              </AnimatePresence>
              : <TableRow
                selected={(props.selectedRows && props.selectedRows.includes(row.id))}
                key={index + '_' + row['id']}
                hover={!props.noHover}

                sx={{
                  cursor: props.noHover && !props.onClickRow ? 'default' : 'pointer'
                }}
              >
                {tableRowContent}
              </TableRow>)
          })
        }
      </TableBody>

      {
        (props as TablePropsWithPaging).setPage ?  // checks: did type conversion work?
          <TableFooter>
            <TableRow>
              <TablePagination
                sx={{fontFamily: 'DIN'}}
                count={(props as TablePropsWithPaging).total}
                page={(props as TablePropsWithPaging).page}
                rowsPerPage={(props as TablePropsWithPaging).countPerPage}
                onPageChange={(_, page) => (props as TablePropsWithPaging).setPage(page)}
                onRowsPerPageChange={e => (props as TablePropsWithPaging).setCountPerPage(Number.parseInt(e.target.value))}
              />
            </TableRow>
          </TableFooter> : null
      }
    </Table >
  )
}

const ValueCell = ({ children, onClickRow, id, key, hidden, nopadding}) => {
  let content = children
  if (typeof children !== "string" && children?.length > 1) {
    content = <div style={{ display: 'flex', flexDirection: 'column' }}>
      {
        children?.map((child, index) => {
          return <Text style={{ fontSize: 14 }} key={index}>{child}</Text>
        })
      }
    </div>
  }
  return <TableCell sx={hidden ? { color: hiddenRowColor } : nopadding ? {paddingRight: 0} : undefined } onClick={onClickRow ? () => onClickRow!(id) : undefined} key={key}>{<div className="text-ellipsis--2">{content}</div>}</TableCell>
}

const mapStateToProps = (state: StoreState) => ({
  dimensions: state.dimensions,
})

export default connect(mapStateToProps)(TableComponent)