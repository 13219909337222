import { Box, Button, Divider } from "@mui/material"
import React from "react"
import { padding } from "../../../theme"
import Text from "../../../components/atoms/text/text"
import { getStore } from "../../../state/configureStore"

interface SubscriptionItem {
    email:string,
    eventType:string,
    alertId:string,
}

const AlertItemInt = ({email, eventType, alertId, remove, minWidth, buttonWidth}:SubscriptionItem & {remove:() => void, minWidth: string, buttonWidth: string} ):JSX.Element => {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: padding/2, alignItems: 'center', flexGrow: 1, flexWrap: 'wrap' }}>
        <Text style={{ flex: 1, minWidth: minWidth }}>{email}</Text>
        <Text style={{ flex: 1, minWidth: minWidth }}>{eventType}</Text>
        <Button variant="outlined" sx={{ height: 'fit-content', width: buttonWidth }} onClick={remove}>Remove</Button>
      </Box>
    )
}


const SubscriptionList = ({
    Subscriptions,
    onRemove,
    minWidth,
    buttonWidth
} : {Subscriptions: SubscriptionItem[] | undefined, onRemove: (alertId:string) => void, minWidth: string, buttonWidth: string}) : JSX.Element => {
    const isDesktop = getStore().getState().dimensions.device === "desktop" 
    return <React.Fragment>
        {Subscriptions?.map((item, idx) => { return (
          <Box key={idx}>
            <AlertItemInt key={idx} minWidth={minWidth} buttonWidth={buttonWidth} email={item.email} eventType={item.eventType} alertId={item.alertId} remove={() => onRemove(item.alertId)} />
            {!isDesktop && (idx < (Subscriptions?.length - 1)) ? <Divider flexItem variant="fullWidth" sx={{ width: '100%', paddingTop: '2.5%' }} orientation="horizontal" /> : null}
          </Box>
        )})}
      </React.Fragment>
}


export { SubscriptionList }