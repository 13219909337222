import { createTheme } from '@mui/material/styles';
import chroma from 'chroma-js';
import { put, fork, take, select } from 'redux-saga/effects';
import { eventChannel } from "redux-saga"

export const text_colors = {
  black: '#000000',
  red: '#e55e5e',
  green: '#6d9c77',
  yellow: '#deb100',
  blue: '#0037da',
  purple: '#881798',
  cyan: '#3a96dd',
  white: '#cccccc',
  grey: '#e6e6e6',
  greyDark: '#BBB',
  greyBlue: '#d3e8df',
}

export function* dimensionsHandler() {
  yield fork(responsiveViewHandler, window.innerWidth, window.innerHeight)
  yield fork(responsiveViewListener)
}

export const padding = 4

// no s in here as thats the default at 0-752
export const breakpointSizes = {
  m: 752,
  l: 1025,
  xl: 1600,

  mapboxMobile: 640
};

export type DeviceType =  "desktop" | "tablet" | "mobile"

let device_types = [
  {
      name: 'desktop',
      minWidth: breakpointSizes.l
  },
  {
      name: 'tablet',
      minWidth: breakpointSizes.m
  },
  {
      name: 'mobile',
      minWidth: 0
  }
]

export function* responsiveViewHandler(viewWidth, viewHeight) {
  const dimensions = yield select(state => state.dimensions)

  const newOrientation = viewWidth > viewHeight ? 'landscape' : 'portrait'

  for(var i = 0; i < device_types.length; i++) {
      let device_type = device_types[i]

      if(device_type.minWidth <= viewWidth) {
          if(!(dimensions && dimensions.device && dimensions.device === device_type.name) || dimensions.orientation !== newOrientation) {
              yield put({
                  type: 'VIEW_DIMENSIONS_UPDATED',
                  device: device_type.name,
                  orientation: viewWidth > viewHeight ? 'landscape' : 'portrait'
              })
          }
          return
      }
  }
}

export function* responsiveViewListener() {
  const channel = eventChannel(listener => {
      const handleWindowResize = (windowSize) => {
          listener(windowSize);
      }

      window.addEventListener("resize", handleWindowResize)
      return () => window.removeEventListener("resize", handleWindowResize);
  });

  let updatedWindow
  while (true) {
      const windowInfo = yield take(channel);

      updatedWindow = windowInfo && windowInfo.srcElement ?
      {
          width: windowInfo.srcElement.innerWidth,
          height: windowInfo.srcElement.innerHeight,
      } : null

      if(updatedWindow) {
          yield fork(responsiveViewHandler, updatedWindow.width, updatedWindow.height)
      }

  }
}

// there is no fromS as that'd be the default styles and we do not want
// those to be part of a min-width: 0px media queries due to CSS performance
export const breakpoints = {
  get onlyS() { return this.untilM; }, // > 0 && < 752

  untilMapboxMobile: "@media (max-width: " + (breakpointSizes.mapboxMobile - 1) + "px)", // < 752
  fromMapboxMobile: "@media (min-width: " + breakpointSizes.mapboxMobile + "px)", // < 752

  untilM: "@media (max-width: " + (breakpointSizes.m - 1) + "px)", // < 752
  fromM: "@media (min-width: " + breakpointSizes.m + "px)", // >= 752
  get onlyM() { return this.fromM + " and " + this.untilL.substring(7); }, // >= 752 && < 1050

  untilL: "@media (max-width: " + (breakpointSizes.l - 1) + "px)", // < 1050
  fromL: "@media (min-width: " + breakpointSizes.l + "px)", // >= 1050
  get onlyL() { return this.fromL + " and " + this.untilXL.substring(7); }, // >= 1050 && < 1600
  
  untilXL: "@media (max-width: " + (breakpointSizes.xl - 1) + "px)", // < 1600
  fromXL: "@media (min-width: " + breakpointSizes.xl + "px)", // >= 1600
    
  get onlyXL() { return this.fromXL; }, // >= 1600

  portrait: "@media (orientation: portrait)",
  get isPortrait() { return this.portrait; },

  landscape: "@media (orientation: landscape)",
  get isLandscape() { return this.landscape; },

  get renderNavbarOnTop() { return this.untilM },
  get renderNavbarOnSide() { return this.fromM },

  untilWidth: (size) => { return "@media (max-width: " + (size - 1) + "px)" },
  fromWidth: (size) => { return "@media (min-width: " + size + "px)" }
};

export const styles = {
  showIfMobile: {
    [breakpoints.untilL]: {
      display: "auto"
    },
    [breakpoints.fromL]: {
      display: "none"
    }
  },
  showIfNotMobile: {
    [breakpoints.untilL]: {
      display: "none"
    },
    [breakpoints.fromL]: {
      display: "auto"
    }
  },
  ifMobile: (style) => {
    return {[breakpoints.untilL]: style}
  },
  ifNotMobile: (style) => {
    return {[breakpoints.fromL]: style}
  },
  ifMapboxMobile: (style) => {
    return {[breakpoints.untilMapboxMobile]: style}
  },
  ifNotMapboxMobile: (style) => {
    return {[breakpoints.fromMapboxMobile]: style}
  }
}

const colourTheme = createTheme({
  palette: {
    primary: {
      main: '#141314',
      light: '#d3e8df',
      contrastText: '#eff2f4'
    },
    secondary: {
      main: '#223337',
      contrastText: '#fff',
    },
    error: {
      main: '#e55e5e',
      light: '#ef5350',
      dark: '#c62828'
    },
    success: {
      main: '#6d9c77',
      light: '#6d9c77',
      dark: '#224937'
    },
    background: {
      default: "#eff2f4",
    },
    text: {
      primary: '#141314',
      secondary: '#989898'
    },
  }
})

const theme = createTheme({
  typography: {
    button: {
      textTransform: "none",
    },
    h3: {
      fontWeight: 400,
      fontFamily: 'VogieBold', 
      textTransform: 'uppercase',
      color: colourTheme.palette.primary.main,
      '@media print': {
        color: text_colors.black
      }
    },
    h4: {
      fontWeight: 400,
      fontFamily: 'VogieBold', 
      color: colourTheme.palette.primary.main,
      '@media print': {
        color: text_colors.black
      }
    },
    h5: {
      color: colourTheme.palette.primary.main,
      '@media print': {
        color: text_colors.black
      }
    },
    h6: {
      color: colourTheme.palette.primary.main,
      '@media print': {
        color: text_colors.black
      }
    }
  },
  components: {
    MuiTypography: {
      defaultProps: {
        fontFamily: 'DIN',
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: 'rgb(113 122 131 / 11%) 0px 7px 30px 0px',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          backgroundColor: 'transparent'
        },
      },
    },
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          "&.MuiTableSortLabel-root": {
            color: colourTheme.palette.primary.main,
            fontWeight: 'bold',
            fontSize: 15,
            '@media print': {
              color: text_colors.black,
            }
          },
          "&.MuiTableSortLabel-icon": {
            color: colourTheme.palette.primary.main,
            '@media print': {
              color: text_colors.black,
            }
          },
          "&.Mui-active .MuiTableSortLabel-icon": {
            color: colourTheme.palette.primary.main,
            '@media print': {
              color: text_colors.black
            }
          },
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          '@media print': {
            border: 'none',
            paddingLeft: 0,
            paddingRight: 0
          }
        },
        head: {
          paddingBottom: '1%',
        },
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 'auto',
          paddingRight: '5%',
        }
      }
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          "&.MuiDialogContentText-root": {
            fontWeight: 450,
            color: colourTheme.palette.primary.main
          }
        }
      }
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          "&.MuiDialogTitle-root": {
            color: colourTheme.palette.primary.main
          }
        }
      }
    }
  }
  // // Used by `getContrastText()` to maximize the contrast between
  // // the background and the text.
  // contrastThreshold: 3,
  // // Used by the functions below to shift a color's luminance by approximately
  // // two indexes within its tonal palette.
  // // E.g., shift from Red 500 to Red 300 or Red 700.
  // tonalOffset: 0.2,
}, colourTheme)

export const runningStateColors = {
  running: chroma('#104438').saturate(7).brighten(1.25).hex(),
  idle: chroma('#104438').hex(),
}
export const connectionStateColors = {
  not_connected: theme.palette.error.main, //theme.palette.grey[500],
  connected: theme.palette.success.main
}

export default theme