import React, { useCallback, useEffect, useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { BarChartView, IDataItem } from './atoms/charts';



const colorMapping = {
  'Cotton': '#01204E',
  'Polyester': '#028391',
  'Suede': '#F6DCAC',
  'Leather': '#FEAE6F',
  // Add more recipes and their corresponding colors here
};


const differentRecipes = [
    {name:'Cotton'},
    {name:'Polyester'},
    {name:'Suede'},
    {name:'Leather'}
]


type DataItem = {
  day: string,
  [key: string]: number | string,
}
const transformData = (data) => {
    const result = {};
    data?.forEach(item => {
      const { day, recipe, cycle_count } = item;
      if (!result[day]) {
        result[day] = { day };
      }
      if (recipe) result[day][recipe] = cycle_count;
    });

    let withName = Object.values(result).map((r: DataItem) => {return {
      ...r,
      name: r.day
    }});

    const toReturn: Array<IDataItem> = (withName as Array<IDataItem>).map(d => {delete d.day; return d;})
    return toReturn
  };


const CyclesBarChart = ({recipes}) => {

  const formatXAxis = (tickItem: string) => {
    return new Date(tickItem).getDate(); // Extract and return only the day from the date
  };
  const data = transformData(recipes) as Array<IDataItem>

  return <div style={{ width: '100%', height: '400px' }}>
      <BarChartView items={data} formatX={formatXAxis} />
    </div>
};

export default CyclesBarChart;
