import { GetCommandResponse, useCommandBackend, useQueryBackend } from "../../../utils/requestHelpers"
import { CommandWithSchema } from "../../../common-types/commandingTypes"
import { AddRecommendedPrice } from "./types"
import { useEffect, useState } from "react"
import React from "react"
import CustomButton from "../../../components/molecules/button"
import { AddPriceForm } from "./AddPriceForm"
import ConsumerPricesListTable from "./consumerPriceListTable"
import { SubpageTitle } from "../../../components/atoms/text/titles"
import { PricesResponse } from "../types"

export const ConsumerPriceListView = () => {
    const commandApi = useCommandBackend("Backoffice")
    const queryApi = useQueryBackend("Backoffice")
    const [command, setCommand] = useState<CommandWithSchema<AddRecommendedPrice> | undefined>()
    const [show, setShow] = useState<boolean>()
    const [prices, setPrices] = useState<Array<PricesResponse>>([])

    useEffect(() => {
        refreshCommand()
    }, [])

    const onSaved = (p) => {setPrices(c => [{...p, valid_from: p.validFrom},...c])}

    useEffect(() => {
        queryApi.Get<Array<PricesResponse>>('/prices/recommended')
            .Data(m => setPrices(m))
    }, [])

    const refreshCommand = () => 
        commandApi.Get<GetCommandResponse<AddRecommendedPrice>>('/cmd/price/recommended/add/')
            .Data(setCommand)
            .Empty(() => setCommand(undefined))
            .Rejected(() => setCommand(undefined))

    const handleSave = (command: AddRecommendedPrice) => {
        commandApi.PostCommand('/cmd/price/recommended/add', command)
            .Data((c) => {
                refreshCommand()
                setShow(false)
                onSaved({...c.command, scope:"General"})
            })
    }

    return (
        <>
            <SubpageTitle title={'Consumer List Price'}  />
            {/* <CustomButton noBg onClick={() => {setShow(command !== undefined)}} disabled={command === undefined}>{'+ Add Price'}</CustomButton> */}
            
            {show && <AddPriceForm command={command!} onClose={() => setShow(false)} onSave={handleSave} />}
            
            <ConsumerPricesListTable prices={prices} />
        </>
    )
}