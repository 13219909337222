import React from "react"
import { UserListItem } from "./types"
import TableView, { ColumnDefinition } from "../../../components/atoms/table"

const columns: Array<ColumnDefinition<UserListItem>> = [
    {
        Property: "givenName",
        Title: "Given name"
    },{
        Property: "familyName",
        Title: "Surname"
    },{
        Property: "email",
        Title: "Email",
    },{
        Property:"roles",
        Title: "Roles"
    }
]

const columns_mobile: Array<ColumnDefinition<UserListItem>> = [
  {
      Property: (item) => item.givenName + ' ' + item.familyName,
      Title: "Name"
  },{
      Property: "email",
      Title: "Email",
  },{
      Property:"roles",
      Title: "Roles"
  }
]

export const UsersList = ({users, onSelect}:{
    users: Array<UserListItem>
    onSelect?: (user: UserListItem) => void
}) => {

    return (
        <TableView
            items={users}
            columns={columns}
            columns_mobile={columns_mobile}
            onRowClick={onSelect}
            />
    )
}
