import React from "react"
import Page from "../../components/molecules/page"
import { OrganizationUsersView } from "./organization_users/organizationUsersView"
import { Info } from '@mui/icons-material'
import AdminUsers from "./admin_users/adminUsers"


export const UsersPage = () => {


    return (
        <Page tabs={[{
            name:'Customer',
            pageSections: [<OrganizationUsersView />],
            icon: <Info />
        }, {
            name: 'Admins',
            pageSections: [<AdminUsers /> ],
            icon: <Info />
        }

        ]} title={'Users'} sideScroll />
    )
}

