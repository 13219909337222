import React, { useEffect, useRef } from 'react'
import mapboxgl from "mapbox-gl";
import { getBoundingBox, getCenter, getCenterLngLatLike, getMapCenterOffset, Location, mapboxToken, mapStyle } from '../../../../utils/map';
import { Box } from '@mui/system';
import { connectionStateColors } from '../../../../theme';
import ReactDOM from 'react-dom'
import MachineMapPopup from './machineMapPopup';
import { connect } from 'react-redux'
import { AnyAction, Dispatch } from 'redux'
import { getStore, StoreState } from '../../../../state/configureStore';
import { closeAllMapPopups, closedMapPopup, openedMapPopup } from '../../../../state/actions/map';
import { MachineState } from '../../../../state/reducers/machine';
import './map_popup.css'
import { StepControl } from '../../../atoms/navigations/StepControl';

type DashboardMapProps = {
  machines: MachineState[],

  sidebarOpen: boolean,
  sidebarWidth: number,
  sidebarOrientation: "horizontal" | "vertical"

}

const machineSourceName = 'machines'

function centerOnLocations(map, locations, sidebarOpen, zoom) {
  if (map && map.current && locations?.filter(l => l !== undefined)?.length > 0) {
    const boundingBox = getBoundingBox(locations as Location[], 2) // TODO: more adaptive padding
    
    const sidebarOffset = getMapCenterOffset()
    
    map.current.fitBounds([
      [boundingBox[0].longitude, boundingBox[0].latitude],
      [boundingBox[1].longitude, boundingBox[1].latitude]
    ],
    {
      offset: sidebarOpen ? sidebarOffset : [0, 0]
    })
  }
}

function createMachineLayer(machines : MachineState[]) {
  let i
  return {
    'type': 'FeatureCollection',
    'features': machines.map((m, index) => {
      return {
        'id': toFeatureId(m.serialId),
        'type': 'Feature',
        'geometry': {
          'type': 'Point',
          'coordinates': [
            m.location?.longitude, m.location?.latitude
          ],
        },
        'properties': {
          'iconSize': [40, 40],
          'serialId': m.serialId,
          'name': m.name,
          'currentState': m.connectionState,
          'cycles': m.cyclesCount,
          'cyclesCountToday': m.cyclesCountToday
        }
      }
    })
  };
}

function hashCode(str) {
  let hash = 0;
  for (let i = 0, len = str.length; i < len; i++) {
      let chr = str.charCodeAt(i);
      hash = (hash << 5) - hash + chr;
      hash |= 0; // Convert to 32bit integer
  }
  return hash
}
// id must be an integer for feature state to work
function toFeatureId(id: string): number {
  return hashCode(id)
}

function setUpClusteredPopups(map, openedMapPopupAction, closedMapPopupAction){
  map.on('click', 'clusters', (e) => {
    const features = map.queryRenderedFeatures(e.point, { layers: ['clusters'] });
    const clusterId = features[0].properties.cluster_id;

    const offset = getMapCenterOffset()

    map.getSource(machineSourceName).getClusterExpansionZoom(clusterId, (err, zoom) => {
        if (err) return;

        map.easeTo({
            center: features[0].geometry.coordinates,
            zoom: zoom,
            offset: offset
        });
        if(zoom > 15){
          const co = [features[0].geometry.coordinates[0] ,features[0].geometry.coordinates[1] + 0.01] // adjustment to get all popups in screen
          map.easeTo({
            center: co,
            zoom: 14,
            offset: offset
        });
        // Get all points in the cluster
        map.getSource(machineSourceName).getClusterLeaves(clusterId, 100, 0, (err: Error, leaves: any) => {
            if (err) {
              console.warn(err)
              return;}

            const isDesktop = getStore().getState().dimensions.device === 'desktop'

            const ids = leaves.map((leaf) => { return leaf?.properties?.serialId })
            
            const popup = new mapboxgl.Popup({
              className: isDesktop ? 'mapboxgl-popup' : 'mapboxgl-popup-mobile',
              closeButton: false,
              closeOnClick: true,
            });
            const popupDOM = document.createElement('div')
            popupDOM.setAttribute('id', 'popup_' + clusterId)

            popup.on('close', () => {
              closedMapPopupAction(clusterId)
            })
            
            const center = getCenterLngLatLike(leaves.map(leaf => {return { longitude: leaf.geometry.coordinates[0], latitude: leaf.geometry.coordinates[1] }}))
            popup.setLngLat(center).setDOMContent(popupDOM).addTo(map);
            openedMapPopupAction(clusterId, popup, ids)
            
        });
      }
    });
});
}


const colors = [connectionStateColors.connected, connectionStateColors.not_connected];
function setupPopups(map, openedMapPopupAction, closedMapPopupAction) {
  const openPopup = (e) => {
    // Change the cursor style as a UI indicator.
    map.getCanvas().style.cursor = 'pointer';

    const serialId = e.features[0].properties.serialId
    const openPopups = getStore().getState().map_state.openPopups // need to get them this way to get updated state later on

    if (Object.keys(openPopups).includes(serialId)) return

    // another one already open, make sure we close that - need to use actions for that like we do on mC portal
    if (Object.keys(openPopups).length) {
      Object.keys(openPopups).forEach((id) => {
        closedMapPopupAction(id)
        openPopups[id].remove()
      })
    }

    // Copy coordinates array.
    const coordinates = e.features[0].geometry.coordinates.slice();

    const popup = new mapboxgl.Popup({
      className: !isDesktop ? 'mapboxgl-popup-mobile' : 'mapboxgl-popup',
      closeButton: false,
      closeOnClick: true
    });

    // Ensure that if the map is zoomed out such that multiple
    // copies of the feature are visible, the popup appears
    // over the copy being pointed to.
    while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
      coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
    }

    const popupDOM = document.createElement('div')
    popupDOM.setAttribute('id', 'popup_' + serialId)

    popup.setLngLat(coordinates).setDOMContent(popupDOM).addTo(map);
    popup.on('close', () => {
      closedMapPopupAction(serialId)
    })

    openedMapPopupAction(serialId, popup)
  }

  const isDesktop = getStore().getState().dimensions.device === 'desktop'
  map.on('click', 'machine-layer', openPopup);
  map.on('mouseenter', 'machine-layer', openPopup);

  map.on('mouseleave', 'machine-layer', () => {
    map.getCanvas().style.cursor = '';
  });

}

    // code for creating an SVG donut chart from feature properties
function createDonutChart(props) {
      const offsets:number[] = [];
      const counts:number[] = [
          props.connected,
          props.disconnected
      ];
      let total:number = 0;
      for (const count of counts) {
          offsets.push(total);
          total += count;
      }
      const fontSize =
          total >= 1000 ? 22 : total >= 100 ? 20 : total >= 10 ? 18 : 16;
      const r =
          total >= 1000 ? 50 : total >= 100 ? 32 : total >= 10 ? 24 : 18;
      const r0 = Math.round(r * 0.6);
      const w = r * 2;

      let html = `<div>
          <svg width="${w}" height="${w}" viewbox="0 0 ${w} ${w}" text-anchor="middle" style="font: ${fontSize}px sans-serif; display: block">`;

      for (let i = 0; i < counts.length; i++) {
          html += donutSegment(
              offsets[i] / total,
              (offsets[i] + counts[i]) / total,
              r,
              r0,
              colors[i]
          );
      }
      html += `<circle cx="${r}" cy="${r}" r="${r0}" fill="white" />
          <text dominant-baseline="central" transform="translate(${r}, ${r})">
              ${total.toLocaleString()}
          </text>
          </svg>
          </div>`;

      const el = document.createElement('div');
      el.innerHTML = html;
      return el.firstChild;
  }
function donutSegment(start, end, r, r0, color) {
    if (end - start === 1) end -= 0.00001;
    const a0 = 2 * Math.PI * (start - 0.25);
    const a1 = 2 * Math.PI * (end - 0.25);
    const x0 = Math.cos(a0),
        y0 = Math.sin(a0);
    const x1 = Math.cos(a1),
        y1 = Math.sin(a1);
    const largeArc = end - start > 0.5 ? 1 : 0;

    // draw an SVG path
    return `<path d="M ${r + r0 * x0} ${r + r0 * y0} L ${r + r * x0} ${
        r + r * y0
    } A ${r} ${r} 0 ${largeArc} 1 ${r + r * x1} ${r + r * y1} L ${
        r + r0 * x1
    } ${r + r0 * y1} A ${r0} ${r0} 0 ${largeArc} 0 ${r + r0 * x0} ${
        r + r0 * y0
    }" fill="${color}" />`;
  }

let markersOnScreen = {};
const markers = {};

function updateMarkers(map) {
    const newMarkers = {};
    const features = map.querySourceFeatures(machineSourceName);
    // for every cluster on the screen, create an HTML marker for it (if we didn't yet),
    // and add it to the map if it's not there already
    for (const feature of features) {
        const coords = feature.geometry.coordinates;
        const props = feature.properties;
        if (!props.cluster) continue;
        const id = props.cluster_id;

        let marker = markers[id];
        if (!marker) {
            const el = createDonutChart(props);
            marker = markers[id] = new mapboxgl.Marker({
                element: el
            }).setLngLat(coords);
        }
        newMarkers[id] = marker;

        if (!markersOnScreen[id]) marker.addTo(map);
    }
    // for every marker we've added previously, remove those that are no longer visible
    for (const id in markersOnScreen) {
        if (!newMarkers[id]) markersOnScreen[id].remove();
    }
    markersOnScreen = newMarkers;
  }

const DashboardMap = (state: DashboardMapProps & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>) => {
  const locations = state.machines?.map(m => m.location)
  const center: Location = locations?.length ? getCenter(locations) : { latitude: 57.72101, longitude: 12.9401 }

  const mapContainer = useRef<HTMLDivElement>(null);
  const map = useRef<any>(null);
  const zoom =  locations?.length ? 9 : 7;

  // close all popups and remove their references on component unmounting
  // also remove map instance
  useEffect(() => {
    return () => { state.closeAllMapPopups(); map.current?.remove(); }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {  
    if (Object.keys(state.openPopups).length) {
      // do nothing - might want to center on popups though
    }
    else {
      centerOnLocations(map, locations, state.sidebarOpen, zoom)
    }
  }, [state.dimensions.orientation])

  useEffect(() => {
    if (map?.current && map.current?.getSource(machineSourceName)) {
      state.machines?.forEach((m, index) => {
        map.current.setFeatureState({
          source: machineSourceName,
          id: toFeatureId(m.serialId)
        }, {
          isRunning: m.runningState === 'running' ? true : false,
          isConnected: m.connectionState === 'Connected',
          'currentState': m.connectionState,
          'cycles': m.cyclesCount,
          'cyclesCountToday': m.cyclesCountToday        });
      })

      const updatedData = createMachineLayer(state.machines)

      map.current?.getSource(machineSourceName).setData(updatedData)
    }
  }, [state.machines])

  useEffect(() => {
    if (map.current) return; // initialize map only once

    // init map
    mapboxgl.accessToken = mapboxToken;
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/seerefine/' + mapStyle,
      center: [center.longitude, center.latitude],
      zoom: zoom
    });

    // set bounding box, so all locations are in view
    centerOnLocations(map, locations, state.sidebarOpen, zoom)

    const geojson = createMachineLayer(state.machines)

    map.current.on('load', () => {
      
      map.current.addSource(machineSourceName, {
        'type': 'geojson',
        'data': geojson,
        "cluster": true,
        "clusterMaxZoom": 18, // Max zoom to cluster points on
        "clusterRadius": 35, 
          "clusterProperties": {
            // Sum of cyclesToday in each cluster
            "cyclesTodaySum": ['+', ['get', 'cyclesCountToday']],
             "connected": ['+', ['case', ['==',['get', 'currentState'], 'Connected'], 1, 0]],
             "disconnected": ['+', ['case', ['==',['get', 'currentState'], 'Disconnected'], 1, 0]],
        }
      });

      map.current.on('render', () => {
        updateMarkers(map.current);           
        });
      map.current.addLayer(
        {
          'id': 'machine-layer',
          'type': 'circle',
          'source': machineSourceName,
          "filter": ['!', ['has', 'point_count']],
          'paint': {
          // Make circles larger as the user zooms from z12 to z22.
            'circle-radius': {
              'property': 'cyclesCountToday',
              'stops': [
                [10, 14],
              ]
            },
            'circle-color': [
              'match',
              ['get', 'currentState'],
              'Connected',
              connectionStateColors.connected,
              'Disconnected',
              connectionStateColors.not_connected,
              connectionStateColors.not_connected
            ],
          }
        },
      );

      map.current.addLayer(
        {
          'id': 'machine-cycles-layer',
          'type': 'symbol',
          'source': machineSourceName,
          "filter": ['!', ['has', 'point_count']],
          'layout': {
              'text-field': ['get', 'cyclesCountToday'],
              'text-size': 12,
          },
          paint: {   
              'text-color': '#fff'
              // 'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
              // 'text-radial-offset': -0.5,
              // 'text-justify': 'auto',
              // 'icon-image': ['concat', ['get', 'icon'], '-15']
          }
        })

      map.current.addLayer({
          "id": 'clusters',
          "type": 'circle',
          "source": machineSourceName,
          "filter": ['has', 'point_count'],
          paint: {
              // Use step expressions (https://docs.mapbox.com/style-spec/reference/expressions/#step)
              // with three steps to implement three types of circles:
              //   * Blue, 20px circles when point count is less than 100
              //   * Yellow, 30px circles when point count is between 100 and 750
              //   * Pink, 40px circles when point count is greater than or equal to 750
              'circle-color': 
                  'transparent'
              ,
              'circle-radius': 20 
        }
      });

    //   map.current.addLayer({
    //       "id": 'cluster-count',
    //       "type": 'symbol',
    //       "source": machineSourceName,
    //       "filter": ['has', 'point_count'],
    //       "layout": {
    //           'text-field': ['get', 'cyclesTodaySum'],
    //           'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
    //           'text-size': 12
    //       }
    //  });

      map.current.on('mouseenter', 'clusters', () => {
          map.current.getCanvas().style.cursor = 'pointer';
      });
  
      // Change it back to a pointer when it leaves
      map.current.on('mouseleave', 'clusters', () => {
          map.current.getCanvas().style.cursor = '';
      });

      
  });

    setUpClusteredPopups(map.current, state.openedMapPopup, state.closedMapPopup)
    
    setupPopups(map.current, state.openedMapPopup, state.closedMapPopup)

    // for initial setting of feature state
    map.current.on('machine-layer', (e) => {
      if (machineSourceName && map?.current && map?.current?.getSource(machineSourceName)) {
        state.machines?.forEach((m, index) => {
          map.current.setFeatureState({
            source: machineSourceName,
            id: toFeatureId(m.serialId)
          }, {
            isRunning: m.runningState === 'running' ? true : false,
            isConnected: m.connectionState === 'Connected'
          });
        })
      }
    })


  })

  

  return (
    <Box style={{
      height: '100%',
      width: '100%',
      }}>
        {/* <CustomButton endIcon={<RestartAltIcon />} style={{ position: 'absolute', zIndex: 100 }} onClick={resetView}>{'Reset view'}</CustomButton> */}
        <Box style={{ 
          height: '100%',
          width: '100%',
        }} ref={mapContainer} className="map-container" />
      {Object.keys(state.openPopups)?.length ? Object.keys(state.openPopups).map(popupId => { 
        const openPopupContainsMultiple = state.openPopups[popupId].dataIds?.length
        const domEl = document.getElementById('popup_' + popupId)
        if (domEl && openPopupContainsMultiple) {
          return (
            ReactDOM.createPortal(<PopupSlide mapPopup={state.openPopups[popupId]} dataIds={state.openPopups[popupId].dataIds} machines={state.machines} />,
            domEl!
          ))
        }
        else if (domEl) return ReactDOM.createPortal(
          <MachineMapPopup mapPopup={state.openPopups[popupId]} machine={state.machines.find(m => m.id === popupId)!} />,
          domEl!
        )})
        : null
      }
    </Box>
  );
}

const PopupSlide = ({ dataIds, machines, mapPopup }: {dataIds: string[], machines: MachineState[], mapPopup: any}) => {
  const [openSlide, setOpenSlide] = React.useState(0)

  const [machine, setMachine] = React.useState<MachineState>(machines[0])

  useEffect(( ) => {
    setMachine(machines.find(m => m.id === dataIds[openSlide])!)
  }, [openSlide])
  
  return <Box style={{
    height: '100%',
    width: '100%' }}>
      <StepControl 
        onClickNext={() => { setOpenSlide(openSlide + 1) }}
        onClickPrev={() => { setOpenSlide(openSlide - 1) }}
        disableNext={!dataIds?.length || openSlide >= dataIds?.length -1}
        disablePrev={!dataIds?.length || openSlide <= 0}
        currentStr={'Machine ' + (openSlide + 1) + ' of ' + dataIds.length}
      />
    <MachineMapPopup mapPopup={mapPopup} machine={machine} />
  </Box>
}

const mapStateToProps = (state: StoreState) => ({
  openPopups: state.map_state.openPopups,
  dimensions: state.dimensions
})

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  closeAllMapPopups: () => dispatch(closeAllMapPopups()),
  closedMapPopup: (id: string) => dispatch(closedMapPopup(id)),
  openedMapPopup: (id: string, popup: any, dataIds?: string[]) => dispatch(openedMapPopup(id, popup, dataIds))
})

export default connect(mapStateToProps, mapDispatchToProps)(DashboardMap)