import React, { ComponentType, Fragment } from "react"

import { Box, Grid, Item, Typography } from "@mui/material"
import { padding } from '../../../theme'


const style = {
    infoSection: {
      paddingBottom: padding * 2,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      gap: 5,
    },
    paddingTop: {
      paddingTop: padding * 2
    },
    infoRow: {
      display: 'flex',

      flexDirection: 'row' as 'row',
      gap: 10,
      width: '100%',
      justifyContent: 'space-between'
    }
  }

type ValueViewProps = {
  value : string | undefined,
  raw : any,
}
  
type Properties = {
    Values: Array<{Label:string, Path?:string, CustomComponent?: ComponentType<ValueViewProps>, ValueComponent?: React.JSX.Element }>,
    Data: any,
}

const DefaultView = ({ value }:ValueViewProps) => {
  return (
    <span>{value}</span>
  )
}


export const PropertyRow = ({Label, Value, Raw, CustomComponent, ValueComponent}:
  {Label:string, Value: any | undefined, Raw?: any, CustomComponent?: ComponentType<ValueViewProps>, ValueComponent?: React.JSX.Element}) =>{
    return (
    <Box style={style.infoRow}>
        <Typography style={{ flex: 1 }} variant='body1'>{Label}</Typography>
        <div style={{ flex: 1}} >
          {ValueComponent ?? <Typography variant='body1'>{CustomComponent ? <CustomComponent value={Value} raw={Raw} /> : <DefaultView value={Value} raw={undefined} />}</Typography>}
        </div>
    </Box>
    )
}

const valueFromPath = (data:any, path?:string) => {
  if (!path) return undefined
    return path.split('.').reduce((o, k) => o && o[k], data)
}

const PropertyView: React.FC<Properties> = ({Values, Data}) => {
    return (
        <Box style={style.infoSection}>
            {Values.map((i, idx) => <PropertyRow key={idx} Label={i.Label} Value={valueFromPath(Data, i.Path)} Raw={Data} ValueComponent={i.ValueComponent} CustomComponent={ i.CustomComponent } />)}
        </Box>
    )
}

export { PropertyView }