import { Box } from '@mui/material'
import { DateTime } from 'luxon'
import React, { useRef, useState } from 'react'
import { connect } from 'react-redux'
import { AnyAction, Dispatch } from 'redux'
import DashboardMap from '../../components/molecules/maps/interactive/dashboardMap'
import { MapSidebar } from '../../components/molecules/maps/interactive/mapSidebar'
import { StoreState } from "../../state/configureStore"
import { lastActivityToReadableTime } from '../../utils/text'
import { Location, getMapSidebarOrientation, getSidebarWidth } from '../../utils/map'
import MapSideBarDisplay from './mapSideBarDisplay'
import { MachineState } from '../../state/reducers/machine'

// export type MachineMapInfo = {
//   cyclesCountToday: number,
//   name: string,
//   location: Location,
//   cyclesCount: number,
//   runningState: 'running' | 'idle',
//   storeId: string,
//   lastActivityTimeStr: string,
//   id: string,
//   connected: boolean
// }

function OverviewMap(state: ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>
): React.ReactElement {

  const sidebarWidth = getSidebarWidth(state.dimensions.device)

  const machineForMap: MachineState[] = state.machines.filter(d => d.location).map(d => {
    return {
      lastActivityTimeStr: lastActivityToReadableTime(d.connectionState, DateTime.fromISO(d.lastActivityTime), DateTime.now()),
      storeId: d.nodeId, 
      id: d.serialId,
      ...d
    }
  }) ?? []


  const [sidebarOpen, setSidebarOpen] = useState<boolean>(true)
  const containerRef = useRef()


  const renderHorizontally = getMapSidebarOrientation() === "horizontal"
  return (
    <Box ref={containerRef} style={{ width: '100%', height: '100%', boxShadow: '-2px 0 15px -6px rgba(155,155,155,0.50)'}}>
      <MapSidebar horizontal={renderHorizontally} openFromBelow={renderHorizontally} containerRef={containerRef} width={sidebarWidth} open={sidebarOpen} onClose={() => setSidebarOpen(false)} onOpen={() => setSidebarOpen(true)}>
        <MapSideBarDisplay horizontal={renderHorizontally} machines={machineForMap}/>
      </MapSidebar>
      <DashboardMap sidebarOpen={sidebarOpen} sidebarWidth={sidebarWidth} sidebarOrientation={renderHorizontally ? "horizontal" : "vertical"} machines={machineForMap} />

    </Box>
  )
}

const mapStateToProps = (state: StoreState) => ({
  machines: state.machines,
  signalRLogs: state.signal_r_logs,
  dimensions: state.dimensions
})

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(OverviewMap)