import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { SubpageTitle } from "../../../components/atoms/text/titles";
import { useQueryBackend } from "../../../utils/requestHelpers";
import { Nullable } from "../../../common-types";
import { BarChartView, IDataItem } from "../../../components/atoms/charts";
import { getDistinctPropertyValues } from "../../../functions";
import { DateTime } from "luxon";
import { DateRangePickerPopUp, TagButton } from "../../../components/molecules/datepickers/dateRangePickerPopUp";
import { useSelector } from "react-redux";
import { RootState } from "../../../state/configureStore";

interface CyclesItem {
  day: string;
  store: Nullable<string>;
  cycles: number;
}

export const SalesPerStore = ({
  nodeId,
  name,
}: {
  nodeId?: string;
  name?: string | undefined;
}) => {
  const queryApi = useQueryBackend("Backoffice");
  const [from, setFrom] = useState<DateTime>(DateTime.now().startOf("month"));
  const [to, setTo] = useState<DateTime>(DateTime.now().endOf("month"));
  const [data, setData] = useState<Array<IDataItem>>([]);
  const today = DateTime.now()
  const step = "month"
  const stepper = {month: 1}
  const todayRollingSeven = today.minus(stepper);
  const lastMonthStart = today.minus(stepper).startOf("month");
  const lastMOnthEnd = today.minus(stepper).endOf("month");
  const colors = useSelector((state: RootState) => state.node_colors.nodeColors )
  const colorMap = colors.reduce((acc, curr) => {
    acc[curr.name] = curr.node_color;
    return acc;
  },{})
  useEffect(() => {

    if(!from || !to) return
    if (nodeId ) {
      queryApi
        .Get<Array<CyclesItem>>(
          `nodes/${nodeId}/stores/daily/between/${from.toFormat(
            "yyyy-MM-dd"
          )}/and/${to.toFormat("yyyy-MM-dd")}`
        )
        .Data((d) => {
          const allDays = getDistinctPropertyValues(d, "day");
          const allStores = getDistinctPropertyValues(d, "store");

          const items = allDays.map((day) => {
            const item: IDataItem = { name: day };
            allStores
              .filter((s) => !!s)
              .forEach((k) => {
                item[k!] =
                  d.find((d) => d.day === day && d.store === k)?.cycles ?? 0;
              });
            return item;
          });
          setData(items);
        });
    } else {
      queryApi
        .Get<Array<CyclesItem>>(
          `customer/nodes/recipes/all-nodes/stores/daily/between/${from.toFormat(
            "yyyy-MM-dd"
          )}/and/${to.plus({day: 1}).toFormat("yyyy-MM-dd")}`
        )
        .Data((d) => {
          const allDays = getDistinctPropertyValues(d, "day");
          const allStores = getDistinctPropertyValues(d, "store");

          const items = allDays.map((day) => {
            const item: IDataItem = { name: day };
            allStores
              .filter((s) => !!s)
              .forEach((k) => {
                item[k!] =
                  d.find((d) => d.day === day && d.store === k)?.cycles ?? 0;
              });
            return item;
          });
          setData(items);
        });
    }
  }, [nodeId, from, to]);
  const [open, setOpen] = useState<boolean>(false);
  const presets:TagButton [] = [
    {
      name: "This Month",
      id: "this",
      action: () => {
        setFrom(today.startOf(step));
        setTo(today.endOf("month"));
        setOpen(false);
      },
      default: true
    },
    {
      name:  "Last Month",
      id: "last",
      action: () => {
        setFrom(lastMonthStart);
        setTo(lastMOnthEnd);
        setOpen(false);
      },
    },
    {
      name:  "Rolling month" ,
      id: "rolling",
      action: () => {
        setFrom(todayRollingSeven);
        setTo(today);
        setOpen(false);
      },
    },
  ];
  return (
    <Box>
      <SubpageTitle title={name ?? "Sales per store"} />
      <Box>
        <DateRangePickerPopUp
          startDate={from}
          endDate={to}
          onStartDatePicked={setFrom}
          onEndDatePicked={setTo}
          presets={presets}
          open={open}
          setOpen={setOpen}
        ></DateRangePickerPopUp>
      </Box>
      <div style={{ width: "100%", height: "400px" }}>
        <BarChartView
          items={data}
          colorMap={colorMap}
          formatX={(s) => s.substring(s.lastIndexOf("-") + 1)}
        />
      </div>
    </Box>
  );
};
