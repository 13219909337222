import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import AsyncStorage from '@react-native-async-storage/async-storage'
import {
  authenticated_user,
  users,
  user_fetching,
  user_saving
} from './user'
import {
  machines,
  machine_fetching,
  orphaned_machines,
  orphaned_machines_fetching,
  machine_saving,
  machine_logs,
  machine_logs_fetching,
  machine_online_history,
  machine_online_history_fetching,
  // machine_twins,
  // machine_twins_fetching,
  signal_r_logs
} from './machine'
import {
  customers,
  customers_fetching,
  customer_fetching,
  customer_saving,
  customer_saving_error,
  customer_invoice,
  customer_invoice_fetching,
  customers_price_models 
} from './customer'
import {
  signal_r_connection,
  settings,
  dimensions
} from './app'
import {
  map_state
} from './map'
import { machine_errors } from './machine-errors'
import { machine_recipes } from './reciepes'
import { node_colors } from './nodeColors.ts'

const rootPersistConfig = {
  key: 'root',
  storage: AsyncStorage,
  debug: true,
  whitelist: [
    'authenticated_user',
    'node_colors'
  ]
}

const reducers = combineReducers({
  authenticated_user,
  users,
  user_fetching,
  user_saving,
  machines,
  machine_fetching,
  machine_saving,
  orphaned_machines,
  orphaned_machines_fetching,
  customers,
  customers_fetching,
  customer_fetching,
  customer_saving,
  customer_saving_error,
  customers_price_models,
  machine_logs,
  machine_logs_fetching,
  machine_online_history,
  machine_online_history_fetching,
  // machine_twins,
  // machine_twins_fetching,
  signal_r_connection,
  settings,
  dimensions,
  signal_r_logs,
  map_state,
  customer_invoice,
  customer_invoice_fetching,
  machine_errors,
  machine_recipes,
  node_colors
})

export default persistReducer(rootPersistConfig, reducers)