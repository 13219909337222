import React, {  useEffect, useState } from "react";
import { Box } from "@mui/material";
import { FetchFromBackoffice } from "../../utils/request";
import {
  CollapsibleTable,
  ColumnName,
} from "../../components/molecules/CollapsableTable";
import { SubpageTitle } from "../../components/atoms/text/titles";
import { DateTime } from "luxon";
import { Tooltip } from "@material-ui/core";

const columnNames: ColumnName[] = [
  {
    displayName: "Meassage",
    id: "message",
    aggregate: false,
    grouped: true,
    level: 0,
    align: "left",
    altText: (row, agg, level) => {
      if(row.groupBy.length > 50){
          return <Tooltip title={row.groupBy}><Box>{`${row.groupBy.substring(0, 40)}... `}</Box></Tooltip>
      }
      else {
        return  row.groupBy
      }
    },
    visible: true,
  },
  {
    displayName: "Machine",
    id: "device_id",
    aggregate: true,
    grouped: true,
    level: 1,
    align: "left",
    visible: true,
  },

  {
    displayName: "Total",
    id: "timestamp",
    aggregate: false,
    grouped: false,
    level: 2,
    align: "left",
    visible: true,
    formatter: (value: string) =>
      DateTime.fromISO(value).toFormat("hh:mm:ss"),
    altText: (row, agg, level) => {
      let total = 0
      if(level === 0){
      row.items.forEach(e => {
        total = total + e.items?.length
      });
      return total.toString()
    }else if(level === 1){
      return row.items.length.toString()
    }
    },
  },
  {
    displayName:  "Error/Warning",
    id: "level",
    aggregate: false,
    grouped: false,
    level: 0,
    align: "left",
    visible: true,
    altText: (row) => {

      return  row.items[0].items[0].level;
    },
  },
];

export const MachineLogTable = ({
  to,
  from,
  warnings
}: {
  to: DateTime;
  from: DateTime;
  warnings: boolean
}) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    if (to && from) {
      FetchFromBackoffice<[]>(
        `telemetry/error-warning/${from?.toFormat(
          "yyyy-MM-dd"
        )}/and/${to?.toFormat("yyyy-MM-dd")}?filterType=${warnings ? "warning" : "no_warning"}`
      )
        .then((r) => {
          setData(r);
        })
        .catch((e) => console.log("Error: ", e));
    }
  }, [to, from, warnings]);

  return (
    <Box>
      <SubpageTitle title={"Details: " + from.toFormat("LLL dd")} />
      <CollapsibleTable
        columnNames={columnNames}
        rows={data.length > 0 ? data : []}
      ></CollapsibleTable>
    </Box>
  );
};
