import { TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import React from "react";
import { Nullable } from "../../../common-types";
import { InputStyle } from "./style";
import { DateTime } from "luxon";


export const MonthSelectorInput = ({label, value, error, required, minDate = '2024-01-01', onChange}:{
    value: Nullable<string>,
    error?: boolean
    label:string,
    required?: boolean
    minDate?: Nullable<string>
    onChange: (val:Nullable<string>) => void
}) => {

    const handleChange = (e) => {
        const date = e as DateTime
        if(date) {
            const dateString = date.toFormat('yyyy-MM-dd')
            onChange(dateString)
            return
        }
        onChange(null)
    }
    const min = DateTime.fromFormat("YYYY-mm-dd", minDate) 
    const max = DateTime.fromFormat("YYYY-mm-dd", "2034-12-01") 
    const v = DateTime.fromFormat("YYYY-mm-dd" , value ?? "2024-12-01")
    return (
        <LocalizationProvider dateAdapter={AdapterLuxon}>
            <DatePicker
                    
                    label={label}
                    value={v}
                    views={["year", "month"]}
                    onChange={handleChange}
                    minDate={min}
                    maxDate={max}
                    
                    renderInput={(params) => <TextField 
                        type="date" 
                        fullWidth 
                        {...params}
                        {...InputStyle}
                        required={required}
                        error = {error}
                        helperText={error ? "Select a date on the next month after the last valid price model. Or the month after current date, what ever is the latest" : ""}
                        />}
                />      
        </LocalizationProvider>

    )
}