import React, { Dispatch, useCallback, useEffect, useState } from "react";
import { AddModal } from "../../../components/molecules/AddModal";
import { SavingReducer } from "../../../state/reducers/general";
import { TextField } from "@mui/material";
import {
  postExcludeFromInvoicing,
  RecipeItem,
} from "../../../state/thunks/machines";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../state/configureStore";

export const ExcludeFromInvoice = ({
  item,
  open,
  setOpen,
}: {
  item: RecipeItem | undefined;
  open: boolean;
  setOpen: Dispatch<boolean>;
}) => {
  const [text, setText] = useState<string>("");
  const dispatch = useDispatch<AppDispatch>();
  const [saving, setSaving] = useState<string>("")
  const [failMessage, setFailMessage] = useState<string>("")
  const loading = useSelector(
    (state: RootState) => state.machine_recipes.loading
  );
  const onSave = useCallback(
    (reason: string) => {
      if(reason === ""){
        setSaving("rejected")
        setFailMessage("Comment is needed")
        return
       }
      if (item && reason.length > 0) {
        setSaving("saving")
        dispatch(
          postExcludeFromInvoicing({
            id: item?.machineName,
            transactionId: item?.transactionId,
            reason: reason,
          })
        );
        setText("");
        setSaving("")
        setFailMessage("")
        setOpen(false);
      }
    },
    [item]
  );

  return (
    <AddModal
      title="Exclude from invoicing"
      open={open}
      saving={saving}
      closeModal={() => setOpen(false)}
      savingFailedMessage={failMessage}
      onSave={() => onSave(text)}
    >
      <TextField
        autoFocus
        margin="normal"
        id="name_input_field"
        label="Reason"
        defaultValue={item?.excludedFromInvoicingReason}
        type="text"
        fullWidth
        onChange={(e) => setText(e.target.value)}
        // error={nameError}
        // helperText={nameError ? "Please enter a name." : null}
      />
    </AddModal>
  );
};
