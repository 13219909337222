import { CustomerNode } from "../../state/reducers/customer"
import React, { Fragment, useEffect, useState } from 'react'
import TableComponent from "../../components/molecules/table"
import { sortListForTable, TableList } from "../../utils/lists"
import DeleteCustomerDialog from "./modals/deleteCustomerDialog"
import UnhideCustomerDialog from "./modals/unhideCustomerDialog"
import ShowHiddenSwitch from "../../components/atoms/showHiddenSwitch"
import EditNodeModal from "./modals/editNodeModal"

type SortOn = 'name' | 'machineCount'

const columnNames = [
  {
    displayName: 'Name',
    serverName: 'name'
  },
  {
    displayName: 'Number of Machines',
    serverName: 'machineCount'
  },
]

type CustomerOrganisationsTableProps = {
  customers: CustomerNode[],
  onClickRow: Function,
  showHidden: boolean,
}

function CustomerOrganisationsTable({
  customers,
  onClickRow,
  showHidden,
}: CustomerOrganisationsTableProps): React.ReactElement {

  const [countPerPage, setCountPerPage] = useState<number>(10)
  const [page, setPage] = useState<number>(0)
  const [sortingOn, setSortingOn] = useState<SortOn>('name')
  const [descending, setDescending] = useState<boolean>(false)

  const [sortedCustomers, setSortedCustomers] = useState<TableList<CustomerNode>>({ list: customers, total: customers.length })

  const [nodeToRemove, setNodeToRemove] = useState<string>('')
  const [nodeToRestore, setNodeToRestore] = useState<string>('')
  const [editNodeWithId, setNodeToEdit] = useState<string>('')

  useEffect(() => {
    setSortedCustomers(sortListForTable(showHidden ? customers : customers.filter(c => !c.hidden), countPerPage, page, sortingOn, descending))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countPerPage, page, sortingOn, descending, showHidden])

  const foundNodeToRemove = sortedCustomers?.list.find(c => c.id === nodeToRemove)
  const foundNodeToRestore = sortedCustomers?.list.find(c => c.id === nodeToRestore)
  const nodeToEdit = sortedCustomers.list.find(c => c.id === editNodeWithId)

  const hasHiddenCustomers = customers?.findIndex(c => c.hidden) > -1
  return (
    <Fragment>
      {nodeToRemove && foundNodeToRemove ? <DeleteCustomerDialog nodeTypeName='store' customer={foundNodeToRemove} open={nodeToRemove ? true : false} closeModal={() => setNodeToRemove('')} /> : null}
      {nodeToRestore && foundNodeToRestore ? <UnhideCustomerDialog nodeTypeName='store' customer={foundNodeToRestore} open={nodeToRestore ? true : false} closeModal={() => setNodeToRestore('')} /> : null}
      {editNodeWithId && nodeToEdit ? <EditNodeModal nodeTypeName="store" node={nodeToEdit} open={editNodeWithId ? true : false} closeModal={() => setNodeToEdit('')} /> : null}
      <TableComponent
        onClickDelete={(id) => setNodeToRemove(id)}
        onClickRestore={(id) => setNodeToRestore(id)}
        onClickEdit={(id) => setNodeToEdit(id)}
        countPerPage={countPerPage}
        page={page}
        sortingOn={sortingOn}
        descending={descending}
        setCountPerPage={setCountPerPage}
        setPage={setPage}
        setSortingOn={setSortingOn}
        setDescending={setDescending}
        columnNames={columnNames}
        onClickRow={onClickRow}
        rows={sortedCustomers?.list.map((child) => {
          return {
            'id': child.id,
            'hidden': child.hidden,
            'Name': child.name,
            'Number Of Machines': child.machineCount,
          }
        }) ?? []}
        total={sortedCustomers?.total ?? 0} />
      {hasHiddenCustomers ? <ShowHiddenSwitch /> : null}
    </Fragment>
  )
}

export default CustomerOrganisationsTable