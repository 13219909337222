import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { LogLevelIcon } from './logLevelIcon'

type TagProps = ({
  level: LevelType,
  iconOnly?: boolean,
  vertical?: boolean
})

export type LevelType = 'Debug' | 'Info' | 'Error' | 'Warning' | 'Debiting' | 'Critical' | 'Recipe' | 'Success' | 'Up' | 'Down'
function LogLevelDisplay({
  level,
  iconOnly,
  vertical
}: TagProps): React.ReactElement {

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: vertical ? 'column' : 'row',
      alignItems: 'center'
    }}>
      <LogLevelIcon level={level}/>
      {iconOnly ? null : <Typography color='textPrimary'>
        {level}
      </Typography>}

    </Box>
  )
}

export default LogLevelDisplay