import { createAsyncThunk } from "@reduxjs/toolkit"
import { FetchFromBackoffice } from "../../utils/request"
import { DateTime } from "luxon"






  export const fetchRecipesPerNode = createAsyncThunk(
    "FETCH_RECIPES_PER_NODE",
    async ({from, to,view }:{from: DateTime, to:DateTime, view?: string }) => {
    const response  = await FetchFromBackoffice(
        `customer/nodes/recipes/per-customer/daily/between/${from?.toFormat(
          "yyyy-MM-dd"
        )}/and/${to?.toFormat("yyyy-MM-dd")}?viewName=${view}`
      )
    return response
    },
  )

  export const fetchTrendsAll = createAsyncThunk(
    "FETCH_TREND_ALL",
    async ({
      from,
      to,
      compareFrom,
      compareTo,
      view,
    }: {
      from: DateTime;
      to: DateTime;
      compareFrom: DateTime | undefined;
      compareTo: DateTime | undefined;
      view?: string;
    }) => {
      const response = await FetchFromBackoffice(
        `customer/nodes/all-nodes/trend/${from.toFormat(
          "yyyy-MM-dd"
        )}-to-${to.toFormat("yyyy-MM-dd")}/compare/${compareFrom.toFormat(
          "yyyy-MM-dd"
        )}-to-${compareTo.toFormat("yyyy-MM-dd")}}?viewName=${view}`
      );
      return response;
    }
  );
  
  