import React, { useEffect, useState } from "react"
import { Schema } from "../../../../common-types/commandingTypes"
import { AddUserCommand } from "./types"
import { AddModal } from "../../../../components/molecules/AddModal"
import { isValid } from "../../../../components/molecules/validatable-inputs/functions"
import { ValidatableTextInput } from "../../../../components/molecules/validatable-inputs"


export const AddUserForm = ({command, schema, onCancel, onSave}:{
    command: AddUserCommand,
    schema: Schema<AddUserCommand>
    onCancel: () => void
    onSave: (cmd:AddUserCommand) => void
}) => {
    const [validationError, setValidationError] = useState<boolean>(true)
    const [editCommand, setEditCommand] = useState<AddUserCommand>(command)

    const handleSave = () => {
        if(isValid(schema, editCommand))
            onSave(editCommand)
    }

    useEffect(() => {
        setValidationError(!isValid(schema, editCommand))
    }, [editCommand])

    return (
        <AddModal 
            open={true}
            title="Add user"
            closeModal={onCancel}
            onSave={handleSave}
            saving="Saving"
            savingFailedMessage="Failed"
            savingDisabled={validationError}
            >
                <ValidatableTextInput title="Email" command={editCommand} property="email" schema={schema} update={setEditCommand} />
                <ValidatableTextInput title="Givenname" command={editCommand} property="givenName" schema={schema} update={setEditCommand} />
                <ValidatableTextInput title="Familyname" command={editCommand} property="familyName" schema={schema} update={setEditCommand} />



        </AddModal>
    )
}