import React, { useEffect, useState } from "react"
import { Box } from "@mui/material"
import { SubpageTitle } from "../../../components/atoms/text/titles"
import { useQueryBackend } from "../../../utils/requestHelpers"
import { Nullable } from "../../../common-types"
import { BarChartView, IDataItem } from "../../../components/atoms/charts"
import { getDistinctPropertyValues } from "../../../functions"
import { DateTime } from "luxon"
import { MonthStepControl } from "../../../components/atoms/navigations"
import { IColorMap } from "../../../components/atoms/charts/barChartView"


interface CyclesItem {
    day: string,
    recipe: Nullable<string>
    cycles: number
}

export const SalesPerRecipe = ({nodeId}:{nodeId:string}) => {
    const queryApi = useQueryBackend("Backoffice")
    const [year, setYear] = useState<number>(DateTime.now().year)
    const [month, setMonth] = useState<number>(DateTime.now().month)
    const [data, setData] = useState<Array<IDataItem>>([])

    useEffect(() => {
        const from = DateTime.fromObject({year, month})
        const to = from.plus({month:1})

        queryApi.Get<Array<CyclesItem>>(`nodes/${nodeId}/recipes/daily/between/${from.toFormat("yyyy-MM-dd")}/and/${to.toFormat("yyyy-MM-dd")}`)
            .Data(d => {
                const allDays = getDistinctPropertyValues(d, "day")
                const allStores = getDistinctPropertyValues(d, "recipe")

                const items = allDays.map(day => {
                    const item:IDataItem = {name:day}
                    allStores.filter(s => !!s).forEach(k => {
                        item[k!] = d.find(d => d.day === day && d.recipe === k)?.cycles ?? 0
                    })
                    return item
                })
                setData(items)
            })
            
    }, [nodeId, month, year])

    const colorMap: IColorMap = {
        "Leather": "#01204E",
        "Suede": "#028391",
        "Textile": "#F6DCAC",
         "Synthetic":"#FEAE6F" 
            }   
    return (
        <Box>
            <SubpageTitle title={'Sales per recipe'}  />
            <MonthStepControl year={year} month={month} onChangeMonth={(year, month) => {setYear(year); setMonth(month)}}/>
            <div style={{ width: '100%', height: '400px' }}>
                <BarChartView colorMap={colorMap} items={data} formatX={s => s.substring(s.lastIndexOf('-') + 1)} />
            </div>
        </Box>
    )
}