import { Checkbox } from "@mui/material"
import React from "react"

export const CheckBox = ({id, title = id, checked, onChange = (key, checked) => console.log(`Checkbox ${key} checked`, checked)}:{
    title?: string
    id: string
    checked: boolean
    onChange?: (key:string, checked:boolean) => void
  }) => {

  return(
    <div>
        <Checkbox
            value={id}
            checked={checked}
            onChange={() => onChange(id, !checked)}
            inputProps={{ 'aria-label': 'controlled' }}
        />
        <label>{title}</label>
     </div>
  )
}