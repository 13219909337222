import React from "react"
import { PricesResponse } from "../types"
import { DateTime } from "luxon"
import TableComponent from "../../../components/molecules/table"
import { DeviceType } from "../../../theme"
import { connect } from "react-redux"
import { StoreState } from "../../../state/configureStore"
import { DimensionsReducer } from "../../../state/reducers/app"

// if not enough space
const hideCurrencyColumnFor: DeviceType = 'mobile'

export const columnNames = [{
    displayName: 'From',
    serverName: 'valid_from'
  }, {
    displayName: 'To',
    serverName: 'valid_until'
  },{
    displayName: 'Consumer Price',
    serverName: 'price'
  },{
    displayName: 'Currency',
    serverName: 'currency',
    hide: [hideCurrencyColumnFor]
  }
  ]

const ConsumerPricesListTable = ({prices, dimensions}:{
    prices: Array<PricesResponse>,
    dimensions: DimensionsReducer,
}) => {
  const device = dimensions.device

    const PriceListTable = () => (
          <TableComponent
            // countPerPage={countPerPage}
            // page={page}
            // sortingOn={sortingOn}
            // descending={descending}
            // setCountPerPage={setCountPerPage}
            // setPage={setPage}
            // setSortingOn={setSortingOn}
            // setDescending={setDescending}
            // onClickRow={(id: string) => navigate(id)}
            columnNames={columnNames}
            rows={prices.map((price) => {
              const from = DateTime.fromISO(price.valid_from).toLocaleString(DateTime.DATE_MED)
              const to = price.valid_until ? DateTime.fromISO(price.valid_until).toLocaleString(DateTime.DATE_MED) : 'ongoing'
              if (device !== hideCurrencyColumnFor) return {
                  'From': from,
                  'To': to,
                  'Price': price.price,
                  'Currency': price.currency,
                }
              else return {
                'From': from,
                'To': to,
                'Price': price.price + ' ' + price.currency,
              }
            })}
            total={prices.length ?? 0} />
          )

    return (
        // state.fetchingState !== 'failed' ?
        //   (state.fetchingState === 'loading' ? <DataLoadingText showSpinner text={'Loading machines ...'} />
        //     : sortedMachines.total > 0
        //       ? 
              <PriceListTable /> 
              //: <DataLoadingText text={'No machines available'} />)
        //   : <DataLoadingText text={'Failed fetching machines'} />
        // )
    //}, [state.fetchingState, sortedMachines, MachinesTable]))
    )
}

const mapStateToProps = (state: StoreState) => ({
  dimensions: state.dimensions,
})

export default connect(mapStateToProps)(ConsumerPricesListTable)

