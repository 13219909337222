import React from 'react'
import { Box, Button, List, ListItem, ListItemText, Paper, Typography } from '@mui/material'
import { Cancel, CheckCircle, PlayCircleFilled, Share } from '@mui/icons-material'
import { LogItemMessage, MachineStateChanged, RecipeActionMessage } from '../../state/sagas/app'
import { MachineReducer, MachineState, SignalRLogReducer } from '../../state/reducers/machine'
import { SubpageTitle } from '../../components/atoms/text/titles'
import { AnimatePresence, motion } from 'framer-motion'
import { DateTime } from 'luxon'
import { LogLevelIcon } from '../../components/molecules/logs/logLevelIcon'
import { LevelType } from '../../components/molecules/logs/logLevelDisplay'
import { StoreState } from '../../state/configureStore'
import { AnyAction, Dispatch } from 'redux'
import { connect } from 'react-redux'
import { testMachineConnected, testRecipe } from '../../state/actions/machine'
import theme from '../../theme'
import { isProd } from '../../config'
import { DimensionsReducer } from '../../state/reducers/app'

function getReadableDateTime(dateTime: DateTime) {
  if (!dateTime.isValid) return '-'

  const difference = DateTime.now().diff(dateTime)
  if (difference.as('minutes') < 1) return 'Now'
  if (difference.as('minutes') < 60) return `${difference.as('minutes').toFixed(0)} minutes ago`
  if (difference.as('days') < 1) return `${dateTime.toFormat('hh:mm')}`

  return `${dateTime.toFormat('d LLL hh:mm')}`
}

const getMachineStates = (machines: MachineState[]) => {
  const machineStates = {
    cycles: 0,
    connected: 0,
    disconnected: 0
  }

  machines?.forEach((m, index) => {
    machineStates.cycles = machineStates.cycles + m.cyclesCountToday
    if(m.connectionState === 'Connected') { ++machineStates.connected } else {++machineStates.disconnected}
  })
  
  return machineStates
}


const styles = {
  listContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    maxHeight: '100%',
    overflow: 'auto',
    marginTop: 2
  }
}

type MapSideBarDisplayProps = {
  machines: MachineState[]
  signalRLogs: SignalRLogReducer
  all_machines: MachineReducer,
  dimensions: DimensionsReducer,
  testMachineConnected: (message) => void
  testRecipe: (message) => void,
  horizontal: boolean
}

type LogObject = {
  log: RecipeActionMessage | LogItemMessage | MachineStateChanged,
  logType: 'RecipeActionMessage' | 'LogItemMessage' | 'MachineStateChanged',
  serialId: string
}

let testState

// TODO: soft transition when new log comes in
function MapSideBarDisplay({ machines, signalRLogs, all_machines, dimensions, testMachineConnected, testRecipe, horizontal}: MapSideBarDisplayProps) {
  let logs: LogObject[] = []

  Object.keys(signalRLogs)?.forEach((serialId) => {
    logs.push(...(signalRLogs[serialId].map((log) => {
      return {
        log: log,
        serialId: serialId,
        logType: (log as RecipeActionMessage).recipeType ? 'RecipeActionMessage' as 'RecipeActionMessage'
          : (log as LogItemMessage).message ? 'LogItemMessage' as 'LogItemMessage'
            : 'MachineStateChanged' as 'MachineStateChanged'
      }
    })))
  })

  logs.sort((a, b) => { return ((DateTime.fromISO(a.log.timestamp).toMillis() < DateTime.fromISO(b.log.timestamp).toMillis()) ? 1 : -1) })
  
  const dateToday = DateTime.now().toFormat('cccc d LLLL')

  const machineStates = getMachineStates(machines)

  const isDesktop = dimensions.device === 'desktop'

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      height: (horizontal || !isDesktop) ? 'fit-content' : '100%',
      paddingTop: !isDesktop ? 1 : 3,
      paddingBottom: !isDesktop ? 1 : 'auto',
      alignItems: 'flex-start',
      backgroundColor: theme.palette.background.paper,
    }}>
      <SubpageTitle title={dateToday} />
      <Box sx={{ width: "100%", display: 'flex', flex: 1, justifyContent: 'center', marginBottom: (!isDesktop ? 1 : 4), marginTop: (!isDesktop ? 0.5 : 2)}}>
        <Paper sx={{
          width: "100%",
          padding: "16px 32px",
          boxShadow: '0 2px 6px 1px rgba(222,222,222,0.50)',
          borderRadius: '20px',
          display: 'flex',
          justifyContent: 'space-between',
          gap: !isDesktop ? 1 : 'auto',
          flexWrap: isDesktop ? 'nowrap' : 'wrap'
        }}>
          <Box>
            <Typography variant={!isDesktop ? "h5" : "h4"} sx={{ lineHeight: 0.8 }}>{machineStates.cycles}</Typography>
            <Typography variant={!isDesktop ? "caption" :  "body2"} style={{ color: theme.palette.text.secondary }} >{"recipes"}</Typography>
          </Box>
          <Box>
            <Typography variant={!isDesktop ? "h5" : "h4"} sx={{ lineHeight: 0.8 }}>{machineStates.connected}</Typography>
            <Typography variant={!isDesktop ? "caption" :  "body2"} style={{ color: theme.palette.text.secondary }}>{"connected"}</Typography>
          </Box>
          <Box>
            <Typography variant={!isDesktop ? "h5" : "h4"} sx={{ lineHeight: 0.8 }}>{machineStates.disconnected}</Typography>
            <Typography variant={!isDesktop ? "caption" :  "body2"} style={{ color: theme.palette.text.secondary }}>{"disconnected"}</Typography>
          </Box>
        </Paper>
      </Box>
      <Box sx={styles.listContainer}>
        {logs?.length ? 
          <React.Fragment>
            <Typography sx={{fontFamily: 'VogieBold'}} variant={"h6"}>Live feed</Typography>
            <List disablePadding sx={{ height: '100%', width: '100%', overflow: 'auto' }}>
              {logs.map((logObj, index) => {
                const machine = all_machines?.find(d => d.serialId === logObj.log.machineId)
                if (!machine) return null
  
                const listItemComponent = getLogListItem(logObj, machine, all_machines, index, isDesktop)
  
                return listItemComponent ?
                  <AnimatePresence key={'listItem' + index}>
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}>
                      {listItemComponent}
                    </motion.div>
                  </AnimatePresence>
                  : null
              })}            
            </List> 
          </React.Fragment>
        : null// <Typography variant={"body1"} textAlign='left'>{'Waiting for events'}</Typography>
        }
      </Box>

      {
        !isProd() && (
          <Paper sx={{ width: "100%", padding: "16px 32px", boxShadow: '0 2px 6px 1px rgba(222,222,222,0.50)', borderRadius: '20px', display: 'flex', flexDirection: 'column'}}>
            <Button variant={"contained"} onClick={() => { 
              testState = testState === 'Connected' ? 'Disconnected' : 'Connected'

              testMachineConnected({
                machineId: 'test0001',
                currentState: testState,
                timestamp: DateTime.now().toISO()
              })
              }}>Toggle Connection</Button>
            <Button sx={{ marginTop: 2}} variant={"contained"} onClick={() => { 
              testState = testState === 'Connected' ? 'Disconnected' : 'Connected'

              testRecipe({
                machineId: 'test0001',
                action: 'started',
                // nodeId: string,
                recipeType: 'Suede',
                timestamp: DateTime.now().toISO()
              })
              }}>Run recipe</Button>
          </Paper>
        )
      }
    </Box>
  )
}

const primaryTypographyProps = {fontSize: '15px'}
const secondaryTypographyProps = {fontSize: '13px'}
const LogListItem = ({ icon, isDesktop, key, primaryStr, secondaryStr }: 
  {icon: React.JSX.Element | null, isDesktop: boolean, key: string, primaryStr: string, secondaryStr: React.JSX.Element | string}) => {
  return <ListItem sx={{ display: 'flex', flexDirection: 'row', gap: !isDesktop ? 1 : 'auto'}} key={key} disablePadding >
  <Box sx={{ flex: 1 }}>
    {icon}
  </Box>
  <Box sx={{ flex: 10 }}>
    <ListItemText
      primaryTypographyProps={primaryTypographyProps}
      secondaryTypographyProps={secondaryTypographyProps}
      primary={primaryStr}
      secondary={secondaryStr} />
  </Box>
</ListItem>
}

const getLogListItem = (logObj: LogObject, machine: MachineState, machines: MachineReducer, index: number, isDesktop: boolean) => {
  const dateAndIdStr = getReadableDateTime(DateTime.fromISO(logObj.log.timestamp))

  if (logObj.logType === 'RecipeActionMessage') {
    const log = logObj.log as RecipeActionMessage

    const actionString = log.action === 'started' ? log.action
      : log.action === 'done' ? 'is done with'
        : log.action

    const icon = (
      log.action === 'started' ? <PlayCircleFilled color={'success'} />
        : log.action === 'done' ? < CheckCircle color={'success'} />
          : log.action === 'canceled' ? <Cancel color={'warning'} /> 
            : null
    )
    
    return <LogListItem 
      icon={icon}
      isDesktop={isDesktop}
      key={'log_' + index}
      primaryStr={machine.name + ' ' + actionString + ' a ' + log.recipeType + ' recipe'}
      secondaryStr={dateAndIdStr}
    />
  }
  else if (logObj.logType === 'LogItemMessage') {
    const log = logObj.log as LogItemMessage
    const machine = machines?.find(d => d.serialId === log.machineId)
    if (!machine) return null

    const primaryStr = 'Machine "' + machine.name + '" reported log of level "' + log.level + '"'
    const secondaryStr = 'Message: ' + log.message

    return <LogListItem 
      icon={<LogLevelIcon level={log.level as LevelType} small />}
      isDesktop={isDesktop}
      key={'log_' + index}
      primaryStr={primaryStr}
      secondaryStr={<React.Fragment>
        <Typography sx={secondaryTypographyProps}>{secondaryStr}</Typography>
        <Typography sx={secondaryTypographyProps}>{dateAndIdStr}</Typography>
      </React.Fragment>}
    />
  }
  else if (logObj.logType === 'MachineStateChanged') {
    const log = logObj.log as MachineStateChanged
    const machine = machines?.find(d => d.serialId === log.machineId)
  
    if (!machine) return null

    // eslint-disable-next-line no-useless-concat
    const primaryStr = 'Machine "' + machine.name + '" has been ' + `${log.currentState === 'Disconnected' ? 'disconnected from' : 'connected to'}` + ' the IoT hub.'

    return <LogListItem 
      icon={<Share color={log.currentState === 'Disconnected' ? 'error' : 'success'} />}
      isDesktop={isDesktop}
      key={'log_' + index}
      primaryStr={primaryStr}
      secondaryStr={dateAndIdStr}
    />
  }
}

const mapStateToProps = (state: StoreState) => ({
  signalRLogs: state.signal_r_logs,
  all_machines: state.machines,
  fetchingState: state.machine_fetching,
  dimensions: state.dimensions
})

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  // fetchMachines: () => dispatch(fetchMachines()),
  testMachineConnected: (message) => dispatch(testMachineConnected(message)),
  testRecipe: (message) => dispatch(testRecipe(message)),
})

export default connect(mapStateToProps, mapDispatchToProps)(MapSideBarDisplay)