import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
import theme, { padding } from '../../../../theme'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import chroma from 'chroma-js';
import { motion } from "framer-motion"
import { styles as defaultStyles } from "../../../../theme"
import { getStore } from '../../../../state/configureStore';

export const MapSidebar = ({ children, open, width, onClose, onOpen, containerRef, horizontal, openFromBelow }) => {
  const [mapHeight, setMapHeight] = useState(undefined)
  const [mapWidth, setMapWidth] = useState(undefined)

  useEffect(() => {
    setMapHeight(containerRef?.current?.clientHeight)
    setMapWidth(containerRef?.current?.clientWidth)
  }, [containerRef, containerRef?.current?.clientHeight, containerRef?.current?.clientWidth])

  const isMobile = getStore().getState().dimensions.device !== 'desktop'

  return (
    <motion.div
      key={'sidebar'}
      initial={horizontal ? {
        right: 'auto',
        top: openFromBelow ? 'auto' : (open ? 0 : -width),
        bottom: openFromBelow ? (open ? 0 : -width) : 'auto'
      } : {
        right: open ? 0 : -width,
        top: 'auto',
        bottom: 'auto'
      }}
      animate={horizontal ? {
        right: 'auto',
        top: openFromBelow ? 'auto' : (open ? 0 : -width),
        bottom: openFromBelow ? (open ? 0 : -width) : 'auto'
      } : {
        right: open ? 0 : -width,
        top: 'auto',
        bottom: 'auto'
      }}
      transition={{
        type: "spring",
        damping: 15,
        stiffness: 200,
        mass: 0.5,
      }}
      style={{
        position: 'absolute',
        zIndex: 101,
        height: horizontal ? width : mapHeight,
        width: horizontal ? '100%' : width,
      }}
    >
      <Box sx={{
        height: horizontal ? width : mapHeight,
        boxShadow: 1,
        ...styles.sidebar_showing,
        padding: open ? (horizontal ? padding / 3 : padding) : 0,
        display: 'open' ? 'auto' : 'none',
        overflow: isMobile ? 'scroll' : 'visible'
      }}>
        <SidebarHandle open={open} onClose={onClose} onOpen={onOpen} horizontal={horizontal} openFromBelow={openFromBelow} sidebarWidth={width} mapWidth={mapWidth} />
        <Box className={'sideMenuContainer'} sx={{ height: '100%' }}>
          {open ? children : null}
        </Box>
      </Box>
    </motion.div>
  )
}

const sidebarHandleWidth = 15
const sidebarBackgroundColor = chroma(theme.palette.background.paper).alpha(1).hex()
const borderRounding = 2

const borderRoundingOnLeftSide = {
  borderTopRightRadius: 0,
  borderBottomRightRadius: 0,
  borderTopLeftRadius: borderRounding,
  borderBottomLeftRadius: borderRounding,
}

const borderRoundingOnTop = {
  borderBottomRightRadius: 0,
  borderBottomLeftRadius: 0,
  borderTopRightRadius: borderRounding,
  borderTopLeftRadius: borderRounding,
}

const borderRoundingOnBottom = {
  borderBottomRightRadius: borderRounding,
  borderBottomLeftRadius: borderRounding,
  borderTopRightRadius: 0,
  borderTopLeftRadius: 0,
}

const styles = {
  sidebar_showing: {
    zIndex: 101,
    backgroundColor: sidebarBackgroundColor,
  },
  sidebar_handle: (horizontal, sidebarWidth, openFromBelow, mapWidth, isMobile) => { 
    return {
      zIndex: 102,
      cursor: 'pointer',
      height: horizontal ? sidebarHandleWidth * 1.5 : isMobile ? 35 : '5%',
      width: horizontal ? '7.5%' : isMobile ? sidebarHandleWidth * 1.5 : sidebarHandleWidth,
      minWidth: horizontal ? 50 : 0,
      top: openFromBelow ? 'auto' : (horizontal ? sidebarWidth : isMobile ? '7.5%' : '5%'),
      bottom: openFromBelow ? (horizontal ? sidebarWidth : isMobile ? '7.5%' : '5%') : 'auto',
      left: horizontal ? 'auto' : -(isMobile ? sidebarHandleWidth * 1.5 : sidebarHandleWidth) + 3,
      ...(horizontal ? defaultStyles.ifMapboxMobile({ right: Math.max(mapWidth * 0.05, 75) }) : { right: 'auto' }), // leave space for Mapbox's own info boxes in the bottom of the map.
      ...(horizontal ? defaultStyles.ifNotMapboxMobile({ right: mapWidth / 2 - (mapWidth * 0.075 / 2)}) : { right: 'auto' }), // leave space for Mapbox's own info boxes in the bottom of the map.
      position: 'absolute',
      ...(horizontal ? (openFromBelow ? borderRoundingOnTop : borderRoundingOnBottom ) : borderRoundingOnLeftSide),
      backgroundColor: sidebarBackgroundColor,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: theme.palette.text.secondary,
    }
  }
}

const SidebarHandle = ({ open, onOpen, onClose, horizontal, sidebarWidth, openFromBelow, mapWidth }) => {
  const isMobile = getStore().getState().dimensions.device !== 'desktop'
  return (
    <Box sx={{ ...styles.sidebar_handle(horizontal, sidebarWidth, openFromBelow, mapWidth, isMobile), ...(open ? {} : { boxShadow: 1 }) }} onClick={open ? onClose : onOpen}>
      {
        open 
        ? <KeyboardArrowRight sx={[horizontal && { rotate: openFromBelow ? '90deg' : '-90deg' }]} fontSize={(horizontal || isMobile) ? 'medium' : 'small'} /> 
        : <KeyboardArrowLeft sx={[horizontal && { rotate: openFromBelow ? '90deg' : '-90deg' }]} fontSize={(horizontal || isMobile) ? 'medium' : 'small'} />
      }
    </Box>)
}