import { CommandBase, Schema } from "../../../../common-types/commandingTypes"

export interface ChildNode {
    id: string
    name: string
    levelName: string
}


export interface AddUserCommand extends CommandBase {
    $schemaId: 'AddUserCommand'
    id: string
    email?: string | undefined
    givenName?:string | undefined
    familyName?:string | undefined
    organisationIds: Array<string>
    role:string
}

export const AddUserCommandSchema: Schema<AddUserCommand> = {
    $id:'AddUserCommand',
    type:'object',
    title: 'AddUserCommand',
    properties: {
        email:{
            type: 'string',
            title: 'EMail',
//            pattern: '^a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$'
        },
        familyName:{
            type: 'string',
            title: 'Family name',
            minLength: 1
        },
        givenName: {
            type: 'string',
            title: 'Given name',
            minLength: 1
        },
        id: {
            type: 'string',
            title: 'Id',
            minLength: 1
        },
        organisationIds: {
            type: 'array',
            title: 'NodeIds',
            minLength: 1
        },
        role:{
            type: 'string',
            title: 'Role',
            minLength: 1
        }
    },
    required:['email', 'familyName', 'givenName', 'id', 'organisationIds', 'role']
}