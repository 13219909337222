import React, { useRef, useEffect } from "react";

export const ClickOutsideWrapper = ({ children, onClickOutside }) => {
  const wrapperRef = useRef(null);
  
  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      onClickOutside();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return <div ref={wrapperRef}>{children}</div>;
};
