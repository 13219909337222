import React from "react"
import { DateTime } from "luxon"
import { StepControl } from "./StepControl"

export const MonthStepControl = ({year, month, onChangeMonth = (year, month) => {}}:{year:number, month:number, onChangeMonth?: (year:number, month:number) => void}) => {
    const currentMonth = DateTime.fromObject({year:year, month:month})
    const prevMonth = currentMonth.minus({month:1})
    const nextMonth = currentMonth.plus({month:1})
    const dateFormat = 'yyyy MMMM'

    return (
        <StepControl
          onClickPrev={() => onChangeMonth(prevMonth.year, prevMonth.month)}
          onClickNext={() => onChangeMonth(nextMonth.year, nextMonth.month)}
          currentStr={currentMonth.toFormat(dateFormat)}
        />
    )
}