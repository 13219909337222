import React, { useCallback, useEffect } from "react";
import { Button, Typography } from "@mui/material";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import theme, { padding } from "../../../theme";
import { ColumnName, DataItem } from "../../molecules/CollapsableTable";

// Define a type for your table data
interface TableData {
  [key: string]: any;
}

interface ExportButtonProps {
  data: TableData[];
  fileName: string;
  columnNames: ColumnName[];
  groupBy: string;
}

const aggregateData = (
  data: DataItem[],
  groupByColumn: string,
  columnNames: ColumnName[]
): DataItem[] => {
  const groupedData: { [key: string]: DataItem[] } = {};

  // Group data by the specified column
  data.forEach((item) => {
    const groupKey = item[groupByColumn];
    if (!groupedData[groupKey]) {
      groupedData[groupKey] = [];
    }
    groupedData[groupKey].push(item);
  });

  // Aggregate values for each group
  const aggregatedResult: DataItem[] = [];
  Object.keys(groupedData).forEach((groupKey) => {
    const items = groupedData[groupKey];

    // Initialize aggregate values with the group key for the groupByColumn and other columns
    const aggregates: DataItem = {
      [groupByColumn]: groupKey,
    };

    columnNames.forEach((column) => {
      if (column.id !== groupByColumn) {
        if (column.aggregate) {
          // Initialize the aggregate field for each numeric column
          aggregates[column.id] = 0;
        } else {
          // Initialize a Set to track unique values for non-aggregatable columns
          aggregates[column.id] = new Set();
        }
      }
    });

    // Aggregate values for numeric columns and track unique values for non-numeric columns
    items.forEach((item) => {
      columnNames.forEach((column) => {
        if (column.id !== groupByColumn && column.aggregate) {
          if (typeof item[column.id] === "number") {
            aggregates[column.id] += item[column.id];
          } else if (
            typeof item[column.id] === "string" &&
            !isNaN(parseFloat(item[column.id]))
          ) {
            aggregates[column.id] += parseFloat(item[column.id]);
          }
        } else if (column.id !== groupByColumn && !column.aggregate) {
          // Track unique values in the Set
          if (typeof item[column.id] === "string") {
            aggregates[column.id].add(item[column.id]);
          }
        }
      });
    });

    // Replace Sets with their size to get the count of unique items
    Object.keys(aggregates).forEach((key) => {
      if (aggregates[key] instanceof Set) {
        aggregates[key] = aggregates[key].size;
      }
    });

    aggregatedResult.push(aggregates);

    // Add original items after the aggregate row
    aggregatedResult.push(...items);
  });

  return aggregatedResult;
};

const ExportButton: React.FC<ExportButtonProps> = ({
  data,
  fileName,
  columnNames,
  groupBy,
}) => {
  const [exportData, setExportData] = React.useState<DataItem[]>([]);

  const handleExport = useCallback(() => {
    // Create a new workbook and add a worksheet
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(exportData);

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    XLSX.writeFileXLSX(workbook, fileName);
  }, [exportData, fileName]);

  useEffect(() => {
    const preperedExcellData: DataItem[] = aggregateData(
      data,
      groupBy,
      columnNames
    );
    setExportData(preperedExcellData);
  }, [data, aggregateData, setExportData]);
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Button
        sx={{
          textDecoration: "none",
          color: theme.palette.primary.dark,
          marginBottom: 2
        }}
        onClick={handleExport}
      >
        <FileDownloadIcon sx={{marginRight: padding / 5}}/>
        <Typography>Export</Typography>
      </Button>
    </div>
  );
};

export default ExportButton;
