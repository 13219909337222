import React, { useCallback, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { DateTime } from "luxon";

import {  useSelector } from "react-redux";
import {
  CollapsibleTable,
  ColumnName,
} from "../../../components/molecules/CollapsableTable.tsx";
import { padding } from "../../../theme.ts";
import { useQueryBackend } from "../../../utils/requestHelpers.ts";

interface SoftwareVersionItem {
  softwareVersion: string;
  softwareType: string;
  fromDate: string;
  deviceId: string;
  serialNumber: string;
  machineName?: string;
}

export const MachineSoftwareVersionTable = () => {
  const isMobile = useSelector(
    (state: any) => state.dimensions.device === "mobile"
  );

  const queryApi = useQueryBackend("Backoffice");

  const columnNames: ColumnName[] = [
    {
      displayName: "Version",
      id: "softwareVersion",
      aggregate: false,
      grouped: true,
      level: 0, // No grouping at this level
      align: "left",
      visible: true
    },
    {
        displayName: "Amount",
        id: "machineName",
        aggregate: false,
        grouped: false,
        level: 1, 
        align: "left",
        visible: true, 
        formatter: (value: string) => value.toString(),
        altText: (row, agg, level) => {
            if(level === 0){
                return row.items.length 
            }
            return level
            }
      },

    {
      displayName: "Device Id",
      id: "deviceId",
      aggregate: false,
      grouped: false,
      level: 1,
      align: "left",
      visible: true, 
      altText: (row, agg, level) => {
        if(level === 0){
            return ""
        }
        return level
        }
    },
    {
      displayName: "Updated At",
      id: "fromDate",
      aggregate: false,
      grouped: false,
      level: 1, 
      align: "left",
      visible: true,
      altText: (row, agg, level) => {
        if(level === 0){
            return ""
        }
        return level
        }
    },
    {
      displayName: "Serial No",
      id: "serialNumber",
      aggregate: false,
      grouped: false,
      level: 1,
      align: "left",
      visible: true,
      altText: (row, agg, level) => {
        if(level === 0){
            return ""
        }
        return level
        }
    },
    {
      displayName: "Type",
      id: "softwareType",
      aggregate: false,
      grouped: false,
      level: 1, // No grouping at this level
      align: "left",
      visible: true, 
      altText: (row, agg, level) => {
        if(level === 0){
            return ""
        }
        return level
        }
    }
  ];

  const [data, setData] = useState<Array<SoftwareVersionItem>>([]);


  useEffect(() => {
    queryApi
      .Get<Array<SoftwareVersionItem>>(
        `software-versions/at-${DateTime.now().toFormat("yyyy-MM-dd HH:mm:ss.SSSZZ")}`
      )
      .Data((d) => {
        setData(d);
      });
  }, []);

  const prepData = data.map((c) => ({
    deviceId: c.deviceId,
    fromDate: c.fromDate,
    serialNumber: c.serialNumber,
    softwareType: c.softwareType,
    softwareVersion: c.softwareVersion,
    machineName: c.machineName
  }));

  return (
      <Box sx={[isMobile && { marginBottom: padding / 2 }]}>
        <CollapsibleTable
          columnNames={columnNames}
          rows={prepData.reverse()}
        ></CollapsibleTable>
      </Box>
  );
};
