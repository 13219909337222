import React, { useEffect, useState } from "react"
import { AddUserButton } from "./addUser"
import { UserListItem } from "./types"
import { useQueryBackend } from "../../../utils/requestHelpers"
import { UsersList } from "./UsersList"
import UserView from "./user"
import { OrganizationUserView } from "../../users/organization_user"
import { useNavigate } from "react-router-dom"

export const UsersView = ({nodeId}:{
    nodeId: string
}) => {
    const queryApi = useQueryBackend("Backoffice")
    const navigate = useNavigate()
    const [users, setUsers] = useState<Array<UserListItem>>([])
    const [selectedUserId, setSelectedUserId] = useState<string|undefined>()
    
    useEffect(() => {
        setUsers([])
        if(nodeId)
            queryApi.Get<Array<UserListItem>>(`node/${nodeId}/users`)
                .Data(r => setUsers(r))
    }, [nodeId])

    

    if(selectedUserId)
        return (
            <OrganizationUserView  />
        )

    return (
        <>
            <AddUserButton nodeId={nodeId} onSaved={usr => setUsers(c => ([{userId: usr.id!, givenName: usr.givenName!, familyName: usr.familyName!, email: usr.email!, roles: [usr.role]}, ...c]))} />  
            <UsersList users={users} onSelect={u => navigate(`/orguser/${u.userId}`)} />
        </>
    )
}