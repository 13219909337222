import React, { useEffect, useState } from "react";
import {  useSelector } from "react-redux";
import { DateTime } from "luxon";
import { Box } from "@mui/system";
import { Button, duration} from "@mui/material";
import DateRangeIcon from "@mui/icons-material/DateRange";

import theme, { padding } from "../../../theme";
import { CustomDateRangePicker } from "./customRangePicker";
import Text from "../../atoms/text/text";
import { ArrowCircleLeft, ArrowCircleRight } from "@mui/icons-material";
import Close from "@mui/icons-material/Close";
import { ClickOutsideWrapper } from "../ClickOutsideWrapper";


export const DateRangePickerPopUp = ({
  startDate,
  endDate,
  onStartDatePicked,
  onEndDatePicked,
  presets,
  setOpen,
  open,
}: {
  startDate: DateTime;
  endDate: DateTime;
  onStartDatePicked: Function;
  onEndDatePicked: Function;
  presets: TagButton[]
  setOpen: Function
  open: boolean
}) => {

  const isMobile = useSelector(
    (state: any) => state.dimensions.device === "mobile"
  );
  const [selected, setSelected] = useState<TagButton>();
  useEffect(() => {
    if(!selected)
    presets?.map((p ) => {
      if(p.default === true){
        setSelected(p) 
      }
    })
  }, [presets, selected])

  useEffect(() => {
    if(!endDate){
      setSelected({name: "", id: "custom", action: () => console.log()})
    }
  },[endDate])

  const stepper = endDate?.diff(startDate, "days").days <= 7 ? { week: 1 } : {month: 1} 
  const step = endDate?.diff(startDate, "days").days <= 7 ? "week" : "month" 
  const today = DateTime.now();

  return (
    <div style={{ marginBottom: padding * 2, marginTop: padding * 2 }}>
    <ClickOutsideWrapper onClickOutside={setOpen} >
      <Box
        sx={{
          width: isMobile ? 275 : 350,
          height: 40,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          border: "1px #868686 solid",
          paddingLeft: padding / 4,
          paddingRight: padding / 4,
          borderRadius: 2,
          "&:hover": {
            cursor: "pointer",
          },
        }}
      >
        <ArrowCircleLeft
          onClick={() => {
            setSelected(undefined)
            onStartDatePicked(startDate?.minus(stepper).startOf(step));
            onEndDatePicked(startDate?.minus(stepper).endOf(step));
            setSelected({name: "", id: "custom", action: () => console.log()})
          }}
        />
        <Box
          onClick={() => setOpen(!open)}
          sx={{
            width: 250,
            display: "flex",
            flex: 1,
            justifyContent: "center",
            flexDirection: "row",
            alignItems: "center",
            paddingLeft: padding / 2,
            paddingRight: padding / 2,
            "&:hover": {
              cursor: "pointer",
            },
          }}
        >
          <DateRangeIcon sx={{ marginRight: padding / 2 }} />
          {
           endDate?.diff(startDate, "days" ).days <= 1 ? 
          <p style={{fontSize: isMobile ? 14 : 14}}>
          {startDate?.toFormat("dd LLL")}
        </p> :
          startDate?.weekNumber === today.minus({ week: 1 }).weekNumber &&
          startDate?.weekNumber === endDate?.weekNumber && endDate?.diff(startDate, "days").days === 6 ? (
            <p style={{fontSize: isMobile ? 14 : 14}}>Last week</p>
          ) : (
            <p style={{fontSize: isMobile ? 14 : 14}}>
              {startDate?.toFormat("dd LLL")} - {endDate?.toFormat("dd LLL")}
            </p>
          )}
          {startDate?.weekNumber === endDate?.weekNumber && !isMobile ? (
            <p style={{ marginLeft: padding, color: "grey", fontSize: 14}}>
              &#183; {"W" + startDate?.weekNumber}
            </p>
          ) : (
            <></>
          )}
        </Box>
        <ArrowCircleRight
          sx={[
            !endDate || endDate?.plus({ week: 1 }) >= DateTime.now()
              ? { opacity: 0.5 }
              : {},
          ]}
          onClick={() => {
            if (!endDate ) return
            if ( endDate.plus({ week: 1 }) < DateTime.now()) {
              onStartDatePicked(endDate?.plus(stepper).startOf(step));
              onEndDatePicked(endDate?.plus(stepper).endOf(step));
              setSelected({name: "", id: "custom", action: () => console.log()})
            }
          }}
        />
      </Box>

      {open && (
          <div
            style={{
              marginTop: padding,
              flexDirection: "column",
              display: "flex",
              boxShadow: "2px 2px 10px 0px #868686",
              position: "absolute",
              backgroundColor: "white",
              zIndex: 10,
              borderRadius: 2,
            }}
          >
            <Close
              sx={{
                borderRadius: 2,
                color: theme.palette.background.default,
                margin: padding / 8,
                flexDirection: "row",
                alignSelf: "end",
                "&:hover": {
                  cursor: "pointer",
                  bgcolor: theme.palette.background.default,
                  color: "white",
                },
              }}
              onClick={() => setOpen(false)}
            />
            <div
              style={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                paddingRight: padding,
              }}
            >
              <TagButtons
                direction={isMobile ? "row" : "column"}
                tagButtons={presets}
                selected={selected}
                setSelected={setSelected}
              ></TagButtons>
              <CustomDateRangePicker
                isMobile={isMobile}
                setEndDate={onEndDatePicked}
                setStartDate={onStartDatePicked}
                startDate={startDate}
                endDate={endDate}
              />
            </div>
          </div>
      )}
          </ClickOutsideWrapper>
    </div>
  );
};

export type TagButton = {
  name: string;
  id: string;
  action: () => void;
  default?: boolean 
};

const TagButtons = ({
  tagButtons,
  selected,
  setSelected,
  direction,
}: {
  tagButtons: TagButton[];
  selected?: TagButton;
  setSelected: (selected: TagButton) => void;
  direction: "row" | "column";
}) => {
  const handlePress = ({ action, tag }: { action: () => void; tag: TagButton }) => {
    action();
    setSelected(tag);
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: direction === "row" ? "column" : "row",
      }}
    >
      <Box
        sx={[

          direction === "column" ? { padding: padding/5, width: 130, marginTop: "10%" } : {marginLeft: "10%"},
          {
            display: "flex",
            flexDirection: direction,
            alignItems: "flex-start",
          },
        ]}
      >
        {tagButtons?.map((t, index) => (
          <Button
            key={index}
            sx={{
              display: "flex",
              bgcolor: selected?.id === t.id ? "primary.light" : "white",
              height: 25,
              margin: padding / 30,
              borderRadius: 2,
              justifyContent: "start",
              "&:hover": {
                bgcolor: "primary.light",
              },
            }}
            onClick={() => handlePress({ action: () => t.action(), tag: t })}
          >
            <Text fontSize={11} style={{ textAlign: "start" }} key={index}>
              {t.name}
            </Text>
          </Button>
        ))}
      </Box>
      {direction === "column" ? (
        <div
          style={{
            position: "relative",
            paddingRight: "10px",
            paddingLeft: "10px",
          }}
        >
          <div
            style={{
              content: '""',
              position: "absolute",
              top: "5%", // Start the border slightly below the top
              bottom: "5%", // End the border slightly above the bottom
              right: 0, // Align the border to the right
              width: "2px", // Border width
              backgroundColor: theme.palette.background.default, // Border color
            }}
          />
        </div>
      ) : (
        <div
          style={{
            marginTop: padding,
            position: "relative",
            paddingTop: "10px",
            paddingBottom: "10px",
            border: "2px color red"
          }}
        >
          <div
            style={{
              content: '""',
              position: "absolute",
              left: "5%", // Start the border slightly below the top
              right: "5%", // End the border slightly above the bottom
              top: 0 , // Align the border to the right
              height: "2px", // Border width
              backgroundColor: theme.palette.background.default, // Border color
            }}
          />
         </div>
      )}
    </Box>
  );
};
