import React, { useEffect, useState } from "react"
import { AddSubscription } from "./addSubscription"
import { SubscriptionList } from "./subscriptionsList"
import { FetchFromBackoffice } from "../../../utils/request"
import { PostToBackoffice } from "../../../utils/commands"
import { Box } from "@mui/material"
import { padding } from "../../../theme"

export type SubscriptionsListEmailItem = {
    alertId: string,
    email:string,
    eventType:string,
  }
  export type SubscriptionsList = {
    items: SubscriptionsListEmailItem[],
  }

type Properties = {
    nodeId:string
}

export const Subscriptions:React.FC<Properties> = ({
    nodeId
}) => {
    const [subscriptions, setSubscriptions] = useState<SubscriptionsListEmailItem[]>([])
    const [eventTypes, setEventTypes] = useState<string[]>([])

    useEffect(() =>{
        const path = 'alerts/event-types'
        FetchFromBackoffice<string[]>(path)
            .then(data => setEventTypes(data))
    }, [])

    useEffect(() => {
        if(nodeId) {
            const path = `alerts/nodes/${nodeId}/email`
            FetchFromBackoffice<SubscriptionsList>(path)
                .then(data => setSubscriptions(data.items))
        }
    }, [nodeId])

    const RemoveSubscription = (alertId:string) => {
        PostToBackoffice('cmd/alerts/remove', {alertId, nodeId})
            .then(_ => setSubscriptions(c => c.filter(i => i.alertId !== alertId)))
    }

    const AddedNode = (eventType:string, email:string) => {
        PostToBackoffice('cmd/alerts/addemail', {nodeId, email, alertType:eventType})
            .then(result => setSubscriptions(c => [{alertId:result.data.subscriptionId, eventType, email},...c]))
    }

    const minWidth = '200px'
    const buttonWidth = '90px'
    return (
      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', gap: padding/2, maxWidth: 800 }}>
        <AddSubscription minWidth={minWidth} buttonWidth={buttonWidth} onAdd={AddedNode} EventTypes={eventTypes} />
        <SubscriptionList minWidth={minWidth} buttonWidth={buttonWidth} Subscriptions={subscriptions} onRemove={RemoveSubscription} />
      </Box>
    )
}