import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useCommandBackend, useQueryBackend } from "../../../utils/requestHelpers"
import { UserListItem } from "../types"
import { CheckboxItem, CheckboxList } from "../../../components/atoms/inputs"

interface PermissionItem {
    orgId: string
    role: string
}

interface RoleItem {
    id: string
    title?: string
}

interface NodeTreeItem {
    nodeId: string
    name: string
    levelName: string
    children: Array<NodeTreeItem>
}

interface UserRoleChanged {
    userId: string
    nodeId: string
    roleName: string
    added: boolean
}

export const OrganizationUserView = () => {
    const { userId } = useParams()
    const queryApi = useQueryBackend("Backoffice")
    const [user, setUser] = useState<UserListItem | undefined>()
    const [userRoles, setUserRoles] = useState<Array<PermissionItem>>([])
    const [nodes, setNodes] = useState<Array<NodeTreeItem>>([])
    
    useEffect(() => {
        queryApi.Get<Array<NodeTreeItem>>('/nodes/tree/full')
            .Data(d => setNodes(d))
    }, [])

    useEffect(() => {
        if(userId) {
            refreshUser(userId)
            refreshUserPermissions(userId)
        }
    }, [userId])

    const refreshUserPermissions = (id:string) => 
        queryApi.Get<Array<PermissionItem>>(`/auth/${id}/roles`)
            .Data(d => setUserRoles(d))

    const refreshUser = (id: string) => 
        queryApi.Get<UserListItem>(`/org/${id}`)
            .Data(d => setUser(d))

    const roleChanged = (evt: UserRoleChanged) => {
        if(evt.added) {
            setUserRoles(c => [...c,{orgId:evt.nodeId, role: evt.roleName}])
        } else {
            setUserRoles(c => [...c.filter(r => !(r.orgId === evt.nodeId && r.role === evt.roleName))])
        }
    }

    if(!user)
        return null

    return (
        <>
            <UserDisplay user={user} />
            <PermissionsGrid userId={user.userId} nodeTree={nodes} permissions={userRoles} roles={[{id: 'NodeViewer', title: 'Viewer'}]} onRoleChanged={roleChanged} />
        </>
    )
}

const PermissionsGrid = ({userId, nodeTree, permissions, roles, onRoleChanged}:
{
    userId: string
    nodeTree: Array<NodeTreeItem>
    permissions: Array<PermissionItem>
    roles: Array<RoleItem>
    onRoleChanged: (evt: UserRoleChanged) => void
}) => {

    return (
        <table>
            <tbody>
                {nodeTree.map((n, idx) => (
                    <>
                    <tr key={idx}>
                        <td><span style={{fontWeight: 'bold'}}>{n.levelName}:&nbsp;</span><span>{n.name}</span><PermissionRolesControl userId={userId} nodeId={n.nodeId} permissions={permissions} roles={roles} onRoleChanged={onRoleChanged} /></td>
                        <td></td>
                    </tr>
                        {n.children.map((c, sidx) => (
                            <>
                                <tr key={sidx * idx}>
                                    <td></td>
                                    <td><span style={{fontWeight: 'bold'}}>{c.levelName}:&nbsp;</span><span>{c.name}</span><PermissionRolesControl userId={userId} nodeId={c.nodeId} permissions={permissions} roles={roles} onRoleChanged={onRoleChanged} /></td>
                                </tr>
                            </>
                            ))}
                    </>
                ))}
            </tbody>
        </table>
    )
}

const PermissionRolesControl = ({nodeId, userId, permissions, roles, onRoleChanged}:
{ 
    nodeId:string
    userId: string
    permissions: Array<PermissionItem>
    roles: Array<RoleItem>
    onRoleChanged: (evt: UserRoleChanged) => void
}) => {
    const commandApi = useCommandBackend("Backoffice")
    const rolesForNode = permissions.filter(p => p.orgId === nodeId)
    
    const haveRole = (role: string) => 
        rolesForNode.filter(r => r.role === role).length > 0 

    const items:Array<CheckboxItem> = roles.map(r => ({id: r.id, checked: haveRole(r.id), title: r.title ?? r.id}))

    const handleCheck = (id:string, checked: boolean) => {
        const command = {userId, nodeId, roleName: id}
        commandApi.PostCommand(!checked ? '/cmd/user/roles/remove' : '/cmd/user/roles/add', command)
            .Data((cmd) => onRoleChanged({...cmd.command, added: checked, roleName: id}))
    }

    return (
        <>
            <CheckboxList items={items} onChange={handleCheck} />
        </>
    )

}

const UserDisplay = ({user}:{user: UserListItem | undefined}) => {

    return (
        <div>
            <div><label style={{fontWeight: 'bold'}}>Name: </label><span>{user?.name}</span></div>
            <div><label style={{fontWeight: 'bold'}}>Email: </label><span>{user?.email}</span></div>
        </div>
    )

}