import React, { useEffect, useState } from "react"
import { PagedResponse, useQueryBackend } from "../../../utils/requestHelpers"
import { UserListItem } from "../types"
import TableView from "../../../components/atoms/table"
import { useNavigate } from "react-router-dom"


export const OrganizationUsersView = () => {
    const [users, setUsers] = useState<Array<UserListItem>>([])
    const queryApi = useQueryBackend("Backoffice")
    const navigate = useNavigate()

    useEffect(() => {
        queryApi.Get<PagedResponse<UserListItem>>('users/organization')
            .Data(d => setUsers(d.items))
    }
    ,[])


    return (
        <div>
        <TableView
            columns={[
                  { Property: 'givenName', Title: 'Givenname' }
                , { Property: 'familyName', Title: 'Familyname' }
                , { Property: 'email', Title: 'Email' }
            ]}
            items={users}
            onRowClick={i => navigate(`/orguser/${i.userId}`)}
         />
        </div>
    )

}