import React, { useEffect, useState } from "react"
import { Box, Button, TextField } from "@mui/material"

import { EventTypeSelector } from "./eventTypeSelector";
import { padding } from "../../../theme";

type Properties = {
    EventTypes: string[],
    onAdd: (eventType:string, email:string) => void,
    
    minWidth: string,
    buttonWidth: string
}

export const AddSubscription:React.FC<Properties> =
({
    EventTypes,
    onAdd,
    minWidth,
    buttonWidth
}) => {
    const [email, setEmail] = useState<string | undefined>();
    const [eventType, setEventType] = useState<string | undefined>();
    const [clickEnabled, setClickEnabled] = useState<boolean>(false)

    useEffect(() =>{
        if(email && eventType){
            setClickEnabled(true)
        } else {
            setClickEnabled(false)
        }
    },[email, eventType])

    const add = () => {
        if(email && eventType) {
            onAdd(eventType, email)
            setEmail(undefined)
        }
    }


    return (
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: padding/2, alignItems: 'center', flexGrow: 1, flexWrap: 'wrap' }}>
        <TextField  
          sx={{minWidth: minWidth, flex: 1}}
          autoFocus
          id="email_input_field"
          label="Email"
          type="email"
          fullWidth
          onChange={(event) => setEmail(event.target.value)}
        />

        <Box sx={{ minWidth: minWidth, flex: 1 }}>
          <EventTypeSelector
            AvailableTypes={EventTypes}
            onSelect={setEventType}
          />
        </Box>

        <Button
          sx={{ height: 'fit-content', width: buttonWidth }}
          variant="contained"
          disabled={!clickEnabled}
          onClick={() => add()}
        >
          Add
        </Button>
      </Box>
    );
}

